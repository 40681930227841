import React from 'react';
import {
    Button,
    Container,
    Divider,
    Header,
    Icon,
    Message,
    Tab,
    Form,
    Grid,
    List,
    Popup,
    Transition,
    Input, Table, Label, Menu
} from 'semantic-ui-react';
import axios from 'axios';
import { YMaps, Map, GeoObject, Placemark, Polyline, ZoomControl } from 'react-yandex-maps';
import AddressField from './components/addressField'
import Regions from './regions'

export default class CalculateTrip extends React.Component {
    state = {
        truckType: 'Тент',
        fromAddrInfo: null,
        toAddrInfo: null,
        palletCount: null,
        distance: 0,
        humanTime: '',
        price: 0,
        dogruzPrice: 0,
        pricePerKm: 0,
        factPricePerKm: 0,
        mapState: { center: [55.75, 37.57], zoom: 9 },
        polylineData: [],
        percentOfReturns: 0,

        poolingDays: 0,
        milkrunDays: 0,
        ftlDays: 0,

        poolingCost: 0,
        milkrunCost: 0,
        ftlCost: 0,

        poolingPallets: 0,
        milkrunPallets: 0,
        ftlPallets: 0,

        pulse: true
    }

    clearState = () => {
        this.setState({
            truckType: 'Тент',
            fromAddrInfo: null,
            toAddrInfo: null,
            distance: 0,
            humanTime: '',
            price: 0,
            pricePerKm: 0,
            factPricePerKm: 0,
            mapState: { center: [55.75, 37.57], zoom: 9 },
            polylineData: [],
            percentOfReturns: 0,
            palletCount: 33,
        });
    }

    constructor() {
        super()
        this.map = null;
        this.ymaps = null;
        this.regions = (new Regions()).arr;
        this.timer = setInterval(this.pulse, 1000)
    }

    pulse = () => {
        this.setState({
            pulse: !this.state.pulse
        });
    }

    isDogruz = () => {
        return window.location.href.indexOf('dogruz') != -1;
    }

    onSetFromAddrInfo = (info) => {
        if(info.coord !== null && info.province !== null){
            info.province = info.province
                .replace('Республика Татарстан', 'Республика Татарстан (Татарстан)')
                .replace('область', 'обл')
            ;
            if(this.regions.filter(x=> {
                //debugger;
                return x.from.indexOf(info.province) === 0
            }).length > 0)
                this.setState({fromAddrInfo: info}, ()=> {
                    this.tryCalculateRoute();
                });
            else{
                console.log('province ' + info.province + ' not found');
            }
        }else{
            this.setState({fromAddrInfo: null, polylineData: [], price: 0});
        }
    }

    onSetToAddrInfo = (info) => {
        if(info.coord !== null && info.province !== null ){
            info.province = info.province.replace('область', 'обл');
            if(this.regions.filter(x=>x.to === info.province).length > 0)
                this.setState({toAddrInfo: info}, ()=> {
                    this.tryCalculateRoute();
                });
            else{
                console.log('province ' + info.province + ' not found');
            }
        }else{
            this.setState({toAddrInfo: null, polylineData: [], price: 0});
        }
    }

    setTruckType = (type)=>{
        this.setState({truckType: type}, ()=> {
            this.tryCalculateRoute();
        });
    }

    tryCalculateRoute = () => {
        if(this.state.fromAddrInfo == null || this.state.toAddrInfo == null || this.state.palletCount == null || this.state.palletCount === 0  || this.state.palletCount < 1)
            return;

        var start = this.state.fromAddrInfo.coord.split(' ').map(y=>parseFloat(y)).reverse();
        var stop = this.state.toAddrInfo.coord.split(' ').map(y=>parseFloat(y)).reverse();
        var palletCount = this.state.palletCount;

        var el = this;
        window.ymaps
            .route([start, stop], {
                mapStateAutoApply: true
            })
        .then(function (route) {
            window.route = route;

            var toProvince = el.state.toAddrInfo.province;
            var fromProvince = el.state.fromAddrInfo.province;
            var filtered =  el.regions.filter(x=>x.to === toProvince && x.from === fromProvince);
            var percentOfReturns = filtered[0].percentOfReturns;
            var distanceKm = parseFloat((route.getLength()/1000).toFixed(2));
            var furaCost = 3000 + distanceKm * (el.state.truckType == 'Тент' ? 33.7 : 39.44) / percentOfReturns;
            furaCost = parseFloat(furaCost.toFixed(0));
            var getForMin33 = function (palletCount, furaCost) {
                if(palletCount === 0){
                    return 0;
                }
                if(palletCount < 15){
                    poolongPallet += parseInt(palletCount);
                    let cost = ((furaCost / 33) * 1.3 * palletCount) + 320;
                    poolongPrice = poolongPrice + cost
                    return cost;
                }

                if(palletCount >= 15 && palletCount <= 22){
                    let cost = (furaCost / 33) * 1.3 * palletCount;
                    milkrunPrice += cost;
                    milkrunPallet += parseInt(palletCount);
                    return cost;
                }

                if(palletCount >= 23 && palletCount <= 33){
                    ftlPallet += parseInt(palletCount);
                    ftlPrice += furaCost;
                    return furaCost;
                }
            }
            var price;
            var dogruzPrice;
            var poolongPallet = 0;
            var poolongPrice = 0;
            var milkrunPrice = 0;
            var milkrunPallet = 0;
            var ftlPrice = 0;
            var ftlPallet = 0;

            if(palletCount >= 33){
                var trucks  = Math.floor(palletCount / 33);
                price = trucks * furaCost;
                ftlPrice = price;

                var ost = palletCount % 33;
                ftlPallet = palletCount - ost;
                price = price + getForMin33(ost, furaCost);
            }else{
                price = getForMin33(palletCount, furaCost);
            }

            dogruzPrice = (furaCost/33) * 0.6 * palletCount;

            var factPricePerKm = price/distanceKm;
            factPricePerKm = parseFloat(factPricePerKm.toFixed(2));
            var pricePerKm = el.state.truckType == 'Тент' ? 33.7 : 39.44;
            var polylineData = [];
            var segments = route.getPaths().get(0).getSegments().map(x=>x._geometry.coordinates)
            segments.forEach(element => {
                element.forEach(x=>polylineData.push(x))

            });
            el.setState({
                distance: distanceKm,
                humanTime: route.getHumanTime().replaceAll('&#160;', ' '),
                price: parseFloat(price.toFixed(0)),
                dogruzPrice: parseFloat(dogruzPrice.toFixed(0)),
                pricePerKm: parseFloat(pricePerKm.toFixed(2)),
                polylineData: polylineData,
                percentOfReturns: percentOfReturns,
                factPricePerKm: parseFloat(factPricePerKm.toFixed(2)),

                poolingDays: Math.round(distanceKm/670) + 1,
                milkrunDays: Math.round(distanceKm/670) + 1,
                ftlDays: Math.round(distanceKm/670),

                poolingPallets: poolongPallet,
                milkrunPallets: milkrunPallet,
                ftlPallets: ftlPallet,

                poolingCost: parseFloat(poolongPrice.toFixed(0)),
                milkrunCost: parseFloat(milkrunPrice.toFixed(0)),
                ftlCost: parseFloat(ftlPrice.toFixed(0)),
            });
        });
    }

    renderMark = (x) => {
        return <Popup position="left center" content={x.title} trigger={<div className={'properties-item'}>
            <div className={'icon-wrapper icon-' + x.icon}></div>
            <div className={'properties-item-content '}><b>{x.value}</b> {x.additionalText}</div>
        </div>} />;
    }

    render() {
        var points = [];


        if(this.state.fromAddrInfo !== null)
            points.push({name: "Откуда", coords: this.state.fromAddrInfo.coord.split(' ').map(y=>parseFloat(y)).reverse()});

        if(this.state.toAddrInfo !== null)
            points.push({name: "Куда", coords: this.state.toAddrInfo.coord.split(' ').map(y=>parseFloat(y)).reverse()});


        var zoom = 3;
        var center = [55.75, 37.57];

        if(points.length > 0){
            var min0 = Math.min(...points.map(x=>x.coords[0]));
            var max0 = Math.max(...points.map(x=>x.coords[0]));

            var min1 = Math.min(...points.map(x=>x.coords[1]));
            var max1 = Math.max(...points.map(x=>x.coords[1]));

            center = [(max0 - min0)/2 + min0, (max1 - min1)/2 + min1];
        }
        var mapState = { center: center, zoom: zoom };

        let viewPrice = this.state.fromAddrInfo !== null
            && this.state.fromAddrInfo.province !== null
            && this.regions.filter(x => x.from === this.state.fromAddrInfo.province).length > 0
            && this.state.toAddrInfo !== null
            && this.state.toAddrInfo.province !== null
            && this.regions.filter(x => x.to === this.state.toAddrInfo.province).length > 0
            && this.state.price !== 0;


        var num2str = (n, text_forms) => {
            n = Math.abs(n) % 100; var n1 = n % 10;
            if (n > 10 && n < 20) { return text_forms[2]; }
            if (n1 > 1 && n1 < 5) { return text_forms[1]; }
            if (n1 == 1) { return text_forms[0]; }
            return text_forms[2];
        }

        let tripDetailItems;
        if (this.isDogruz())
            tripDetailItems = [
                {
                    title: 'Область отпавления',
                    value: this.state.fromAddrInfo?.province,
                    additionalText: '',
                    icon: 'from'
                },
                {
                    title: 'Область назначения',
                    value: this.state.toAddrInfo?.province,
                    additionalText: '',
                    icon: 'to'
                },
                {
                    title: 'Маршрут',
                    value: this.state.distance.toLocaleString('ru-RU'),
                    additionalText: ' км',
                    icon: 'route'
                },
                /*{
                    title: 'Время в пути',
                    value: this.state.humanTime,
                    icon: 'clock'
                },*/
            ];
        else
            tripDetailItems = [
                {
                    title: 'Область отпавления',
                    value: this.state.fromAddrInfo?.province,
                    additionalText: '',
                    icon: 'from'
                },
                {
                    title: 'Область назначения',
                    value: this.state.toAddrInfo?.province,
                    additionalText: '',
                    icon: 'to'
                },
                {
                    title: 'Маршрут',
                    value: this.state.distance.toLocaleString('ru-RU'),
                    additionalText: ' км',
                    icon: 'route'
                },
                /*{
                    title: 'Время в пути',
                    value: this.state.humanTime,
                    icon: 'clock'
                },*/
                {
                    title: 'Рыночный тариф за пробег одного км пути',
                    value: this.state.pricePerKm.toLocaleString('ru-RU'),
                    additionalText: ' руб/км',
                    icon: 'star'
                },
                {
                    title: 'Тариф за пробег с грузом за один км',
                    value: this.state.factPricePerKm.toLocaleString('ru-RU'),
                    additionalText: ' руб/км',
                    icon: 'cash'
                },
                {
                    title: 'Вероятность возврата из региона с товаром в течение суток с момента разгрузки',
                    value: parseFloat((this.state.percentOfReturns * 100).toFixed(0)),
                    additionalText: ' %',
                    icon: 'return'
                }
            ];
        return (
            <Container>
            <Form>
                <Grid columns={2} className="form-wrapper">
                    <Grid.Column width={11} className="form-col">
                        <Grid columns={2}>
                            <Grid.Column className="form-col-inner">
                                <AddressField elementId={'from-addr'} label="Откуда" placeholder="Адрес загрузки" onSetAddrInfo={this.onSetFromAddrInfo}/>
                            </Grid.Column>
                            <Grid.Column className="form-col-inner">
                                <AddressField elementId={'to-addr'} label="Куда" placeholder="Адрес доставки" onSetAddrInfo={this.onSetToAddrInfo}/>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2} className="form-col">
                        <Form.Field>
                            <label>Паллет (шт.)</label>
                            <Input onChange={(e) => {
                                this.setState({
                                    palletCount: e.target.value
                                }, ()=> { this.tryCalculateRoute();});
                            }} style={{height: '58px'}} value={this.state.palletCount} placeholder='Паллет...' size='huge'/>
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={3} className="form-col">
                        <Form.Field>
                            <label>Тип кузова</label>
                            <Button.Group style={{width: '100%'}}>
                                <Button size='huge' onClick={()=>{this.setTruckType('Тент');}} color={this.state.truckType == 'Тент' ? 'blue' : '' }>Тент</Button>
                                <Button size='huge' onClick={()=>{this.setTruckType('Реф');}} color={this.state.truckType == 'Реф' ? 'blue' : '' }>Реф</Button>
                            </Button.Group>
                        </Form.Field>
                    </Grid.Column>
                </Grid>
            </Form>
            <Transition visible={points.length > 0} animation='scale' duration={200}>
                <>
                <Divider hidden />
                <Divider hidden />
                <Grid columns={2} style={{position: 'relative'}}>
                    <Grid.Column width={16}>
                        <YMaps>
                            <div>
                                <Map
                                    width={'100%'}
                                    height={'437px'}
                                    state={mapState} instanceRef={(ref) => {
                                    this.map = ref;
                                    ref && ref.behaviors.disable('scrollZoom');
                                    ref && ref.behaviors.disable('drag');
                                }} >
                                    {points.map(x=> <GeoObject
                                        key={"GeoObject_" + x.coords}
                                        // The geometry description.
                                        geometry={{
                                            type: 'Point',
                                            coordinates: x.coords,
                                        }}
                                        // Properties.
                                        properties={{
                                            // The placemark content.
                                            iconContent: x.name,
                                        }}
                                        // Options.
                                        options={{
                                            // The placemark's icon will stretch to fit its contents.
                                            preset: 'islands#blackStretchyIcon',
                                        }}
                                    />)}

                                    {this.state.polylineData !== [] ? <Polyline
                                        geometry={this.state.polylineData}
                                        options={{
                                            balloonCloseButton: false,
                                            strokeStyle: 'solid',
                                            strokeColor: '#135CA9',
                                            strokeWidth: 4,
                                            strokeOpacity: 1,
                                        }}
                                    />: ''}
                                    {/*<ZoomControl options={{ float: 'right' }} />*/}
                                </Map>
                            </div>
                        </YMaps>
                    </Grid.Column>
                    <div className={'properties-container'}>
                        <Transition visible={viewPrice} animation='scale' duration={200}>
                            <div className={'properties-list'}>
                                <div className={'properties-header'}>Условия перевозки</div>
                                {tripDetailItems.map(x => this.renderMark(x))}
                                {this.isDogruz() ? <div className={'properties-footer'}>
                                    <div className={'footer-header'}>Ваш заработок</div>
                                    <div className={'price-sum'}>{this.state.dogruzPrice.toLocaleString('ru-RU')} ₽</div>
                                    <p className={'price-VAT-description'}>без НДС</p>
                                </div> : ""}
                            </div>
                        </Transition>
                        <Transition visible={
                            !viewPrice && points.length > 1 && this.state.polylineData === []
                        } animation='scale' duration={200}>
                            <Message >
                                Не удалось определить регион России
                                <img style={{width: '100%', opacity: '0.5'}} src={"https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Map_of_Russia_-_Economic_regions.svg/800px-Map_of_Russia_-_Economic_regions.svg.png"}/>
                            </Message>
                        </Transition>

                    </div>
                </Grid>
                <Transition visible={viewPrice} animation='scale' duration={200}>
                    <>
                        {this.isDogruz() ? "" : <Grid>

                            <Grid.Column width={11} className="price-table">
                                <Table className={'description-table'} basic>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Тип доставки</Table.HeaderCell>
                                            <Table.HeaderCell>Количество паллет</Table.HeaderCell>
                                            <Table.HeaderCell>Сроки доставки</Table.HeaderCell>
                                            <Table.HeaderCell>Стоимость, руб. без НДС</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {[
                                            {
                                                name: 'LTL',
                                                pallets: this.state.poolingPallets,
                                                days: this.state.poolingDays,
                                                cost: this.state.poolingCost
                                            },
                                            {
                                                name: 'STL',
                                                pallets: this.state.milkrunPallets,
                                                days: this.state.milkrunDays,
                                                cost: this.state.milkrunCost
                                            },
                                            {
                                                name: 'FTL',
                                                pallets: this.state.ftlPallets,
                                                days: this.state.ftlDays,
                                                cost: this.state.ftlCost
                                            },
                                        ].map(x=><Table.Row>
                                            <Table.Cell>{x.name}</Table.Cell>
                                            <Table.Cell><b>{x.pallets}</b></Table.Cell>
                                            <Table.Cell><b>{x.days}</b> {num2str(x.days, ['день', 'дня', 'дней'])}</Table.Cell>
                                            <Table.Cell><b>{x.cost.toLocaleString('ru-RU')}</b></Table.Cell>
                                        </Table.Row>)}
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                            <Grid.Column width={5} style={{paddingTop: '64px'}} className="price-block">
                                <div className={'price-container'}>
                                    <div className={'price-sum'}>{this.state.price.toLocaleString('ru-RU')} <div className={'icon-wrapper icon-ruble'}/></div>
                                    <p className={'price-VAT-description'}>без НДС</p>
                                </div>
                            </Grid.Column>
                        </Grid>}
                        <div className={'offer-description'}>Цены, содержащиеся в предложениях, являются приблизительными, информация не является публичной офертой.</div>
                    </>
                </Transition>
                </>
            </Transition>
            </Container>
        );
    }
  }
