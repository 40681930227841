import React from 'react';
import { Button, Container, Divider, Header, Icon, Message, Tab, Form, Grid, List, Popup, Transition } from 'semantic-ui-react';
import axios from 'axios';

export default class AddressField extends React.Component {
    getRandomAddr = (label) => {
        var fromArr = [
            'Волгоградская обл, Городищенский р-н, Ерзовка рп, Промышленная ул, дом № 10',
            'Ставропольский край, Ставрополь, Пригородная Ул., дом 205',
            'рабочий посёлок Ерзовка, Промышленная ул., 4А'
        ];

        var toArr = [
            'Челябинск, ул. Рылеева 2Б',
            'Екатеринбург, Кольцовский тракт, 13/1'
        ];

        var getRandomEl = (arr) => {
            return arr[Math.floor(Math.random() * arr.length)];
        };

        return this.props.label == 'Откуда' ?  
        getRandomEl(fromArr) : 
        getRandomEl(toArr);
    }
    state = {
        focused: false,
        exampleAddress: this.getRandomAddr(this.props.label),
        address: '',
        addressCoord: '',
        isMouseOver: false
    }

    clear = () => {
        this.setState({focused: false,
            exampleAddress: this.getRandomAddr(this.props.label),
            address: '',
            addressCoord: ''});
        this.props.onSetAddrInfo({
            coord: null,
            province: null
        });

    }

    onChange = (e) => {
      this.setState({
        address: e.target.value, 
      });
      console.log('e.target.value: - ' + e.target.value + ', e.target.value === \'москва\':' + (e.target.value === 'москва'));
      if(e.target.value.toLowerCase() === 'москва' || e.target.value.toLowerCase() === 'моск'){
          var addressCoord = '37.622504 55.753215';
          this.setState({
              addressCoord: addressCoord
          });
          this.props.onSetAddrInfo({
              coord: addressCoord,
              province: 'Московская область'
          });
          return;
      }
          
      var el = this;
      axios.get("https://geocode-maps.yandex.ru/1.x/?format=json&apikey=0b64d10c-fa8c-49e2-a94a-a58cdf6048d9&geocode="+ e.target.value, {mode: 'cors'}).then((data) =>{
        console.log(data.data.response);
        var addressCoord = '';
        var province = '';
        var featureMember = data.data.response.GeoObjectCollection.featureMember[0];
        if(featureMember !== undefined && featureMember.GeoObject.Point.pos !== undefined){
            addressCoord = featureMember.GeoObject.Point.pos;
            //debugger;
            if(featureMember.GeoObject !== undefined &&
                featureMember.GeoObject.metaDataProperty  !== undefined &&
                featureMember.GeoObject.metaDataProperty.GeocoderMetaData  !== undefined &&
                featureMember.GeoObject.metaDataProperty.GeocoderMetaData.AddressDetails  !== undefined &&
                featureMember.GeoObject.metaDataProperty.GeocoderMetaData.AddressDetails.Country  !== undefined &&
                featureMember.GeoObject.metaDataProperty.GeocoderMetaData.AddressDetails.Country.AdministrativeArea  !== undefined &&
                featureMember.GeoObject.metaDataProperty.GeocoderMetaData.AddressDetails.Country.AdministrativeArea.AdministrativeAreaName  !== undefined)
                province = data.data.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.AddressDetails.Country.AdministrativeArea.AdministrativeAreaName;
        }

        province = province.replace('область', 'обл');
              //console.log('province: ' + province);
        if(addressCoord != ''){
            el.setState({
                addressCoord: addressCoord
            });
            this.props.onSetAddrInfo({
                coord: addressCoord,
                province: province
            });    
        }
      })
    }

    onFocus = (e) => {
        this.setState({focused: true});
    }

    onBlur = (e) => {
        setTimeout(()=>{
            this.setState({focused: false});
        }, 200);
    }

    render() {
        return (
            <Form.Field>
            <label>{this.props.label}</label>
            <textarea
                id={this.props.elementId}
                style={{resize: 'none'}}
                rows={2}    
                value={this.state.address} 
                onChange={this.onChange} 
                onFocus={this.onFocus}
                onMouseOver={()=>{this.setState({isMouseOver: true})}}
                onMouseLeave={()=>{this.setState({isMouseOver: false})}}
                onBlur={this.onBlur}
                placeholder={this.props.placeholder} />
                <Transition visible={this.state.address == '' && this.state.focused} animation='scale' duration={200}>
                    <div className='description' style={{color: 'white'}}>
                        Например: <a style={{color: 'white', textDecoration: 'underline'}} href="#" onClick={() => {
                        this.onChange({target:{value:this.state.exampleAddress}});
                    }}>{this.state.exampleAddress}</a>
                    </div>
                </Transition>
                <Transition visible={this.state.address !== ''} animation='scale' duration={500}>
                    <Icon 
                        style={{position: 'absolute', right: '12px', top: '37px', color: '#d4d4d5', cursor: 'pointer'}} 
                        onClick={this.clear}
                        name={'remove'}/>
                </Transition>
                
        </Form.Field>   
        );
    }
  }