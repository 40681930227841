export default class Regions {
    constructor() {
        var arr = [];
        arr.push({from: "г. Москва", to: "г. Москва", percentOfReturns: 0.47});
        arr.push({from: "г. Москва", to: "Ленинградская обл", percentOfReturns: 0.8836815464});
        arr.push({from: "г. Москва", to: "Московская обл", percentOfReturns: 0.47});
        arr.push({from: "г. Москва", to: "Санкт-Петербург", percentOfReturns: 0.92});
        arr.push({from: "г. Москва", to: "Белгородская обл", percentOfReturns: 1.032362027});
        arr.push({from: "г. Москва", to: "Владимирская обл", percentOfReturns: 0.47});
        arr.push({from: "г. Москва", to: "Волгоградская обл", percentOfReturns: 0.7241927852});
        arr.push({from: "г. Москва", to: "Воронежская обл", percentOfReturns: 0.7625942199});
        arr.push({from: "г. Москва", to: "Ивановская обл", percentOfReturns: 0.5333261162});
        arr.push({from: "г. Москва", to: "Калужская обл", percentOfReturns: 0.47});
        arr.push({from: "г. Москва", to: "Краснодарский край", percentOfReturns: 0.6308422513});
        arr.push({from: "г. Москва", to: "Липецкая обл", percentOfReturns: 0.7155174316});
        arr.push({from: "г. Москва", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "г. Москва", to: "Пермский край", percentOfReturns: 0.7386976871});
        arr.push({from: "г. Москва", to: "Ростовская обл", percentOfReturns: 0.7460847446});
        arr.push({from: "г. Москва", to: "Самарская обл", percentOfReturns: 0.682038571});
        arr.push({from: "г. Москва", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "г. Москва", to: "Ставропольский край", percentOfReturns: 0.780143135});
        arr.push({from: "г. Москва", to: "Тульская обл", percentOfReturns: 0.47});
        arr.push({from: "г. Москва", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "г. Москва", to: "Ярославская обл", percentOfReturns: 0.3954295656});
        arr.push({from: "г. Москва", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "г. Москва", to: "Ульяновская обл", percentOfReturns: 0.6202341737});
        arr.push({from: "г. Москва", to: "Брянская обл", percentOfReturns: 0.7357335871});
        arr.push({from: "г. Москва", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "г. Москва", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "г. Москва", to: "Архангельская обл", percentOfReturns: 0.6210495687});
        arr.push({from: "г. Москва", to: "Астраханская обл", percentOfReturns: 0.7423655211});
        arr.push({from: "г. Москва", to: "Вологодская обл", percentOfReturns: 0.5978681887});
        arr.push({from: "г. Москва", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "г. Москва", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "г. Москва", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "г. Москва", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "г. Москва", to: "Кабардино-Балкарская Республика", percentOfReturns: 0.84});
        arr.push({from: "г. Москва", to: "Калининградская обл", percentOfReturns: 0.4830235003});
        arr.push({from: "г. Москва", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "г. Москва", to: "Карачаево-Черкесская Республика", percentOfReturns: 0.82});
        arr.push({from: "г. Москва", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "г. Москва", to: "Кировская обл", percentOfReturns: 0.6597362407});
        arr.push({from: "г. Москва", to: "Костромская обл", percentOfReturns: 0.5349812568});
        arr.push({from: "г. Москва", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "г. Москва", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "г. Москва", to: "Курская обл", percentOfReturns: 0.8271241351});
        arr.push({from: "г. Москва", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "г. Москва", to: "Мурманская обл", percentOfReturns: 0.6576788681});
        arr.push({from: "г. Москва", to: "Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "г. Москва", to: "Нижегородская обл", percentOfReturns: 0.6953280758});
        arr.push({from: "г. Москва", to: "Новгородская обл", percentOfReturns: 0.8212070195});
        arr.push({from: "г. Москва", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "г. Москва", to: "Оренбургская обл", percentOfReturns: 0.6482695668});
        arr.push({from: "г. Москва", to: "Орловская обл", percentOfReturns: 0.6796263426});
        arr.push({from: "г. Москва", to: "Пензенская обл", percentOfReturns: 0.6759425671});
        arr.push({from: "г. Москва", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "г. Москва", to: "Псковская обл", percentOfReturns: 0.5195501327});
        arr.push({from: "г. Москва", to: "Республика Адыгея", percentOfReturns: 0.7944580255});
        arr.push({from: "г. Москва", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "г. Москва", to: "Республика Башкортостан", percentOfReturns: 0.7348977438});
        arr.push({from: "г. Москва", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "г. Москва", to: "Республика Дагестан", percentOfReturns: 0.77});
        arr.push({from: "г. Москва", to: "Республика Ингушетия", percentOfReturns: 0.77});
        arr.push({from: "г. Москва", to: "Республика Калмыкия", percentOfReturns: 0.86});
        arr.push({from: "г. Москва", to: "Республика Карелия", percentOfReturns: 0.6916802688});
        arr.push({from: "г. Москва", to: "Республика Коми", percentOfReturns: 0.556420352});
        arr.push({from: "г. Москва", to: "Республика Крым", percentOfReturns: 0.4669283633});
        arr.push({from: "г. Москва", to: "Республика Марий Эл", percentOfReturns: 0.5664241478});
        arr.push({from: "г. Москва", to: "Республика Мордовия", percentOfReturns: 0.6367239818});
        arr.push({from: "г. Москва", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "г. Москва", to: "Республика Северная Осетия - Алания", percentOfReturns: 0.6641109995});
        arr.push({from: "г. Москва", to: "Республика Татарстан", percentOfReturns: 0.599926894});
        arr.push({from: "г. Москва", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "г. Москва", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "г. Москва", to: "Рязанская обл", percentOfReturns: 0.4311485704});
        arr.push({from: "г. Москва", to: "Саратовская обл", percentOfReturns: 0.7249067544});
        arr.push({from: "г. Москва", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "г. Москва", to: "Севастополь", percentOfReturns: 0.72});
        arr.push({from: "г. Москва", to: "Смоленская обл", percentOfReturns: 0.9842241888});
        arr.push({from: "г. Москва", to: "Тамбовская обл", percentOfReturns: 0.6811521187});
        arr.push({from: "г. Москва", to: "Тверская обл", percentOfReturns: 0.47});
        arr.push({from: "г. Москва", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "г. Москва", to: "Удмуртская Республика", percentOfReturns: 0.5097286307});
        arr.push({from: "г. Москва", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "г. Москва", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "г. Москва", to: "Чеченская Республика", percentOfReturns: 0.77});
        arr.push({from: "г. Москва", to: "Чувашская Республика ", percentOfReturns: 0.5500897862});
        arr.push({from: "г. Москва", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "г. Москва", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Ленинградская обл", to: "г. Москва", percentOfReturns: 1.08});
        arr.push({from: "Ленинградская обл", to: "Ленинградская обл", percentOfReturns: 0.47});
        arr.push({from: "Ленинградская обл", to: "Московская обл", percentOfReturns: 1.08});
        arr.push({from: "Ленинградская обл", to: "Санкт-Петербург", percentOfReturns: 0.47});
        arr.push({from: "Ленинградская обл", to: "Белгородская обл", percentOfReturns: 1.032362027});
        arr.push({from: "Ленинградская обл", to: "Владимирская обл", percentOfReturns: 0.47});
        arr.push({from: "Ленинградская обл", to: "Волгоградская обл", percentOfReturns: 0.7241927852});
        arr.push({from: "Ленинградская обл", to: "Воронежская обл", percentOfReturns: 0.7625942199});
        arr.push({from: "Ленинградская обл", to: "Ивановская обл", percentOfReturns: 0.5333261162});
        arr.push({from: "Ленинградская обл", to: "Калужская обл", percentOfReturns: 0.47});
        arr.push({from: "Ленинградская обл", to: "Краснодарский край", percentOfReturns: 0.6308422513});
        arr.push({from: "Ленинградская обл", to: "Липецкая обл", percentOfReturns: 0.7155174316});
        arr.push({from: "Ленинградская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Ленинградская обл", to: "Пермский край", percentOfReturns: 0.7386976871});
        arr.push({from: "Ленинградская обл", to: "Ростовская обл", percentOfReturns: 0.7460847446});
        arr.push({from: "Ленинградская обл", to: "Самарская обл", percentOfReturns: 0.682038571});
        arr.push({from: "Ленинградская обл", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Ленинградская обл", to: "Ставропольский край", percentOfReturns: 0.780143135});
        arr.push({from: "Ленинградская обл", to: "Тульская обл", percentOfReturns: 0.47});
        arr.push({from: "Ленинградская обл", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Ленинградская обл", to: "Ярославская обл", percentOfReturns: 0.3954295656});
        arr.push({from: "Ленинградская обл", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Ленинградская обл", to: "Ульяновская обл", percentOfReturns: 0.6202341737});
        arr.push({from: "Ленинградская обл", to: "Брянская обл", percentOfReturns: 0.7357335871});
        arr.push({from: "Ленинградская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Ленинградская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Ленинградская обл", to: "Архангельская обл", percentOfReturns: 0.6210495687});
        arr.push({from: "Ленинградская обл", to: "Астраханская обл", percentOfReturns: 0.7423655211});
        arr.push({from: "Ленинградская обл", to: "Вологодская обл", percentOfReturns: 0.5978681887});
        arr.push({from: "Ленинградская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Ленинградская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Ленинградская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Ленинградская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Ленинградская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 0.84});
        arr.push({from: "Ленинградская обл", to: "Калининградская обл", percentOfReturns: 0.4830235003});
        arr.push({from: "Ленинградская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Ленинградская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 0.82});
        arr.push({from: "Ленинградская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Ленинградская обл", to: "Кировская обл", percentOfReturns: 0.6597362407});
        arr.push({from: "Ленинградская обл", to: "Костромская обл", percentOfReturns: 0.5349812568});
        arr.push({from: "Ленинградская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Ленинградская обл", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Ленинградская обл", to: "Курская обл", percentOfReturns: 0.8271241351});
        arr.push({from: "Ленинградская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Ленинградская обл", to: "Мурманская обл", percentOfReturns: 0.6576788681});
        arr.push({from: "Ленинградская обл", to: "Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Ленинградская обл", to: "Нижегородская обл", percentOfReturns: 0.6953280758});
        arr.push({from: "Ленинградская обл", to: "Новгородская обл", percentOfReturns: 0.8212070195});
        arr.push({from: "Ленинградская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Ленинградская обл", to: "Оренбургская обл", percentOfReturns: 0.6482695668});
        arr.push({from: "Ленинградская обл", to: "Орловская обл", percentOfReturns: 0.6796263426});
        arr.push({from: "Ленинградская обл", to: "Пензенская обл", percentOfReturns: 0.6759425671});
        arr.push({from: "Ленинградская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Ленинградская обл", to: "Псковская обл", percentOfReturns: 0.5195501327});
        arr.push({from: "Ленинградская обл", to: "Республика Адыгея", percentOfReturns: 0.7944580255});
        arr.push({from: "Ленинградская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Ленинградская обл", to: "Республика Башкортостан", percentOfReturns: 0.7348977438});
        arr.push({from: "Ленинградская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Ленинградская обл", to: "Республика Дагестан", percentOfReturns: 0.77});
        arr.push({from: "Ленинградская обл", to: "Республика Ингушетия", percentOfReturns: 0.77});
        arr.push({from: "Ленинградская обл", to: "Республика Калмыкия", percentOfReturns: 0.86});
        arr.push({from: "Ленинградская обл", to: "Республика Карелия", percentOfReturns: 0.6916802688});
        arr.push({from: "Ленинградская обл", to: "Республика Коми", percentOfReturns: 0.556420352});
        arr.push({from: "Ленинградская обл", to: "Республика Крым", percentOfReturns: 0.4669283633});
        arr.push({from: "Ленинградская обл", to: "Республика Марий Эл", percentOfReturns: 0.5664241478});
        arr.push({from: "Ленинградская обл", to: "Республика Мордовия", percentOfReturns: 0.6367239818});
        arr.push({from: "Ленинградская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Ленинградская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 0.6641109995});
        arr.push({from: "Ленинградская обл", to: "Республика Татарстан", percentOfReturns: 0.599926894});
        arr.push({from: "Ленинградская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Ленинградская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Ленинградская обл", to: "Рязанская обл", percentOfReturns: 0.4311485704});
        arr.push({from: "Ленинградская обл", to: "Саратовская обл", percentOfReturns: 0.7249067544});
        arr.push({from: "Ленинградская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Ленинградская обл", to: "Севастополь", percentOfReturns: 0.72});
        arr.push({from: "Ленинградская обл", to: "Смоленская обл", percentOfReturns: 0.9842241888});
        arr.push({from: "Ленинградская обл", to: "Тамбовская обл", percentOfReturns: 0.6811521187});
        arr.push({from: "Ленинградская обл", to: "Тверская обл", percentOfReturns: 0.47});
        arr.push({from: "Ленинградская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Ленинградская обл", to: "Удмуртская Республика", percentOfReturns: 0.5097286307});
        arr.push({from: "Ленинградская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Ленинградская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Ленинградская обл", to: "Чеченская Республика", percentOfReturns: 0.77});
        arr.push({from: "Ленинградская обл", to: "Чувашская Республика ", percentOfReturns: 0.5500897862});
        arr.push({from: "Ленинградская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Ленинградская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Московская обл", to: "г. Москва", percentOfReturns: 0.47});
        arr.push({from: "Московская обл", to: "Ленинградская обл", percentOfReturns: 0.92});
        arr.push({from: "Московская обл", to: "Московская обл", percentOfReturns: 0.47});
        arr.push({from: "Московская обл", to: "Санкт-Петербург", percentOfReturns: 0.92});
        arr.push({from: "Московская обл", to: "Белгородская обл", percentOfReturns: 1.032362027});
        arr.push({from: "Московская обл", to: "Владимирская обл", percentOfReturns: 0.47});
        arr.push({from: "Московская обл", to: "Волгоградская обл", percentOfReturns: 0.7241927852});
        arr.push({from: "Московская обл", to: "Воронежская обл", percentOfReturns: 0.7625942199});
        arr.push({from: "Московская обл", to: "Ивановская обл", percentOfReturns: 0.5333261162});
        arr.push({from: "Московская обл", to: "Калужская обл", percentOfReturns: 0.47});
        arr.push({from: "Московская обл", to: "Краснодарский край", percentOfReturns: 0.6308422513});
        arr.push({from: "Московская обл", to: "Липецкая обл", percentOfReturns: 0.7155174316});
        arr.push({from: "Московская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Московская обл", to: "Пермский край", percentOfReturns: 0.7386976871});
        arr.push({from: "Московская обл", to: "Ростовская обл", percentOfReturns: 0.7460847446});
        arr.push({from: "Московская обл", to: "Самарская обл", percentOfReturns: 0.682038571});
        arr.push({from: "Московская обл", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Московская обл", to: "Ставропольский край", percentOfReturns: 0.780143135});
        arr.push({from: "Московская обл", to: "Тульская обл", percentOfReturns: 0.47});
        arr.push({from: "Московская обл", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Московская обл", to: "Ярославская обл", percentOfReturns: 0.3954295656});
        arr.push({from: "Московская обл", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Московская обл", to: "Ульяновская обл", percentOfReturns: 0.6202341737});
        arr.push({from: "Московская обл", to: "Брянская обл", percentOfReturns: 0.7357335871});
        arr.push({from: "Московская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Московская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Московская обл", to: "Архангельская обл", percentOfReturns: 0.6210495687});
        arr.push({from: "Московская обл", to: "Астраханская обл", percentOfReturns: 0.7423655211});
        arr.push({from: "Московская обл", to: "Вологодская обл", percentOfReturns: 0.5978681887});
        arr.push({from: "Московская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Московская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Московская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Московская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Московская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 0.84});
        arr.push({from: "Московская обл", to: "Калининградская обл", percentOfReturns: 0.4830235003});
        arr.push({from: "Московская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Московская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 0.82});
        arr.push({from: "Московская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Московская обл", to: "Кировская обл", percentOfReturns: 0.6597362407});
        arr.push({from: "Московская обл", to: "Костромская обл", percentOfReturns: 0.5349812568});
        arr.push({from: "Московская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Московская обл", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Московская обл", to: "Курская обл", percentOfReturns: 0.8271241351});
        arr.push({from: "Московская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Московская обл", to: "Мурманская обл", percentOfReturns: 0.6576788681});
        arr.push({from: "Московская обл", to: "Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Московская обл", to: "Нижегородская обл", percentOfReturns: 0.6953280758});
        arr.push({from: "Московская обл", to: "Новгородская обл", percentOfReturns: 0.8212070195});
        arr.push({from: "Московская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Московская обл", to: "Оренбургская обл", percentOfReturns: 0.6482695668});
        arr.push({from: "Московская обл", to: "Орловская обл", percentOfReturns: 0.6796263426});
        arr.push({from: "Московская обл", to: "Пензенская обл", percentOfReturns: 0.6759425671});
        arr.push({from: "Московская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Московская обл", to: "Псковская обл", percentOfReturns: 0.5195501327});
        arr.push({from: "Московская обл", to: "Республика Адыгея", percentOfReturns: 0.7944580255});
        arr.push({from: "Московская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Московская обл", to: "Республика Башкортостан", percentOfReturns: 0.7348977438});
        arr.push({from: "Московская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Московская обл", to: "Республика Дагестан", percentOfReturns: 0.77});
        arr.push({from: "Московская обл", to: "Республика Ингушетия", percentOfReturns: 0.77});
        arr.push({from: "Московская обл", to: "Республика Калмыкия", percentOfReturns: 0.86});
        arr.push({from: "Московская обл", to: "Республика Карелия", percentOfReturns: 0.6916802688});
        arr.push({from: "Московская обл", to: "Республика Коми", percentOfReturns: 0.556420352});
        arr.push({from: "Московская обл", to: "Республика Крым", percentOfReturns: 0.4669283633});
        arr.push({from: "Московская обл", to: "Республика Марий Эл", percentOfReturns: 0.5664241478});
        arr.push({from: "Московская обл", to: "Республика Мордовия", percentOfReturns: 0.6367239818});
        arr.push({from: "Московская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Московская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 0.6641109995});
        arr.push({from: "Московская обл", to: "Республика Татарстан", percentOfReturns: 0.599926894});
        arr.push({from: "Московская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Московская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Московская обл", to: "Рязанская обл", percentOfReturns: 0.4311485704});
        arr.push({from: "Московская обл", to: "Саратовская обл", percentOfReturns: 0.7249067544});
        arr.push({from: "Московская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Московская обл", to: "Севастополь", percentOfReturns: 0.72});
        arr.push({from: "Московская обл", to: "Смоленская обл", percentOfReturns: 0.9842241888});
        arr.push({from: "Московская обл", to: "Тамбовская обл", percentOfReturns: 0.6811521187});
        arr.push({from: "Московская обл", to: "Тверская обл", percentOfReturns: 0.47});
        arr.push({from: "Московская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Московская обл", to: "Удмуртская Республика", percentOfReturns: 0.5097286307});
        arr.push({from: "Московская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Московская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Московская обл", to: "Чеченская Республика", percentOfReturns: 0.77});
        arr.push({from: "Московская обл", to: "Чувашская Республика ", percentOfReturns: 0.5500897862});
        arr.push({from: "Московская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Московская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Санкт-Петербург", to: "г. Москва", percentOfReturns: 1.08});
        arr.push({from: "Санкт-Петербург", to: "Ленинградская обл", percentOfReturns: 0.47});
        arr.push({from: "Санкт-Петербург", to: "Московская обл", percentOfReturns: 1.08});
        arr.push({from: "Санкт-Петербург", to: "Санкт-Петербург", percentOfReturns: 0.47});
        arr.push({from: "Санкт-Петербург", to: "Белгородская обл", percentOfReturns: 1.032362027});
        arr.push({from: "Санкт-Петербург", to: "Владимирская обл", percentOfReturns: 0.47});
        arr.push({from: "Санкт-Петербург", to: "Волгоградская обл", percentOfReturns: 0.7241927852});
        arr.push({from: "Санкт-Петербург", to: "Воронежская обл", percentOfReturns: 0.7625942199});
        arr.push({from: "Санкт-Петербург", to: "Ивановская обл", percentOfReturns: 0.5333261162});
        arr.push({from: "Санкт-Петербург", to: "Калужская обл", percentOfReturns: 0.47});
        arr.push({from: "Санкт-Петербург", to: "Краснодарский край", percentOfReturns: 0.6308422513});
        arr.push({from: "Санкт-Петербург", to: "Липецкая обл", percentOfReturns: 0.7155174316});
        arr.push({from: "Санкт-Петербург", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Санкт-Петербург", to: "Пермский край", percentOfReturns: 0.7386976871});
        arr.push({from: "Санкт-Петербург", to: "Ростовская обл", percentOfReturns: 0.7460847446});
        arr.push({from: "Санкт-Петербург", to: "Самарская обл", percentOfReturns: 0.682038571});
        arr.push({from: "Санкт-Петербург", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Санкт-Петербург", to: "Ставропольский край", percentOfReturns: 0.780143135});
        arr.push({from: "Санкт-Петербург", to: "Тульская обл", percentOfReturns: 0.47});
        arr.push({from: "Санкт-Петербург", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Санкт-Петербург", to: "Ярославская обл", percentOfReturns: 0.3954295656});
        arr.push({from: "Санкт-Петербург", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Санкт-Петербург", to: "Ульяновская обл", percentOfReturns: 0.6202341737});
        arr.push({from: "Санкт-Петербург", to: "Брянская обл", percentOfReturns: 0.7357335871});
        arr.push({from: "Санкт-Петербург", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Санкт-Петербург", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Санкт-Петербург", to: "Архангельская обл", percentOfReturns: 0.6210495687});
        arr.push({from: "Санкт-Петербург", to: "Астраханская обл", percentOfReturns: 0.7423655211});
        arr.push({from: "Санкт-Петербург", to: "Вологодская обл", percentOfReturns: 0.5978681887});
        arr.push({from: "Санкт-Петербург", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Санкт-Петербург", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Санкт-Петербург", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Санкт-Петербург", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Санкт-Петербург", to: "Кабардино-Балкарская Республика", percentOfReturns: 0.84});
        arr.push({from: "Санкт-Петербург", to: "Калининградская обл", percentOfReturns: 0.4830235003});
        arr.push({from: "Санкт-Петербург", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Санкт-Петербург", to: "Карачаево-Черкесская Республика", percentOfReturns: 0.82});
        arr.push({from: "Санкт-Петербург", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Санкт-Петербург", to: "Кировская обл", percentOfReturns: 0.6597362407});
        arr.push({from: "Санкт-Петербург", to: "Костромская обл", percentOfReturns: 0.5349812568});
        arr.push({from: "Санкт-Петербург", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Санкт-Петербург", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Санкт-Петербург", to: "Курская обл", percentOfReturns: 0.8271241351});
        arr.push({from: "Санкт-Петербург", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Санкт-Петербург", to: "Мурманская обл", percentOfReturns: 0.6576788681});
        arr.push({from: "Санкт-Петербург", to: "Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Санкт-Петербург", to: "Нижегородская обл", percentOfReturns: 0.6953280758});
        arr.push({from: "Санкт-Петербург", to: "Новгородская обл", percentOfReturns: 0.8212070195});
        arr.push({from: "Санкт-Петербург", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Санкт-Петербург", to: "Оренбургская обл", percentOfReturns: 0.6482695668});
        arr.push({from: "Санкт-Петербург", to: "Орловская обл", percentOfReturns: 0.6796263426});
        arr.push({from: "Санкт-Петербург", to: "Пензенская обл", percentOfReturns: 0.6759425671});
        arr.push({from: "Санкт-Петербург", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Санкт-Петербург", to: "Псковская обл", percentOfReturns: 0.5195501327});
        arr.push({from: "Санкт-Петербург", to: "Республика Адыгея", percentOfReturns: 0.7944580255});
        arr.push({from: "Санкт-Петербург", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Санкт-Петербург", to: "Республика Башкортостан", percentOfReturns: 0.7348977438});
        arr.push({from: "Санкт-Петербург", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Санкт-Петербург", to: "Республика Дагестан", percentOfReturns: 0.77});
        arr.push({from: "Санкт-Петербург", to: "Республика Ингушетия", percentOfReturns: 0.77});
        arr.push({from: "Санкт-Петербург", to: "Республика Калмыкия", percentOfReturns: 0.86});
        arr.push({from: "Санкт-Петербург", to: "Республика Карелия", percentOfReturns: 0.6916802688});
        arr.push({from: "Санкт-Петербург", to: "Республика Коми", percentOfReturns: 0.556420352});
        arr.push({from: "Санкт-Петербург", to: "Республика Крым", percentOfReturns: 0.4669283633});
        arr.push({from: "Санкт-Петербург", to: "Республика Марий Эл", percentOfReturns: 0.5664241478});
        arr.push({from: "Санкт-Петербург", to: "Республика Мордовия", percentOfReturns: 0.6367239818});
        arr.push({from: "Санкт-Петербург", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Санкт-Петербург", to: "Республика Северная Осетия - Алания", percentOfReturns: 0.6641109995});
        arr.push({from: "Санкт-Петербург", to: "Республика Татарстан", percentOfReturns: 0.599926894});
        arr.push({from: "Санкт-Петербург", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Санкт-Петербург", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Санкт-Петербург", to: "Рязанская обл", percentOfReturns: 0.4311485704});
        arr.push({from: "Санкт-Петербург", to: "Саратовская обл", percentOfReturns: 0.7249067544});
        arr.push({from: "Санкт-Петербург", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Санкт-Петербург", to: "Севастополь", percentOfReturns: 0.72});
        arr.push({from: "Санкт-Петербург", to: "Смоленская обл", percentOfReturns: 0.9842241888});
        arr.push({from: "Санкт-Петербург", to: "Тамбовская обл", percentOfReturns: 0.6811521187});
        arr.push({from: "Санкт-Петербург", to: "Тверская обл", percentOfReturns: 0.47});
        arr.push({from: "Санкт-Петербург", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Санкт-Петербург", to: "Удмуртская Республика", percentOfReturns: 0.5097286307});
        arr.push({from: "Санкт-Петербург", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Санкт-Петербург", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Санкт-Петербург", to: "Чеченская Республика", percentOfReturns: 0.77});
        arr.push({from: "Санкт-Петербург", to: "Чувашская Республика ", percentOfReturns: 0.5500897862});
        arr.push({from: "Санкт-Петербург", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Санкт-Петербург", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Белгородская обл", to: "г. Москва", percentOfReturns: 0.9686524436});
        arr.push({from: "Белгородская обл", to: "Ленинградская обл", percentOfReturns: 0.8836815464});
        arr.push({from: "Белгородская обл", to: "Московская обл", percentOfReturns: 1.0});
        arr.push({from: "Белгородская обл", to: "Санкт-Петербург", percentOfReturns: 0.92});
        arr.push({from: "Белгородская обл", to: "Белгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Белгородская обл", to: "Владимирская обл", percentOfReturns: 1.0});
        arr.push({from: "Белгородская обл", to: "Волгоградская обл", percentOfReturns: 0.7241927852});
        arr.push({from: "Белгородская обл", to: "Воронежская обл", percentOfReturns: 1.0});
        arr.push({from: "Белгородская обл", to: "Ивановская обл", percentOfReturns: 1.0});
        arr.push({from: "Белгородская обл", to: "Калужская обл", percentOfReturns: 1.0});
        arr.push({from: "Белгородская обл", to: "Краснодарский край", percentOfReturns: 0.6308422513});
        arr.push({from: "Белгородская обл", to: "Липецкая обл", percentOfReturns: 1.0});
        arr.push({from: "Белгородская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Белгородская обл", to: "Пермский край", percentOfReturns: 0.7386976871});
        arr.push({from: "Белгородская обл", to: "Ростовская обл", percentOfReturns: 0.7460847446});
        arr.push({from: "Белгородская обл", to: "Самарская обл", percentOfReturns: 0.682038571});
        arr.push({from: "Белгородская обл", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Белгородская обл", to: "Ставропольский край", percentOfReturns: 0.780143135});
        arr.push({from: "Белгородская обл", to: "Тульская обл", percentOfReturns: 1.0});
        arr.push({from: "Белгородская обл", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Белгородская обл", to: "Ярославская обл", percentOfReturns: 1.0});
        arr.push({from: "Белгородская обл", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Белгородская обл", to: "Ульяновская обл", percentOfReturns: 0.6202341737});
        arr.push({from: "Белгородская обл", to: "Брянская обл", percentOfReturns: 1.0});
        arr.push({from: "Белгородская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Белгородская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Белгородская обл", to: "Архангельская обл", percentOfReturns: 0.6210495687});
        arr.push({from: "Белгородская обл", to: "Астраханская обл", percentOfReturns: 0.7423655211});
        arr.push({from: "Белгородская обл", to: "Вологодская обл", percentOfReturns: 0.5978681887});
        arr.push({from: "Белгородская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Белгородская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Белгородская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Белгородская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Белгородская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 0.84});
        arr.push({from: "Белгородская обл", to: "Калининградская обл", percentOfReturns: 0.4830235003});
        arr.push({from: "Белгородская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Белгородская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 0.82});
        arr.push({from: "Белгородская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Белгородская обл", to: "Кировская обл", percentOfReturns: 0.6597362407});
        arr.push({from: "Белгородская обл", to: "Костромская обл", percentOfReturns: 1.0});
        arr.push({from: "Белгородская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Белгородская обл", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Белгородская обл", to: "Курская обл", percentOfReturns: 1.0});
        arr.push({from: "Белгородская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Белгородская обл", to: "Мурманская обл", percentOfReturns: 0.6576788681});
        arr.push({from: "Белгородская обл", to: "Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Белгородская обл", to: "Нижегородская обл", percentOfReturns: 0.6953280758});
        arr.push({from: "Белгородская обл", to: "Новгородская обл", percentOfReturns: 0.8212070195});
        arr.push({from: "Белгородская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Белгородская обл", to: "Оренбургская обл", percentOfReturns: 0.6482695668});
        arr.push({from: "Белгородская обл", to: "Орловская обл", percentOfReturns: 1.0});
        arr.push({from: "Белгородская обл", to: "Пензенская обл", percentOfReturns: 0.6759425671});
        arr.push({from: "Белгородская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Белгородская обл", to: "Псковская обл", percentOfReturns: 0.5195501327});
        arr.push({from: "Белгородская обл", to: "Республика Адыгея", percentOfReturns: 0.7944580255});
        arr.push({from: "Белгородская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Белгородская обл", to: "Республика Башкортостан", percentOfReturns: 0.7348977438});
        arr.push({from: "Белгородская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Белгородская обл", to: "Республика Дагестан", percentOfReturns: 0.77});
        arr.push({from: "Белгородская обл", to: "Республика Ингушетия", percentOfReturns: 0.77});
        arr.push({from: "Белгородская обл", to: "Республика Калмыкия", percentOfReturns: 0.86});
        arr.push({from: "Белгородская обл", to: "Республика Карелия", percentOfReturns: 0.6916802688});
        arr.push({from: "Белгородская обл", to: "Республика Коми", percentOfReturns: 0.556420352});
        arr.push({from: "Белгородская обл", to: "Республика Крым", percentOfReturns: 0.4669283633});
        arr.push({from: "Белгородская обл", to: "Республика Марий Эл", percentOfReturns: 0.5664241478});
        arr.push({from: "Белгородская обл", to: "Республика Мордовия", percentOfReturns: 0.6367239818});
        arr.push({from: "Белгородская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Белгородская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 0.6641109995});
        arr.push({from: "Белгородская обл", to: "Республика Татарстан", percentOfReturns: 0.599926894});
        arr.push({from: "Белгородская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Белгородская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Белгородская обл", to: "Рязанская обл", percentOfReturns: 1.0});
        arr.push({from: "Белгородская обл", to: "Саратовская обл", percentOfReturns: 0.7249067544});
        arr.push({from: "Белгородская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Белгородская обл", to: "Севастополь", percentOfReturns: 0.72});
        arr.push({from: "Белгородская обл", to: "Смоленская обл", percentOfReturns: 1.0});
        arr.push({from: "Белгородская обл", to: "Тамбовская обл", percentOfReturns: 1.0});
        arr.push({from: "Белгородская обл", to: "Тверская обл", percentOfReturns: 1.0});
        arr.push({from: "Белгородская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Белгородская обл", to: "Удмуртская Республика", percentOfReturns: 0.5097286307});
        arr.push({from: "Белгородская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Белгородская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Белгородская обл", to: "Чеченская Республика", percentOfReturns: 0.77});
        arr.push({from: "Белгородская обл", to: "Чувашская Республика ", percentOfReturns: 0.5500897862});
        arr.push({from: "Белгородская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Белгородская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Владимирская обл", to: "г. Москва", percentOfReturns: 2.127659574});
        arr.push({from: "Владимирская обл", to: "Ленинградская обл", percentOfReturns: 0.8836815464});
        arr.push({from: "Владимирская обл", to: "Московская обл", percentOfReturns: 1.0});
        arr.push({from: "Владимирская обл", to: "Санкт-Петербург", percentOfReturns: 0.92});
        arr.push({from: "Владимирская обл", to: "Белгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Владимирская обл", to: "Владимирская обл", percentOfReturns: 1.0});
        arr.push({from: "Владимирская обл", to: "Волгоградская обл", percentOfReturns: 0.7241927852});
        arr.push({from: "Владимирская обл", to: "Воронежская обл", percentOfReturns: 1.0});
        arr.push({from: "Владимирская обл", to: "Ивановская обл", percentOfReturns: 1.0});
        arr.push({from: "Владимирская обл", to: "Калужская обл", percentOfReturns: 1.0});
        arr.push({from: "Владимирская обл", to: "Краснодарский край", percentOfReturns: 0.6308422513});
        arr.push({from: "Владимирская обл", to: "Липецкая обл", percentOfReturns: 1.0});
        arr.push({from: "Владимирская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Владимирская обл", to: "Пермский край", percentOfReturns: 0.7386976871});
        arr.push({from: "Владимирская обл", to: "Ростовская обл", percentOfReturns: 0.7460847446});
        arr.push({from: "Владимирская обл", to: "Самарская обл", percentOfReturns: 0.682038571});
        arr.push({from: "Владимирская обл", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Владимирская обл", to: "Ставропольский край", percentOfReturns: 0.780143135});
        arr.push({from: "Владимирская обл", to: "Тульская обл", percentOfReturns: 1.0});
        arr.push({from: "Владимирская обл", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Владимирская обл", to: "Ярославская обл", percentOfReturns: 1.0});
        arr.push({from: "Владимирская обл", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Владимирская обл", to: "Ульяновская обл", percentOfReturns: 0.6202341737});
        arr.push({from: "Владимирская обл", to: "Брянская обл", percentOfReturns: 1.0});
        arr.push({from: "Владимирская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Владимирская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Владимирская обл", to: "Архангельская обл", percentOfReturns: 0.6210495687});
        arr.push({from: "Владимирская обл", to: "Астраханская обл", percentOfReturns: 0.7423655211});
        arr.push({from: "Владимирская обл", to: "Вологодская обл", percentOfReturns: 0.5978681887});
        arr.push({from: "Владимирская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Владимирская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Владимирская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Владимирская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Владимирская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 0.84});
        arr.push({from: "Владимирская обл", to: "Калининградская обл", percentOfReturns: 0.4830235003});
        arr.push({from: "Владимирская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Владимирская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 0.82});
        arr.push({from: "Владимирская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Владимирская обл", to: "Кировская обл", percentOfReturns: 0.6597362407});
        arr.push({from: "Владимирская обл", to: "Костромская обл", percentOfReturns: 1.0});
        arr.push({from: "Владимирская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Владимирская обл", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Владимирская обл", to: "Курская обл", percentOfReturns: 1.0});
        arr.push({from: "Владимирская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Владимирская обл", to: "Мурманская обл", percentOfReturns: 0.6576788681});
        arr.push({from: "Владимирская обл", to: "Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Владимирская обл", to: "Нижегородская обл", percentOfReturns: 0.6953280758});
        arr.push({from: "Владимирская обл", to: "Новгородская обл", percentOfReturns: 0.8212070195});
        arr.push({from: "Владимирская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Владимирская обл", to: "Оренбургская обл", percentOfReturns: 0.6482695668});
        arr.push({from: "Владимирская обл", to: "Орловская обл", percentOfReturns: 1.0});
        arr.push({from: "Владимирская обл", to: "Пензенская обл", percentOfReturns: 0.6759425671});
        arr.push({from: "Владимирская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Владимирская обл", to: "Псковская обл", percentOfReturns: 0.5195501327});
        arr.push({from: "Владимирская обл", to: "Республика Адыгея", percentOfReturns: 0.7944580255});
        arr.push({from: "Владимирская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Владимирская обл", to: "Республика Башкортостан", percentOfReturns: 0.7348977438});
        arr.push({from: "Владимирская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Владимирская обл", to: "Республика Дагестан", percentOfReturns: 0.77});
        arr.push({from: "Владимирская обл", to: "Республика Ингушетия", percentOfReturns: 0.77});
        arr.push({from: "Владимирская обл", to: "Республика Калмыкия", percentOfReturns: 0.86});
        arr.push({from: "Владимирская обл", to: "Республика Карелия", percentOfReturns: 0.6916802688});
        arr.push({from: "Владимирская обл", to: "Республика Коми", percentOfReturns: 0.556420352});
        arr.push({from: "Владимирская обл", to: "Республика Крым", percentOfReturns: 0.4669283633});
        arr.push({from: "Владимирская обл", to: "Республика Марий Эл", percentOfReturns: 0.5664241478});
        arr.push({from: "Владимирская обл", to: "Республика Мордовия", percentOfReturns: 0.6367239818});
        arr.push({from: "Владимирская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Владимирская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 0.6641109995});
        arr.push({from: "Владимирская обл", to: "Республика Татарстан", percentOfReturns: 0.599926894});
        arr.push({from: "Владимирская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Владимирская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Владимирская обл", to: "Рязанская обл", percentOfReturns: 1.0});
        arr.push({from: "Владимирская обл", to: "Саратовская обл", percentOfReturns: 0.7249067544});
        arr.push({from: "Владимирская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Владимирская обл", to: "Севастополь", percentOfReturns: 0.72});
        arr.push({from: "Владимирская обл", to: "Смоленская обл", percentOfReturns: 1.0});
        arr.push({from: "Владимирская обл", to: "Тамбовская обл", percentOfReturns: 1.0});
        arr.push({from: "Владимирская обл", to: "Тверская обл", percentOfReturns: 1.0});
        arr.push({from: "Владимирская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Владимирская обл", to: "Удмуртская Республика", percentOfReturns: 0.5097286307});
        arr.push({from: "Владимирская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Владимирская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Владимирская обл", to: "Чеченская Республика", percentOfReturns: 0.77});
        arr.push({from: "Владимирская обл", to: "Чувашская Республика ", percentOfReturns: 0.5500897862});
        arr.push({from: "Владимирская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Владимирская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Волгоградская обл", to: "г. Москва", percentOfReturns: 1.38084778});
        arr.push({from: "Волгоградская обл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Волгоградская обл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Волгоградская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Волгоградская обл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Волгоградская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Волгоградская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Волгоградская обл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Волгоградская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Волгоградская обл", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Волгоградская обл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Волгоградская обл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Волгоградская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Волгоградская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Волгоградская обл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Волгоградская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Волгоградская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Волгоградская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Волгоградская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Волгоградская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Волгоградская обл", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Волгоградская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Волгоградская обл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Волгоградская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Волгоградская обл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Волгоградская обл", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Волгоградская обл", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Волгоградская обл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Волгоградская обл", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Волгоградская обл", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Волгоградская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Волгоградская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Волгоградская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Волгоградская обл", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Волгоградская обл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Волгоградская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Волгоградская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Волгоградская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Волгоградская обл", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Волгоградская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Волгоградская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Волгоградская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Воронежская обл", to: "г. Москва", percentOfReturns: 1.311313375});
        arr.push({from: "Воронежская обл", to: "Ленинградская обл", percentOfReturns: 0.8836815464});
        arr.push({from: "Воронежская обл", to: "Московская обл", percentOfReturns: 1.0});
        arr.push({from: "Воронежская обл", to: "Санкт-Петербург", percentOfReturns: 0.92});
        arr.push({from: "Воронежская обл", to: "Белгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Воронежская обл", to: "Владимирская обл", percentOfReturns: 1.0});
        arr.push({from: "Воронежская обл", to: "Волгоградская обл", percentOfReturns: 0.7241927852});
        arr.push({from: "Воронежская обл", to: "Воронежская обл", percentOfReturns: 1.0});
        arr.push({from: "Воронежская обл", to: "Ивановская обл", percentOfReturns: 1.0});
        arr.push({from: "Воронежская обл", to: "Калужская обл", percentOfReturns: 1.0});
        arr.push({from: "Воронежская обл", to: "Краснодарский край", percentOfReturns: 0.6308422513});
        arr.push({from: "Воронежская обл", to: "Липецкая обл", percentOfReturns: 1.0});
        arr.push({from: "Воронежская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Воронежская обл", to: "Пермский край", percentOfReturns: 0.7386976871});
        arr.push({from: "Воронежская обл", to: "Ростовская обл", percentOfReturns: 0.7460847446});
        arr.push({from: "Воронежская обл", to: "Самарская обл", percentOfReturns: 0.682038571});
        arr.push({from: "Воронежская обл", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Воронежская обл", to: "Ставропольский край", percentOfReturns: 0.780143135});
        arr.push({from: "Воронежская обл", to: "Тульская обл", percentOfReturns: 1.0});
        arr.push({from: "Воронежская обл", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Воронежская обл", to: "Ярославская обл", percentOfReturns: 1.0});
        arr.push({from: "Воронежская обл", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Воронежская обл", to: "Ульяновская обл", percentOfReturns: 0.6202341737});
        arr.push({from: "Воронежская обл", to: "Брянская обл", percentOfReturns: 1.0});
        arr.push({from: "Воронежская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Воронежская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Воронежская обл", to: "Архангельская обл", percentOfReturns: 0.6210495687});
        arr.push({from: "Воронежская обл", to: "Астраханская обл", percentOfReturns: 0.7423655211});
        arr.push({from: "Воронежская обл", to: "Вологодская обл", percentOfReturns: 0.5978681887});
        arr.push({from: "Воронежская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Воронежская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Воронежская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Воронежская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Воронежская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 0.84});
        arr.push({from: "Воронежская обл", to: "Калининградская обл", percentOfReturns: 0.4830235003});
        arr.push({from: "Воронежская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Воронежская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 0.82});
        arr.push({from: "Воронежская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Воронежская обл", to: "Кировская обл", percentOfReturns: 0.6597362407});
        arr.push({from: "Воронежская обл", to: "Костромская обл", percentOfReturns: 1.0});
        arr.push({from: "Воронежская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Воронежская обл", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Воронежская обл", to: "Курская обл", percentOfReturns: 1.0});
        arr.push({from: "Воронежская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Воронежская обл", to: "Мурманская обл", percentOfReturns: 0.6576788681});
        arr.push({from: "Воронежская обл", to: "Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Воронежская обл", to: "Нижегородская обл", percentOfReturns: 0.6953280758});
        arr.push({from: "Воронежская обл", to: "Новгородская обл", percentOfReturns: 0.8212070195});
        arr.push({from: "Воронежская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Воронежская обл", to: "Оренбургская обл", percentOfReturns: 0.6482695668});
        arr.push({from: "Воронежская обл", to: "Орловская обл", percentOfReturns: 1.0});
        arr.push({from: "Воронежская обл", to: "Пензенская обл", percentOfReturns: 0.6759425671});
        arr.push({from: "Воронежская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Воронежская обл", to: "Псковская обл", percentOfReturns: 0.5195501327});
        arr.push({from: "Воронежская обл", to: "Республика Адыгея", percentOfReturns: 0.7944580255});
        arr.push({from: "Воронежская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Воронежская обл", to: "Республика Башкортостан", percentOfReturns: 0.7348977438});
        arr.push({from: "Воронежская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Воронежская обл", to: "Республика Дагестан", percentOfReturns: 0.77});
        arr.push({from: "Воронежская обл", to: "Республика Ингушетия", percentOfReturns: 0.77});
        arr.push({from: "Воронежская обл", to: "Республика Калмыкия", percentOfReturns: 0.86});
        arr.push({from: "Воронежская обл", to: "Республика Карелия", percentOfReturns: 0.6916802688});
        arr.push({from: "Воронежская обл", to: "Республика Коми", percentOfReturns: 0.556420352});
        arr.push({from: "Воронежская обл", to: "Республика Крым", percentOfReturns: 0.4669283633});
        arr.push({from: "Воронежская обл", to: "Республика Марий Эл", percentOfReturns: 0.5664241478});
        arr.push({from: "Воронежская обл", to: "Республика Мордовия", percentOfReturns: 0.6367239818});
        arr.push({from: "Воронежская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Воронежская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 0.6641109995});
        arr.push({from: "Воронежская обл", to: "Республика Татарстан", percentOfReturns: 0.599926894});
        arr.push({from: "Воронежская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Воронежская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Воронежская обл", to: "Рязанская обл", percentOfReturns: 1.0});
        arr.push({from: "Воронежская обл", to: "Саратовская обл", percentOfReturns: 0.7249067544});
        arr.push({from: "Воронежская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Воронежская обл", to: "Севастополь", percentOfReturns: 0.72});
        arr.push({from: "Воронежская обл", to: "Смоленская обл", percentOfReturns: 1.0});
        arr.push({from: "Воронежская обл", to: "Тамбовская обл", percentOfReturns: 1.0});
        arr.push({from: "Воронежская обл", to: "Тверская обл", percentOfReturns: 1.0});
        arr.push({from: "Воронежская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Воронежская обл", to: "Удмуртская Республика", percentOfReturns: 0.5097286307});
        arr.push({from: "Воронежская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Воронежская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Воронежская обл", to: "Чеченская Республика", percentOfReturns: 0.77});
        arr.push({from: "Воронежская обл", to: "Чувашская Республика ", percentOfReturns: 0.5500897862});
        arr.push({from: "Воронежская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Воронежская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Ивановская обл", to: "г. Москва", percentOfReturns: 1.875025373});
        arr.push({from: "Ивановская обл", to: "Ленинградская обл", percentOfReturns: 0.8836815464});
        arr.push({from: "Ивановская обл", to: "Московская обл", percentOfReturns: 1.0});
        arr.push({from: "Ивановская обл", to: "Санкт-Петербург", percentOfReturns: 0.92});
        arr.push({from: "Ивановская обл", to: "Белгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Ивановская обл", to: "Владимирская обл", percentOfReturns: 1.0});
        arr.push({from: "Ивановская обл", to: "Волгоградская обл", percentOfReturns: 0.7241927852});
        arr.push({from: "Ивановская обл", to: "Воронежская обл", percentOfReturns: 1.0});
        arr.push({from: "Ивановская обл", to: "Ивановская обл", percentOfReturns: 1.0});
        arr.push({from: "Ивановская обл", to: "Калужская обл", percentOfReturns: 1.0});
        arr.push({from: "Ивановская обл", to: "Краснодарский край", percentOfReturns: 0.6308422513});
        arr.push({from: "Ивановская обл", to: "Липецкая обл", percentOfReturns: 1.0});
        arr.push({from: "Ивановская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Ивановская обл", to: "Пермский край", percentOfReturns: 0.7386976871});
        arr.push({from: "Ивановская обл", to: "Ростовская обл", percentOfReturns: 0.7460847446});
        arr.push({from: "Ивановская обл", to: "Самарская обл", percentOfReturns: 0.682038571});
        arr.push({from: "Ивановская обл", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Ивановская обл", to: "Ставропольский край", percentOfReturns: 0.780143135});
        arr.push({from: "Ивановская обл", to: "Тульская обл", percentOfReturns: 1.0});
        arr.push({from: "Ивановская обл", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Ивановская обл", to: "Ярославская обл", percentOfReturns: 1.0});
        arr.push({from: "Ивановская обл", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Ивановская обл", to: "Ульяновская обл", percentOfReturns: 0.6202341737});
        arr.push({from: "Ивановская обл", to: "Брянская обл", percentOfReturns: 1.0});
        arr.push({from: "Ивановская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Ивановская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Ивановская обл", to: "Архангельская обл", percentOfReturns: 0.6210495687});
        arr.push({from: "Ивановская обл", to: "Астраханская обл", percentOfReturns: 0.7423655211});
        arr.push({from: "Ивановская обл", to: "Вологодская обл", percentOfReturns: 0.5978681887});
        arr.push({from: "Ивановская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Ивановская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Ивановская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Ивановская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Ивановская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 0.84});
        arr.push({from: "Ивановская обл", to: "Калининградская обл", percentOfReturns: 0.4830235003});
        arr.push({from: "Ивановская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Ивановская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 0.82});
        arr.push({from: "Ивановская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Ивановская обл", to: "Кировская обл", percentOfReturns: 0.6597362407});
        arr.push({from: "Ивановская обл", to: "Костромская обл", percentOfReturns: 1.0});
        arr.push({from: "Ивановская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Ивановская обл", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Ивановская обл", to: "Курская обл", percentOfReturns: 1.0});
        arr.push({from: "Ивановская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Ивановская обл", to: "Мурманская обл", percentOfReturns: 0.6576788681});
        arr.push({from: "Ивановская обл", to: "Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Ивановская обл", to: "Нижегородская обл", percentOfReturns: 0.6953280758});
        arr.push({from: "Ивановская обл", to: "Новгородская обл", percentOfReturns: 0.8212070195});
        arr.push({from: "Ивановская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Ивановская обл", to: "Оренбургская обл", percentOfReturns: 0.6482695668});
        arr.push({from: "Ивановская обл", to: "Орловская обл", percentOfReturns: 1.0});
        arr.push({from: "Ивановская обл", to: "Пензенская обл", percentOfReturns: 0.6759425671});
        arr.push({from: "Ивановская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Ивановская обл", to: "Псковская обл", percentOfReturns: 0.5195501327});
        arr.push({from: "Ивановская обл", to: "Республика Адыгея", percentOfReturns: 0.7944580255});
        arr.push({from: "Ивановская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Ивановская обл", to: "Республика Башкортостан", percentOfReturns: 0.7348977438});
        arr.push({from: "Ивановская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Ивановская обл", to: "Республика Дагестан", percentOfReturns: 0.77});
        arr.push({from: "Ивановская обл", to: "Республика Ингушетия", percentOfReturns: 0.77});
        arr.push({from: "Ивановская обл", to: "Республика Калмыкия", percentOfReturns: 0.86});
        arr.push({from: "Ивановская обл", to: "Республика Карелия", percentOfReturns: 0.6916802688});
        arr.push({from: "Ивановская обл", to: "Республика Коми", percentOfReturns: 0.556420352});
        arr.push({from: "Ивановская обл", to: "Республика Крым", percentOfReturns: 0.4669283633});
        arr.push({from: "Ивановская обл", to: "Республика Марий Эл", percentOfReturns: 0.5664241478});
        arr.push({from: "Ивановская обл", to: "Республика Мордовия", percentOfReturns: 0.6367239818});
        arr.push({from: "Ивановская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Ивановская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 0.6641109995});
        arr.push({from: "Ивановская обл", to: "Республика Татарстан", percentOfReturns: 0.599926894});
        arr.push({from: "Ивановская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Ивановская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Ивановская обл", to: "Рязанская обл", percentOfReturns: 1.0});
        arr.push({from: "Ивановская обл", to: "Саратовская обл", percentOfReturns: 0.7249067544});
        arr.push({from: "Ивановская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Ивановская обл", to: "Севастополь", percentOfReturns: 0.72});
        arr.push({from: "Ивановская обл", to: "Смоленская обл", percentOfReturns: 1.0});
        arr.push({from: "Ивановская обл", to: "Тамбовская обл", percentOfReturns: 1.0});
        arr.push({from: "Ивановская обл", to: "Тверская обл", percentOfReturns: 1.0});
        arr.push({from: "Ивановская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Ивановская обл", to: "Удмуртская Республика", percentOfReturns: 0.5097286307});
        arr.push({from: "Ивановская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Ивановская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Ивановская обл", to: "Чеченская Республика", percentOfReturns: 0.77});
        arr.push({from: "Ивановская обл", to: "Чувашская Республика ", percentOfReturns: 0.5500897862});
        arr.push({from: "Ивановская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Ивановская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Калужская обл", to: "г. Москва", percentOfReturns: 2.127659574});
        arr.push({from: "Калужская обл", to: "Ленинградская обл", percentOfReturns: 0.8836815464});
        arr.push({from: "Калужская обл", to: "Московская обл", percentOfReturns: 1.0});
        arr.push({from: "Калужская обл", to: "Санкт-Петербург", percentOfReturns: 0.92});
        arr.push({from: "Калужская обл", to: "Белгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Калужская обл", to: "Владимирская обл", percentOfReturns: 1.0});
        arr.push({from: "Калужская обл", to: "Волгоградская обл", percentOfReturns: 0.7241927852});
        arr.push({from: "Калужская обл", to: "Воронежская обл", percentOfReturns: 1.0});
        arr.push({from: "Калужская обл", to: "Ивановская обл", percentOfReturns: 1.0});
        arr.push({from: "Калужская обл", to: "Калужская обл", percentOfReturns: 1.0});
        arr.push({from: "Калужская обл", to: "Краснодарский край", percentOfReturns: 0.6308422513});
        arr.push({from: "Калужская обл", to: "Липецкая обл", percentOfReturns: 1.0});
        arr.push({from: "Калужская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Калужская обл", to: "Пермский край", percentOfReturns: 0.7386976871});
        arr.push({from: "Калужская обл", to: "Ростовская обл", percentOfReturns: 0.7460847446});
        arr.push({from: "Калужская обл", to: "Самарская обл", percentOfReturns: 0.682038571});
        arr.push({from: "Калужская обл", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Калужская обл", to: "Ставропольский край", percentOfReturns: 0.780143135});
        arr.push({from: "Калужская обл", to: "Тульская обл", percentOfReturns: 1.0});
        arr.push({from: "Калужская обл", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Калужская обл", to: "Ярославская обл", percentOfReturns: 1.0});
        arr.push({from: "Калужская обл", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Калужская обл", to: "Ульяновская обл", percentOfReturns: 0.6202341737});
        arr.push({from: "Калужская обл", to: "Брянская обл", percentOfReturns: 1.0});
        arr.push({from: "Калужская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Калужская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Калужская обл", to: "Архангельская обл", percentOfReturns: 0.6210495687});
        arr.push({from: "Калужская обл", to: "Астраханская обл", percentOfReturns: 0.7423655211});
        arr.push({from: "Калужская обл", to: "Вологодская обл", percentOfReturns: 0.5978681887});
        arr.push({from: "Калужская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Калужская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Калужская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Калужская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Калужская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 0.84});
        arr.push({from: "Калужская обл", to: "Калининградская обл", percentOfReturns: 0.4830235003});
        arr.push({from: "Калужская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Калужская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 0.82});
        arr.push({from: "Калужская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Калужская обл", to: "Кировская обл", percentOfReturns: 0.6597362407});
        arr.push({from: "Калужская обл", to: "Костромская обл", percentOfReturns: 1.0});
        arr.push({from: "Калужская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Калужская обл", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Калужская обл", to: "Курская обл", percentOfReturns: 1.0});
        arr.push({from: "Калужская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Калужская обл", to: "Мурманская обл", percentOfReturns: 0.6576788681});
        arr.push({from: "Калужская обл", to: "Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Калужская обл", to: "Нижегородская обл", percentOfReturns: 0.6953280758});
        arr.push({from: "Калужская обл", to: "Новгородская обл", percentOfReturns: 0.8212070195});
        arr.push({from: "Калужская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Калужская обл", to: "Оренбургская обл", percentOfReturns: 0.6482695668});
        arr.push({from: "Калужская обл", to: "Орловская обл", percentOfReturns: 1.0});
        arr.push({from: "Калужская обл", to: "Пензенская обл", percentOfReturns: 0.6759425671});
        arr.push({from: "Калужская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Калужская обл", to: "Псковская обл", percentOfReturns: 0.5195501327});
        arr.push({from: "Калужская обл", to: "Республика Адыгея", percentOfReturns: 0.7944580255});
        arr.push({from: "Калужская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Калужская обл", to: "Республика Башкортостан", percentOfReturns: 0.7348977438});
        arr.push({from: "Калужская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Калужская обл", to: "Республика Дагестан", percentOfReturns: 0.77});
        arr.push({from: "Калужская обл", to: "Республика Ингушетия", percentOfReturns: 0.77});
        arr.push({from: "Калужская обл", to: "Республика Калмыкия", percentOfReturns: 0.86});
        arr.push({from: "Калужская обл", to: "Республика Карелия", percentOfReturns: 0.6916802688});
        arr.push({from: "Калужская обл", to: "Республика Коми", percentOfReturns: 0.556420352});
        arr.push({from: "Калужская обл", to: "Республика Крым", percentOfReturns: 0.4669283633});
        arr.push({from: "Калужская обл", to: "Республика Марий Эл", percentOfReturns: 0.5664241478});
        arr.push({from: "Калужская обл", to: "Республика Мордовия", percentOfReturns: 0.6367239818});
        arr.push({from: "Калужская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Калужская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 0.6641109995});
        arr.push({from: "Калужская обл", to: "Республика Татарстан", percentOfReturns: 0.599926894});
        arr.push({from: "Калужская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Калужская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Калужская обл", to: "Рязанская обл", percentOfReturns: 1.0});
        arr.push({from: "Калужская обл", to: "Саратовская обл", percentOfReturns: 0.7249067544});
        arr.push({from: "Калужская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Калужская обл", to: "Севастополь", percentOfReturns: 0.72});
        arr.push({from: "Калужская обл", to: "Смоленская обл", percentOfReturns: 1.0});
        arr.push({from: "Калужская обл", to: "Тамбовская обл", percentOfReturns: 1.0});
        arr.push({from: "Калужская обл", to: "Тверская обл", percentOfReturns: 1.0});
        arr.push({from: "Калужская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Калужская обл", to: "Удмуртская Республика", percentOfReturns: 0.5097286307});
        arr.push({from: "Калужская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Калужская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Калужская обл", to: "Чеченская Республика", percentOfReturns: 0.77});
        arr.push({from: "Калужская обл", to: "Чувашская Республика ", percentOfReturns: 0.5500897862});
        arr.push({from: "Калужская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Калужская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Краснодарский край", to: "г. Москва", percentOfReturns: 1.585182346});
        arr.push({from: "Краснодарский край", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Краснодарский край", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Краснодарский край", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Краснодарский край", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Краснодарский край", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Краснодарский край", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Краснодарский край", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Краснодарский край", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Краснодарский край", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Краснодарский край", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Краснодарский край", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Краснодарский край", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Краснодарский край", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Краснодарский край", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Краснодарский край", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Краснодарский край", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Краснодарский край", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Краснодарский край", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Краснодарский край", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Краснодарский край", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Краснодарский край", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Краснодарский край", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Краснодарский край", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Краснодарский край", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Краснодарский край", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Краснодарский край", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Краснодарский край", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Краснодарский край", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Краснодарский край", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Краснодарский край", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Краснодарский край", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Краснодарский край", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Краснодарский край", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Краснодарский край", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Краснодарский край", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Краснодарский край", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Краснодарский край", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Краснодарский край", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Краснодарский край", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Краснодарский край", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Краснодарский край", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Липецкая обл", to: "г. Москва", percentOfReturns: 1.39758999});
        arr.push({from: "Липецкая обл", to: "Ленинградская обл", percentOfReturns: 0.8836815464});
        arr.push({from: "Липецкая обл", to: "Московская обл", percentOfReturns: 1.0});
        arr.push({from: "Липецкая обл", to: "Санкт-Петербург", percentOfReturns: 0.92});
        arr.push({from: "Липецкая обл", to: "Белгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Липецкая обл", to: "Владимирская обл", percentOfReturns: 1.0});
        arr.push({from: "Липецкая обл", to: "Волгоградская обл", percentOfReturns: 0.7241927852});
        arr.push({from: "Липецкая обл", to: "Воронежская обл", percentOfReturns: 1.0});
        arr.push({from: "Липецкая обл", to: "Ивановская обл", percentOfReturns: 1.0});
        arr.push({from: "Липецкая обл", to: "Калужская обл", percentOfReturns: 1.0});
        arr.push({from: "Липецкая обл", to: "Краснодарский край", percentOfReturns: 0.6308422513});
        arr.push({from: "Липецкая обл", to: "Липецкая обл", percentOfReturns: 1.0});
        arr.push({from: "Липецкая обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Липецкая обл", to: "Пермский край", percentOfReturns: 0.7386976871});
        arr.push({from: "Липецкая обл", to: "Ростовская обл", percentOfReturns: 0.7460847446});
        arr.push({from: "Липецкая обл", to: "Самарская обл", percentOfReturns: 0.682038571});
        arr.push({from: "Липецкая обл", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Липецкая обл", to: "Ставропольский край", percentOfReturns: 0.780143135});
        arr.push({from: "Липецкая обл", to: "Тульская обл", percentOfReturns: 1.0});
        arr.push({from: "Липецкая обл", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Липецкая обл", to: "Ярославская обл", percentOfReturns: 1.0});
        arr.push({from: "Липецкая обл", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Липецкая обл", to: "Ульяновская обл", percentOfReturns: 0.6202341737});
        arr.push({from: "Липецкая обл", to: "Брянская обл", percentOfReturns: 1.0});
        arr.push({from: "Липецкая обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Липецкая обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Липецкая обл", to: "Архангельская обл", percentOfReturns: 0.6210495687});
        arr.push({from: "Липецкая обл", to: "Астраханская обл", percentOfReturns: 0.7423655211});
        arr.push({from: "Липецкая обл", to: "Вологодская обл", percentOfReturns: 0.5978681887});
        arr.push({from: "Липецкая обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Липецкая обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Липецкая обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Липецкая обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Липецкая обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 0.84});
        arr.push({from: "Липецкая обл", to: "Калининградская обл", percentOfReturns: 0.4830235003});
        arr.push({from: "Липецкая обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Липецкая обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 0.82});
        arr.push({from: "Липецкая обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Липецкая обл", to: "Кировская обл", percentOfReturns: 0.6597362407});
        arr.push({from: "Липецкая обл", to: "Костромская обл", percentOfReturns: 1.0});
        arr.push({from: "Липецкая обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Липецкая обл", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Липецкая обл", to: "Курская обл", percentOfReturns: 1.0});
        arr.push({from: "Липецкая обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Липецкая обл", to: "Мурманская обл", percentOfReturns: 0.6576788681});
        arr.push({from: "Липецкая обл", to: "Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Липецкая обл", to: "Нижегородская обл", percentOfReturns: 0.6953280758});
        arr.push({from: "Липецкая обл", to: "Новгородская обл", percentOfReturns: 0.8212070195});
        arr.push({from: "Липецкая обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Липецкая обл", to: "Оренбургская обл", percentOfReturns: 0.6482695668});
        arr.push({from: "Липецкая обл", to: "Орловская обл", percentOfReturns: 1.0});
        arr.push({from: "Липецкая обл", to: "Пензенская обл", percentOfReturns: 0.6759425671});
        arr.push({from: "Липецкая обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Липецкая обл", to: "Псковская обл", percentOfReturns: 0.5195501327});
        arr.push({from: "Липецкая обл", to: "Республика Адыгея", percentOfReturns: 0.7944580255});
        arr.push({from: "Липецкая обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Липецкая обл", to: "Республика Башкортостан", percentOfReturns: 0.7348977438});
        arr.push({from: "Липецкая обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Липецкая обл", to: "Республика Дагестан", percentOfReturns: 0.77});
        arr.push({from: "Липецкая обл", to: "Республика Ингушетия", percentOfReturns: 0.77});
        arr.push({from: "Липецкая обл", to: "Республика Калмыкия", percentOfReturns: 0.86});
        arr.push({from: "Липецкая обл", to: "Республика Карелия", percentOfReturns: 0.6916802688});
        arr.push({from: "Липецкая обл", to: "Республика Коми", percentOfReturns: 0.556420352});
        arr.push({from: "Липецкая обл", to: "Республика Крым", percentOfReturns: 0.4669283633});
        arr.push({from: "Липецкая обл", to: "Республика Марий Эл", percentOfReturns: 0.5664241478});
        arr.push({from: "Липецкая обл", to: "Республика Мордовия", percentOfReturns: 0.6367239818});
        arr.push({from: "Липецкая обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Липецкая обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 0.6641109995});
        arr.push({from: "Липецкая обл", to: "Республика Татарстан", percentOfReturns: 0.599926894});
        arr.push({from: "Липецкая обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Липецкая обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Липецкая обл", to: "Рязанская обл", percentOfReturns: 1.0});
        arr.push({from: "Липецкая обл", to: "Саратовская обл", percentOfReturns: 0.7249067544});
        arr.push({from: "Липецкая обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Липецкая обл", to: "Севастополь", percentOfReturns: 0.72});
        arr.push({from: "Липецкая обл", to: "Смоленская обл", percentOfReturns: 1.0});
        arr.push({from: "Липецкая обл", to: "Тамбовская обл", percentOfReturns: 1.0});
        arr.push({from: "Липецкая обл", to: "Тверская обл", percentOfReturns: 1.0});
        arr.push({from: "Липецкая обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Липецкая обл", to: "Удмуртская Республика", percentOfReturns: 0.5097286307});
        arr.push({from: "Липецкая обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Липецкая обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Липецкая обл", to: "Чеченская Республика", percentOfReturns: 0.77});
        arr.push({from: "Липецкая обл", to: "Чувашская Республика ", percentOfReturns: 0.5500897862});
        arr.push({from: "Липецкая обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Липецкая обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Новосибирская обл", to: "г. Москва", percentOfReturns: 1.285328603});
        arr.push({from: "Новосибирская обл", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Новосибирская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Новосибирская обл", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Новосибирская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Новосибирская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Новосибирская обл", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Новосибирская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Новосибирская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Новосибирская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Новосибирская обл", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Новосибирская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Новосибирская обл", to: "Новосибирская обл", percentOfReturns: 1.0});
        arr.push({from: "Новосибирская обл", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Новосибирская обл", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Новосибирская обл", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Новосибирская обл", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Новосибирская обл", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Новосибирская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Новосибирская обл", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Новосибирская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Новосибирская обл", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Новосибирская обл", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Новосибирская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Новосибирская обл", to: "Алтайский край", percentOfReturns: 1.0});
        arr.push({from: "Новосибирская обл", to: "Амурская обл", percentOfReturns: 1.449275362});
        arr.push({from: "Новосибирская обл", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Новосибирская обл", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Новосибирская обл", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Новосибирская обл", to: "Еврейская автономная обл", percentOfReturns: 1.470588235});
        arr.push({from: "Новосибирская обл", to: "Забайкальский край", percentOfReturns: 1.587301587});
        arr.push({from: "Новосибирская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 2.0});
        arr.push({from: "Новосибирская обл", to: "Иркутская обл", percentOfReturns: 1.0});
        arr.push({from: "Новосибирская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Новосибирская обл", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Новосибирская обл", to: "Камчатский край", percentOfReturns: 2.0});
        arr.push({from: "Новосибирская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Новосибирская обл", to: "Кемеровская обл", percentOfReturns: 1.0});
        arr.push({from: "Новосибирская обл", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Новосибирская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Новосибирская обл", to: "Красноярский край", percentOfReturns: 1.0});
        arr.push({from: "Новосибирская обл", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Новосибирская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Новосибирская обл", to: "Магаданская обл", percentOfReturns: 2.0});
        arr.push({from: "Новосибирская обл", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Новосибирская обл", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Новосибирская обл", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Новосибирская обл", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Новосибирская обл", to: "Омская обл", percentOfReturns: 1.0});
        arr.push({from: "Новосибирская обл", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Новосибирская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Новосибирская обл", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Новосибирская обл", to: "Приморский край", percentOfReturns: 1.470588235});
        arr.push({from: "Новосибирская обл", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Новосибирская обл", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Новосибирская обл", to: "Республика Алтай", percentOfReturns: 1.0});
        arr.push({from: "Новосибирская обл", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Новосибирская обл", to: "Республика Бурятия", percentOfReturns: 1.0});
        arr.push({from: "Новосибирская обл", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Новосибирская обл", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Новосибирская обл", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Новосибирская обл", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Новосибирская обл", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Новосибирская обл", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Новосибирская обл", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Новосибирская обл", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Новосибирская обл", to: "Республика Саха (Якутия)", percentOfReturns: 2.0});
        arr.push({from: "Новосибирская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Новосибирская обл", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Новосибирская обл", to: "Республика Тыва", percentOfReturns: 1.0});
        arr.push({from: "Новосибирская обл", to: "Республика Хакасия", percentOfReturns: 1.0});
        arr.push({from: "Новосибирская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Новосибирская обл", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Новосибирская обл", to: "Сахалинская обл", percentOfReturns: 2.0});
        arr.push({from: "Новосибирская обл", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Новосибирская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Новосибирская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Новосибирская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Новосибирская обл", to: "Томская обл", percentOfReturns: 1.0});
        arr.push({from: "Новосибирская обл", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Новосибирская обл", to: "Хабаровский край", percentOfReturns: 1.465160601});
        arr.push({from: "Новосибирская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Новосибирская обл", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Новосибирская обл", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Новосибирская обл", to: "Чукотский автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Новосибирская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Пермский край", to: "г. Москва", percentOfReturns: 1.353733763});
        arr.push({from: "Пермский край", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Пермский край", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Пермский край", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Пермский край", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Пермский край", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Пермский край", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Пермский край", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Пермский край", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Пермский край", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Пермский край", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Пермский край", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Пермский край", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Пермский край", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Пермский край", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Пермский край", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Пермский край", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Пермский край", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Пермский край", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Пермский край", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Пермский край", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Пермский край", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Пермский край", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Пермский край", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Пермский край", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Пермский край", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Пермский край", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Пермский край", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Пермский край", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Пермский край", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Пермский край", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Пермский край", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Пермский край", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Пермский край", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Пермский край", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Пермский край", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Пермский край", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Пермский край", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Пермский край", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Пермский край", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Пермский край", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Пермский край", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "г. Москва", percentOfReturns: 1.340330314});
        arr.push({from: "Ростовская обл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ростовская обл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Ростовская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ростовская обл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Ростовская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Ростовская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ростовская обл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Ростовская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Ростовская обл", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ростовская обл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Ростовская обл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Ростовская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Ростовская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Ростовская обл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Ростовская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Ростовская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Ростовская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Ростовская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Ростовская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Ростовская обл", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Ростовская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Ростовская обл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Ростовская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Ростовская обл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Ростовская обл", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Ростовская обл", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Ростовская обл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Ростовская обл", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Ростовская обл", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Ростовская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Ростовская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Ростовская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Ростовская обл", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Ростовская обл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Ростовская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Ростовская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ростовская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Ростовская обл", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Ростовская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Ростовская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Ростовская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "г. Москва", percentOfReturns: 1.466192738});
        arr.push({from: "Самарская обл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Самарская обл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Самарская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Самарская обл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Самарская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Самарская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Самарская обл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Самарская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Самарская обл", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Самарская обл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Самарская обл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Самарская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Самарская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Самарская обл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Самарская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Самарская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Самарская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Самарская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Самарская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Самарская обл", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Самарская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Самарская обл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Самарская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Самарская обл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Самарская обл", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Самарская обл", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Самарская обл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Самарская обл", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Самарская обл", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Самарская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Самарская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Самарская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Самарская обл", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Самарская обл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Самарская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Самарская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Самарская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Самарская обл", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Самарская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Самарская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Самарская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "г. Москва", percentOfReturns: 1.306925271});
        arr.push({from: "Свердловская обл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Свердловская обл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Свердловская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Свердловская обл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Свердловская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Свердловская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Свердловская обл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Свердловская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Свердловская обл", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Свердловская обл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Свердловская обл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Свердловская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Свердловская обл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Свердловская обл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Свердловская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Свердловская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Свердловская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Свердловская обл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Свердловская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Свердловская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Свердловская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Свердловская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Свердловская обл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Свердловская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Свердловская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Свердловская обл", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Свердловская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Свердловская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Свердловская обл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Свердловская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Свердловская обл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Свердловская обл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Свердловская обл", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Свердловская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Свердловская обл", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Свердловская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Свердловская обл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Свердловская обл", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Свердловская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Свердловская обл", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Свердловская обл", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Свердловская обл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Свердловская обл", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Свердловская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Свердловская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Свердловская обл", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Свердловская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Свердловская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Свердловская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Свердловская обл", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Свердловская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Свердловская обл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Свердловская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Свердловская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Свердловская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Свердловская обл", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Свердловская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Свердловская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Свердловская обл", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Свердловская обл", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Свердловская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Свердловская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "г. Москва", percentOfReturns: 1.281816061});
        arr.push({from: "Ставропольский край", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ставропольский край", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Ставропольский край", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ставропольский край", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Ставропольский край", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Ставропольский край", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ставропольский край", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Ставропольский край", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Ставропольский край", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Ставропольский край", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ставропольский край", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Ставропольский край", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Ставропольский край", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Ставропольский край", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Ставропольский край", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Ставропольский край", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Ставропольский край", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Ставропольский край", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Ставропольский край", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Ставропольский край", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Ставропольский край", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Ставропольский край", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Ставропольский край", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Ставропольский край", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Ставропольский край", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Ставропольский край", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Ставропольский край", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Ставропольский край", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Ставропольский край", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Ставропольский край", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Ставропольский край", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Ставропольский край", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Ставропольский край", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Ставропольский край", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Ставропольский край", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Ставропольский край", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Ставропольский край", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Ставропольский край", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Ставропольский край", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Ставропольский край", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ставропольский край", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Ставропольский край", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Ставропольский край", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Ставропольский край", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Ставропольский край", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Ставропольский край", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Тульская обл", to: "г. Москва", percentOfReturns: 2.127659574});
        arr.push({from: "Тульская обл", to: "Ленинградская обл", percentOfReturns: 0.8836815464});
        arr.push({from: "Тульская обл", to: "Московская обл", percentOfReturns: 1.0});
        arr.push({from: "Тульская обл", to: "Санкт-Петербург", percentOfReturns: 0.92});
        arr.push({from: "Тульская обл", to: "Белгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Тульская обл", to: "Владимирская обл", percentOfReturns: 1.0});
        arr.push({from: "Тульская обл", to: "Волгоградская обл", percentOfReturns: 0.7241927852});
        arr.push({from: "Тульская обл", to: "Воронежская обл", percentOfReturns: 1.0});
        arr.push({from: "Тульская обл", to: "Ивановская обл", percentOfReturns: 1.0});
        arr.push({from: "Тульская обл", to: "Калужская обл", percentOfReturns: 1.0});
        arr.push({from: "Тульская обл", to: "Краснодарский край", percentOfReturns: 0.6308422513});
        arr.push({from: "Тульская обл", to: "Липецкая обл", percentOfReturns: 1.0});
        arr.push({from: "Тульская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Тульская обл", to: "Пермский край", percentOfReturns: 0.7386976871});
        arr.push({from: "Тульская обл", to: "Ростовская обл", percentOfReturns: 0.7460847446});
        arr.push({from: "Тульская обл", to: "Самарская обл", percentOfReturns: 0.682038571});
        arr.push({from: "Тульская обл", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Тульская обл", to: "Ставропольский край", percentOfReturns: 0.780143135});
        arr.push({from: "Тульская обл", to: "Тульская обл", percentOfReturns: 1.0});
        arr.push({from: "Тульская обл", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Тульская обл", to: "Ярославская обл", percentOfReturns: 1.0});
        arr.push({from: "Тульская обл", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Тульская обл", to: "Ульяновская обл", percentOfReturns: 0.6202341737});
        arr.push({from: "Тульская обл", to: "Брянская обл", percentOfReturns: 1.0});
        arr.push({from: "Тульская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Тульская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Тульская обл", to: "Архангельская обл", percentOfReturns: 0.6210495687});
        arr.push({from: "Тульская обл", to: "Астраханская обл", percentOfReturns: 0.7423655211});
        arr.push({from: "Тульская обл", to: "Вологодская обл", percentOfReturns: 0.5978681887});
        arr.push({from: "Тульская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Тульская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Тульская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Тульская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Тульская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 0.84});
        arr.push({from: "Тульская обл", to: "Калининградская обл", percentOfReturns: 0.4830235003});
        arr.push({from: "Тульская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Тульская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 0.82});
        arr.push({from: "Тульская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Тульская обл", to: "Кировская обл", percentOfReturns: 0.6597362407});
        arr.push({from: "Тульская обл", to: "Костромская обл", percentOfReturns: 1.0});
        arr.push({from: "Тульская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Тульская обл", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Тульская обл", to: "Курская обл", percentOfReturns: 1.0});
        arr.push({from: "Тульская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Тульская обл", to: "Мурманская обл", percentOfReturns: 0.6576788681});
        arr.push({from: "Тульская обл", to: "Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Тульская обл", to: "Нижегородская обл", percentOfReturns: 0.6953280758});
        arr.push({from: "Тульская обл", to: "Новгородская обл", percentOfReturns: 0.8212070195});
        arr.push({from: "Тульская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Тульская обл", to: "Оренбургская обл", percentOfReturns: 0.6482695668});
        arr.push({from: "Тульская обл", to: "Орловская обл", percentOfReturns: 1.0});
        arr.push({from: "Тульская обл", to: "Пензенская обл", percentOfReturns: 0.6759425671});
        arr.push({from: "Тульская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Тульская обл", to: "Псковская обл", percentOfReturns: 0.5195501327});
        arr.push({from: "Тульская обл", to: "Республика Адыгея", percentOfReturns: 0.7944580255});
        arr.push({from: "Тульская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Тульская обл", to: "Республика Башкортостан", percentOfReturns: 0.7348977438});
        arr.push({from: "Тульская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Тульская обл", to: "Республика Дагестан", percentOfReturns: 0.77});
        arr.push({from: "Тульская обл", to: "Республика Ингушетия", percentOfReturns: 0.77});
        arr.push({from: "Тульская обл", to: "Республика Калмыкия", percentOfReturns: 0.86});
        arr.push({from: "Тульская обл", to: "Республика Карелия", percentOfReturns: 0.6916802688});
        arr.push({from: "Тульская обл", to: "Республика Коми", percentOfReturns: 0.556420352});
        arr.push({from: "Тульская обл", to: "Республика Крым", percentOfReturns: 0.4669283633});
        arr.push({from: "Тульская обл", to: "Республика Марий Эл", percentOfReturns: 0.5664241478});
        arr.push({from: "Тульская обл", to: "Республика Мордовия", percentOfReturns: 0.6367239818});
        arr.push({from: "Тульская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Тульская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 0.6641109995});
        arr.push({from: "Тульская обл", to: "Республика Татарстан", percentOfReturns: 0.599926894});
        arr.push({from: "Тульская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Тульская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Тульская обл", to: "Рязанская обл", percentOfReturns: 1.0});
        arr.push({from: "Тульская обл", to: "Саратовская обл", percentOfReturns: 0.7249067544});
        arr.push({from: "Тульская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Тульская обл", to: "Севастополь", percentOfReturns: 0.72});
        arr.push({from: "Тульская обл", to: "Смоленская обл", percentOfReturns: 1.0});
        arr.push({from: "Тульская обл", to: "Тамбовская обл", percentOfReturns: 1.0});
        arr.push({from: "Тульская обл", to: "Тверская обл", percentOfReturns: 1.0});
        arr.push({from: "Тульская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Тульская обл", to: "Удмуртская Республика", percentOfReturns: 0.5097286307});
        arr.push({from: "Тульская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Тульская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Тульская обл", to: "Чеченская Республика", percentOfReturns: 0.77});
        arr.push({from: "Тульская обл", to: "Чувашская Республика ", percentOfReturns: 0.5500897862});
        arr.push({from: "Тульская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Тульская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Челябинская обл", to: "г. Москва", percentOfReturns: 1.372687711});
        arr.push({from: "Челябинская обл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Челябинская обл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Челябинская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Челябинская обл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Челябинская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Челябинская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Челябинская обл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Челябинская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Челябинская обл", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Челябинская обл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Челябинская обл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Челябинская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Челябинская обл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Челябинская обл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Челябинская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Челябинская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Челябинская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Челябинская обл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Челябинская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Челябинская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Челябинская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Челябинская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Челябинская обл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Челябинская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Челябинская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Челябинская обл", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Челябинская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Челябинская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Челябинская обл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Челябинская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Челябинская обл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Челябинская обл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Челябинская обл", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Челябинская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Челябинская обл", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Челябинская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Челябинская обл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Челябинская обл", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Челябинская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Челябинская обл", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Челябинская обл", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Челябинская обл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Челябинская обл", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Челябинская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Челябинская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Челябинская обл", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Челябинская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Челябинская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Челябинская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Челябинская обл", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Челябинская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Челябинская обл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Челябинская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Челябинская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Челябинская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Челябинская обл", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Челябинская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Челябинская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Челябинская обл", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Челябинская обл", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Челябинская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Челябинская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Ярославская обл", to: "г. Москва", percentOfReturns: 2.528895376});
        arr.push({from: "Ярославская обл", to: "Ленинградская обл", percentOfReturns: 0.8836815464});
        arr.push({from: "Ярославская обл", to: "Московская обл", percentOfReturns: 1.0});
        arr.push({from: "Ярославская обл", to: "Санкт-Петербург", percentOfReturns: 0.92});
        arr.push({from: "Ярославская обл", to: "Белгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Ярославская обл", to: "Владимирская обл", percentOfReturns: 1.0});
        arr.push({from: "Ярославская обл", to: "Волгоградская обл", percentOfReturns: 0.7241927852});
        arr.push({from: "Ярославская обл", to: "Воронежская обл", percentOfReturns: 1.0});
        arr.push({from: "Ярославская обл", to: "Ивановская обл", percentOfReturns: 1.0});
        arr.push({from: "Ярославская обл", to: "Калужская обл", percentOfReturns: 1.0});
        arr.push({from: "Ярославская обл", to: "Краснодарский край", percentOfReturns: 0.6308422513});
        arr.push({from: "Ярославская обл", to: "Липецкая обл", percentOfReturns: 1.0});
        arr.push({from: "Ярославская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Ярославская обл", to: "Пермский край", percentOfReturns: 0.7386976871});
        arr.push({from: "Ярославская обл", to: "Ростовская обл", percentOfReturns: 0.7460847446});
        arr.push({from: "Ярославская обл", to: "Самарская обл", percentOfReturns: 0.682038571});
        arr.push({from: "Ярославская обл", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Ярославская обл", to: "Ставропольский край", percentOfReturns: 0.780143135});
        arr.push({from: "Ярославская обл", to: "Тульская обл", percentOfReturns: 1.0});
        arr.push({from: "Ярославская обл", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Ярославская обл", to: "Ярославская обл", percentOfReturns: 1.0});
        arr.push({from: "Ярославская обл", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Ярославская обл", to: "Ульяновская обл", percentOfReturns: 0.6202341737});
        arr.push({from: "Ярославская обл", to: "Брянская обл", percentOfReturns: 1.0});
        arr.push({from: "Ярославская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Ярославская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Ярославская обл", to: "Архангельская обл", percentOfReturns: 0.6210495687});
        arr.push({from: "Ярославская обл", to: "Астраханская обл", percentOfReturns: 0.7423655211});
        arr.push({from: "Ярославская обл", to: "Вологодская обл", percentOfReturns: 0.5978681887});
        arr.push({from: "Ярославская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Ярославская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Ярославская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Ярославская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Ярославская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 0.84});
        arr.push({from: "Ярославская обл", to: "Калининградская обл", percentOfReturns: 0.4830235003});
        arr.push({from: "Ярославская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Ярославская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 0.82});
        arr.push({from: "Ярославская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Ярославская обл", to: "Кировская обл", percentOfReturns: 0.6597362407});
        arr.push({from: "Ярославская обл", to: "Костромская обл", percentOfReturns: 1.0});
        arr.push({from: "Ярославская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Ярославская обл", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Ярославская обл", to: "Курская обл", percentOfReturns: 1.0});
        arr.push({from: "Ярославская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Ярославская обл", to: "Мурманская обл", percentOfReturns: 0.6576788681});
        arr.push({from: "Ярославская обл", to: "Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Ярославская обл", to: "Нижегородская обл", percentOfReturns: 0.6953280758});
        arr.push({from: "Ярославская обл", to: "Новгородская обл", percentOfReturns: 0.8212070195});
        arr.push({from: "Ярославская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Ярославская обл", to: "Оренбургская обл", percentOfReturns: 0.6482695668});
        arr.push({from: "Ярославская обл", to: "Орловская обл", percentOfReturns: 1.0});
        arr.push({from: "Ярославская обл", to: "Пензенская обл", percentOfReturns: 0.6759425671});
        arr.push({from: "Ярославская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Ярославская обл", to: "Псковская обл", percentOfReturns: 0.5195501327});
        arr.push({from: "Ярославская обл", to: "Республика Адыгея", percentOfReturns: 0.7944580255});
        arr.push({from: "Ярославская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Ярославская обл", to: "Республика Башкортостан", percentOfReturns: 0.7348977438});
        arr.push({from: "Ярославская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Ярославская обл", to: "Республика Дагестан", percentOfReturns: 0.77});
        arr.push({from: "Ярославская обл", to: "Республика Ингушетия", percentOfReturns: 0.77});
        arr.push({from: "Ярославская обл", to: "Республика Калмыкия", percentOfReturns: 0.86});
        arr.push({from: "Ярославская обл", to: "Республика Карелия", percentOfReturns: 0.6916802688});
        arr.push({from: "Ярославская обл", to: "Республика Коми", percentOfReturns: 0.556420352});
        arr.push({from: "Ярославская обл", to: "Республика Крым", percentOfReturns: 0.4669283633});
        arr.push({from: "Ярославская обл", to: "Республика Марий Эл", percentOfReturns: 0.5664241478});
        arr.push({from: "Ярославская обл", to: "Республика Мордовия", percentOfReturns: 0.6367239818});
        arr.push({from: "Ярославская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Ярославская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 0.6641109995});
        arr.push({from: "Ярославская обл", to: "Республика Татарстан", percentOfReturns: 0.599926894});
        arr.push({from: "Ярославская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Ярославская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Ярославская обл", to: "Рязанская обл", percentOfReturns: 1.0});
        arr.push({from: "Ярославская обл", to: "Саратовская обл", percentOfReturns: 0.7249067544});
        arr.push({from: "Ярославская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Ярославская обл", to: "Севастополь", percentOfReturns: 0.72});
        arr.push({from: "Ярославская обл", to: "Смоленская обл", percentOfReturns: 1.0});
        arr.push({from: "Ярославская обл", to: "Тамбовская обл", percentOfReturns: 1.0});
        arr.push({from: "Ярославская обл", to: "Тверская обл", percentOfReturns: 1.0});
        arr.push({from: "Ярославская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Ярославская обл", to: "Удмуртская Республика", percentOfReturns: 0.5097286307});
        arr.push({from: "Ярославская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Ярославская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Ярославская обл", to: "Чеченская Республика", percentOfReturns: 0.77});
        arr.push({from: "Ярославская обл", to: "Чувашская Республика ", percentOfReturns: 0.5500897862});
        arr.push({from: "Ярославская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Ярославская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Тюменская обл", to: "г. Москва", percentOfReturns: 1.381866436});
        arr.push({from: "Тюменская обл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Тюменская обл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Тюменская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Тюменская обл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Тюменская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Тюменская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Тюменская обл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Тюменская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Тюменская обл", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Тюменская обл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Тюменская обл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Тюменская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Тюменская обл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Тюменская обл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Тюменская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Тюменская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Тюменская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Тюменская обл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Тюменская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Тюменская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Тюменская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Тюменская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Тюменская обл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Тюменская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Тюменская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Тюменская обл", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Тюменская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Тюменская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Тюменская обл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Тюменская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Тюменская обл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Тюменская обл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Тюменская обл", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Тюменская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Тюменская обл", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Тюменская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Тюменская обл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Тюменская обл", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Тюменская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Тюменская обл", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Тюменская обл", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Тюменская обл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Тюменская обл", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Тюменская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Тюменская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Тюменская обл", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Тюменская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Тюменская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Тюменская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Тюменская обл", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Тюменская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Тюменская обл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Тюменская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Тюменская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Тюменская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Тюменская обл", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Тюменская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Тюменская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Тюменская обл", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Тюменская обл", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Тюменская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Тюменская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "г. Москва", percentOfReturns: 1.612294263});
        arr.push({from: "Ульяновская обл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ульяновская обл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Ульяновская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ульяновская обл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Ульяновская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Ульяновская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ульяновская обл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Ульяновская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Ульяновская обл", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ульяновская обл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Ульяновская обл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Ульяновская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Ульяновская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Ульяновская обл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Ульяновская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Ульяновская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Ульяновская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Ульяновская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Ульяновская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Ульяновская обл", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Ульяновская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Ульяновская обл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Ульяновская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Ульяновская обл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Ульяновская обл", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Ульяновская обл", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Ульяновская обл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Ульяновская обл", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Ульяновская обл", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Ульяновская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Ульяновская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Ульяновская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Ульяновская обл", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Ульяновская обл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Ульяновская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Ульяновская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ульяновская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Ульяновская обл", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Ульяновская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Ульяновская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Ульяновская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Брянская обл", to: "г. Москва", percentOfReturns: 1.359187643});
        arr.push({from: "Брянская обл", to: "Ленинградская обл", percentOfReturns: 0.8836815464});
        arr.push({from: "Брянская обл", to: "Московская обл", percentOfReturns: 1.0});
        arr.push({from: "Брянская обл", to: "Санкт-Петербург", percentOfReturns: 0.92});
        arr.push({from: "Брянская обл", to: "Белгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Брянская обл", to: "Владимирская обл", percentOfReturns: 1.0});
        arr.push({from: "Брянская обл", to: "Волгоградская обл", percentOfReturns: 0.7241927852});
        arr.push({from: "Брянская обл", to: "Воронежская обл", percentOfReturns: 1.0});
        arr.push({from: "Брянская обл", to: "Ивановская обл", percentOfReturns: 1.0});
        arr.push({from: "Брянская обл", to: "Калужская обл", percentOfReturns: 1.0});
        arr.push({from: "Брянская обл", to: "Краснодарский край", percentOfReturns: 0.6308422513});
        arr.push({from: "Брянская обл", to: "Липецкая обл", percentOfReturns: 1.0});
        arr.push({from: "Брянская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Брянская обл", to: "Пермский край", percentOfReturns: 0.7386976871});
        arr.push({from: "Брянская обл", to: "Ростовская обл", percentOfReturns: 0.7460847446});
        arr.push({from: "Брянская обл", to: "Самарская обл", percentOfReturns: 0.682038571});
        arr.push({from: "Брянская обл", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Брянская обл", to: "Ставропольский край", percentOfReturns: 0.780143135});
        arr.push({from: "Брянская обл", to: "Тульская обл", percentOfReturns: 1.0});
        arr.push({from: "Брянская обл", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Брянская обл", to: "Ярославская обл", percentOfReturns: 1.0});
        arr.push({from: "Брянская обл", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Брянская обл", to: "Ульяновская обл", percentOfReturns: 0.6202341737});
        arr.push({from: "Брянская обл", to: "Брянская обл", percentOfReturns: 1.0});
        arr.push({from: "Брянская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Брянская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Брянская обл", to: "Архангельская обл", percentOfReturns: 0.6210495687});
        arr.push({from: "Брянская обл", to: "Астраханская обл", percentOfReturns: 0.7423655211});
        arr.push({from: "Брянская обл", to: "Вологодская обл", percentOfReturns: 0.5978681887});
        arr.push({from: "Брянская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Брянская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Брянская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Брянская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Брянская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 0.84});
        arr.push({from: "Брянская обл", to: "Калининградская обл", percentOfReturns: 0.4830235003});
        arr.push({from: "Брянская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Брянская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 0.82});
        arr.push({from: "Брянская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Брянская обл", to: "Кировская обл", percentOfReturns: 0.6597362407});
        arr.push({from: "Брянская обл", to: "Костромская обл", percentOfReturns: 1.0});
        arr.push({from: "Брянская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Брянская обл", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Брянская обл", to: "Курская обл", percentOfReturns: 1.0});
        arr.push({from: "Брянская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Брянская обл", to: "Мурманская обл", percentOfReturns: 0.6576788681});
        arr.push({from: "Брянская обл", to: "Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Брянская обл", to: "Нижегородская обл", percentOfReturns: 0.6953280758});
        arr.push({from: "Брянская обл", to: "Новгородская обл", percentOfReturns: 0.8212070195});
        arr.push({from: "Брянская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Брянская обл", to: "Оренбургская обл", percentOfReturns: 0.6482695668});
        arr.push({from: "Брянская обл", to: "Орловская обл", percentOfReturns: 1.0});
        arr.push({from: "Брянская обл", to: "Пензенская обл", percentOfReturns: 0.6759425671});
        arr.push({from: "Брянская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Брянская обл", to: "Псковская обл", percentOfReturns: 0.5195501327});
        arr.push({from: "Брянская обл", to: "Республика Адыгея", percentOfReturns: 0.7944580255});
        arr.push({from: "Брянская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Брянская обл", to: "Республика Башкортостан", percentOfReturns: 0.7348977438});
        arr.push({from: "Брянская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Брянская обл", to: "Республика Дагестан", percentOfReturns: 0.77});
        arr.push({from: "Брянская обл", to: "Республика Ингушетия", percentOfReturns: 0.77});
        arr.push({from: "Брянская обл", to: "Республика Калмыкия", percentOfReturns: 0.86});
        arr.push({from: "Брянская обл", to: "Республика Карелия", percentOfReturns: 0.6916802688});
        arr.push({from: "Брянская обл", to: "Республика Коми", percentOfReturns: 0.556420352});
        arr.push({from: "Брянская обл", to: "Республика Крым", percentOfReturns: 0.4669283633});
        arr.push({from: "Брянская обл", to: "Республика Марий Эл", percentOfReturns: 0.5664241478});
        arr.push({from: "Брянская обл", to: "Республика Мордовия", percentOfReturns: 0.6367239818});
        arr.push({from: "Брянская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Брянская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 0.6641109995});
        arr.push({from: "Брянская обл", to: "Республика Татарстан", percentOfReturns: 0.599926894});
        arr.push({from: "Брянская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Брянская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Брянская обл", to: "Рязанская обл", percentOfReturns: 1.0});
        arr.push({from: "Брянская обл", to: "Саратовская обл", percentOfReturns: 0.7249067544});
        arr.push({from: "Брянская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Брянская обл", to: "Севастополь", percentOfReturns: 0.72});
        arr.push({from: "Брянская обл", to: "Смоленская обл", percentOfReturns: 1.0});
        arr.push({from: "Брянская обл", to: "Тамбовская обл", percentOfReturns: 1.0});
        arr.push({from: "Брянская обл", to: "Тверская обл", percentOfReturns: 1.0});
        arr.push({from: "Брянская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Брянская обл", to: "Удмуртская Республика", percentOfReturns: 0.5097286307});
        arr.push({from: "Брянская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Брянская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Брянская обл", to: "Чеченская Республика", percentOfReturns: 0.77});
        arr.push({from: "Брянская обл", to: "Чувашская Республика ", percentOfReturns: 0.5500897862});
        arr.push({from: "Брянская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Брянская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Алтайский край", to: "г. Москва", percentOfReturns: 1.260287158});
        arr.push({from: "Алтайский край", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Алтайский край", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Алтайский край", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Алтайский край", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Алтайский край", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Алтайский край", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Алтайский край", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Алтайский край", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Алтайский край", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Алтайский край", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Алтайский край", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Алтайский край", to: "Новосибирская обл", percentOfReturns: 1.0});
        arr.push({from: "Алтайский край", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Алтайский край", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Алтайский край", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Алтайский край", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Алтайский край", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Алтайский край", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Алтайский край", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Алтайский край", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Алтайский край", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Алтайский край", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Алтайский край", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Алтайский край", to: "Алтайский край", percentOfReturns: 1.0});
        arr.push({from: "Алтайский край", to: "Амурская обл", percentOfReturns: 1.449275362});
        arr.push({from: "Алтайский край", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Алтайский край", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Алтайский край", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Алтайский край", to: "Еврейская автономная обл", percentOfReturns: 1.470588235});
        arr.push({from: "Алтайский край", to: "Забайкальский край", percentOfReturns: 1.587301587});
        arr.push({from: "Алтайский край", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 2.0});
        arr.push({from: "Алтайский край", to: "Иркутская обл", percentOfReturns: 1.0});
        arr.push({from: "Алтайский край", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Алтайский край", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Алтайский край", to: "Камчатский край", percentOfReturns: 2.0});
        arr.push({from: "Алтайский край", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Алтайский край", to: "Кемеровская обл", percentOfReturns: 1.0});
        arr.push({from: "Алтайский край", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Алтайский край", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Алтайский край", to: "Красноярский край", percentOfReturns: 1.0});
        arr.push({from: "Алтайский край", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Алтайский край", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Алтайский край", to: "Магаданская обл", percentOfReturns: 2.0});
        arr.push({from: "Алтайский край", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Алтайский край", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Алтайский край", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Алтайский край", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Алтайский край", to: "Омская обл", percentOfReturns: 1.0});
        arr.push({from: "Алтайский край", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Алтайский край", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Алтайский край", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Алтайский край", to: "Приморский край", percentOfReturns: 1.470588235});
        arr.push({from: "Алтайский край", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Алтайский край", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Алтайский край", to: "Республика Алтай", percentOfReturns: 1.0});
        arr.push({from: "Алтайский край", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Алтайский край", to: "Республика Бурятия", percentOfReturns: 1.0});
        arr.push({from: "Алтайский край", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Алтайский край", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Алтайский край", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Алтайский край", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Алтайский край", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Алтайский край", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Алтайский край", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Алтайский край", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Алтайский край", to: "Республика Саха (Якутия)", percentOfReturns: 2.0});
        arr.push({from: "Алтайский край", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Алтайский край", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Алтайский край", to: "Республика Тыва", percentOfReturns: 1.0});
        arr.push({from: "Алтайский край", to: "Республика Хакасия", percentOfReturns: 1.0});
        arr.push({from: "Алтайский край", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Алтайский край", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Алтайский край", to: "Сахалинская обл", percentOfReturns: 2.0});
        arr.push({from: "Алтайский край", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Алтайский край", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Алтайский край", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Алтайский край", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Алтайский край", to: "Томская обл", percentOfReturns: 1.0});
        arr.push({from: "Алтайский край", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Алтайский край", to: "Хабаровский край", percentOfReturns: 1.465160601});
        arr.push({from: "Алтайский край", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Алтайский край", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Алтайский край", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Алтайский край", to: "Чукотский автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Алтайский край", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Амурская обл", to: "г. Москва", percentOfReturns: 1.449275362});
        arr.push({from: "Амурская обл", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Амурская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Амурская обл", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Амурская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Амурская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Амурская обл", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Амурская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Амурская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Амурская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Амурская обл", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Амурская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Амурская обл", to: "Новосибирская обл", percentOfReturns: 1.285328603});
        arr.push({from: "Амурская обл", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Амурская обл", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Амурская обл", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Амурская обл", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Амурская обл", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Амурская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Амурская обл", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Амурская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Амурская обл", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Амурская обл", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Амурская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Амурская обл", to: "Алтайский край", percentOfReturns: 1.260287158});
        arr.push({from: "Амурская обл", to: "Амурская обл", percentOfReturns: 1.0});
        arr.push({from: "Амурская обл", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Амурская обл", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Амурская обл", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Амурская обл", to: "Еврейская автономная обл", percentOfReturns: 1.0});
        arr.push({from: "Амурская обл", to: "Забайкальский край", percentOfReturns: 1.0});
        arr.push({from: "Амурская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 1.0});
        arr.push({from: "Амурская обл", to: "Иркутская обл", percentOfReturns: 1.321310484});
        arr.push({from: "Амурская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Амурская обл", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Амурская обл", to: "Камчатский край", percentOfReturns: 1.0});
        arr.push({from: "Амурская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Амурская обл", to: "Кемеровская обл", percentOfReturns: 1.242138464});
        arr.push({from: "Амурская обл", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Амурская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Амурская обл", to: "Красноярский край", percentOfReturns: 1.308203705});
        arr.push({from: "Амурская обл", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Амурская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Амурская обл", to: "Магаданская обл", percentOfReturns: 1.0});
        arr.push({from: "Амурская обл", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Амурская обл", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Амурская обл", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Амурская обл", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Амурская обл", to: "Омская обл", percentOfReturns: 1.266801133});
        arr.push({from: "Амурская обл", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Амурская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Амурская обл", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Амурская обл", to: "Приморский край", percentOfReturns: 1.0});
        arr.push({from: "Амурская обл", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Амурская обл", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Амурская обл", to: "Республика Алтай", percentOfReturns: 1.265822785});
        arr.push({from: "Амурская обл", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Амурская обл", to: "Республика Бурятия", percentOfReturns: 1.515151515});
        arr.push({from: "Амурская обл", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Амурская обл", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Амурская обл", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Амурская обл", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Амурская обл", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Амурская обл", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Амурская обл", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Амурская обл", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Амурская обл", to: "Республика Саха (Якутия)", percentOfReturns: 1.0});
        arr.push({from: "Амурская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Амурская обл", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Амурская обл", to: "Республика Тыва", percentOfReturns: 1.388888889});
        arr.push({from: "Амурская обл", to: "Республика Хакасия", percentOfReturns: 1.538461538});
        arr.push({from: "Амурская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Амурская обл", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Амурская обл", to: "Сахалинская обл", percentOfReturns: 1.0});
        arr.push({from: "Амурская обл", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Амурская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Амурская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Амурская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Амурская обл", to: "Томская обл", percentOfReturns: 1.295879923});
        arr.push({from: "Амурская обл", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Амурская обл", to: "Хабаровский край", percentOfReturns: 1.0});
        arr.push({from: "Амурская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Амурская обл", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Амурская обл", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Амурская обл", to: "Чукотский автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Амурская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Архангельская обл", to: "г. Москва", percentOfReturns: 1.610177432});
        arr.push({from: "Архангельская обл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Архангельская обл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Архангельская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Архангельская обл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Архангельская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Архангельская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Архангельская обл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Архангельская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Архангельская обл", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Архангельская обл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Архангельская обл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Архангельская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Архангельская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Архангельская обл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Архангельская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Архангельская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Архангельская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Архангельская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Архангельская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Архангельская обл", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Архангельская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Архангельская обл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Архангельская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Архангельская обл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Архангельская обл", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Архангельская обл", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Архангельская обл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Архангельская обл", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Архангельская обл", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Архангельская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Архангельская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Архангельская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Архангельская обл", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Архангельская обл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Архангельская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Архангельская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Архангельская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Архангельская обл", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Архангельская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Архангельская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Архангельская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "г. Москва", percentOfReturns: 1.347045319});
        arr.push({from: "Астраханская обл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Астраханская обл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Астраханская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Астраханская обл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Астраханская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Астраханская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Астраханская обл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Астраханская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Астраханская обл", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Астраханская обл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Астраханская обл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Астраханская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Астраханская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Астраханская обл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Астраханская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Астраханская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Астраханская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Астраханская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Астраханская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Астраханская обл", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Астраханская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Астраханская обл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Астраханская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Астраханская обл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Астраханская обл", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Астраханская обл", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Астраханская обл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Астраханская обл", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Астраханская обл", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Астраханская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Астраханская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Астраханская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Астраханская обл", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Астраханская обл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Астраханская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Астраханская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Астраханская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Астраханская обл", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Астраханская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Астраханская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Астраханская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "г. Москва", percentOfReturns: 1.67260948});
        arr.push({from: "Вологодская обл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Вологодская обл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Вологодская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Вологодская обл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Вологодская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Вологодская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Вологодская обл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Вологодская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Вологодская обл", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Вологодская обл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Вологодская обл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Вологодская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Вологодская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Вологодская обл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Вологодская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Вологодская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Вологодская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Вологодская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Вологодская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Вологодская обл", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Вологодская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Вологодская обл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Вологодская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Вологодская обл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Вологодская обл", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Вологодская обл", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Вологодская обл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Вологодская обл", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Вологодская обл", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Вологодская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Вологодская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Вологодская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Вологодская обл", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Вологодская обл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Вологодская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Вологодская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Вологодская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Вологодская обл", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Вологодская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Вологодская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Вологодская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Еврейская автономная обл", to: "г. Москва", percentOfReturns: 1.470588235});
        arr.push({from: "Еврейская автономная обл", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Еврейская автономная обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Еврейская автономная обл", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Еврейская автономная обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Еврейская автономная обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Еврейская автономная обл", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Еврейская автономная обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Еврейская автономная обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Еврейская автономная обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Еврейская автономная обл", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Еврейская автономная обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Еврейская автономная обл", to: "Новосибирская обл", percentOfReturns: 1.285328603});
        arr.push({from: "Еврейская автономная обл", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Еврейская автономная обл", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Еврейская автономная обл", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Еврейская автономная обл", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Еврейская автономная обл", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Еврейская автономная обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Еврейская автономная обл", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Еврейская автономная обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Еврейская автономная обл", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Еврейская автономная обл", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Еврейская автономная обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Еврейская автономная обл", to: "Алтайский край", percentOfReturns: 1.260287158});
        arr.push({from: "Еврейская автономная обл", to: "Амурская обл", percentOfReturns: 1.0});
        arr.push({from: "Еврейская автономная обл", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Еврейская автономная обл", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Еврейская автономная обл", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Еврейская автономная обл", to: "Еврейская автономная обл", percentOfReturns: 1.0});
        arr.push({from: "Еврейская автономная обл", to: "Забайкальский край", percentOfReturns: 1.0});
        arr.push({from: "Еврейская автономная обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 1.0});
        arr.push({from: "Еврейская автономная обл", to: "Иркутская обл", percentOfReturns: 1.321310484});
        arr.push({from: "Еврейская автономная обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Еврейская автономная обл", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Еврейская автономная обл", to: "Камчатский край", percentOfReturns: 1.0});
        arr.push({from: "Еврейская автономная обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Еврейская автономная обл", to: "Кемеровская обл", percentOfReturns: 1.242138464});
        arr.push({from: "Еврейская автономная обл", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Еврейская автономная обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Еврейская автономная обл", to: "Красноярский край", percentOfReturns: 1.308203705});
        arr.push({from: "Еврейская автономная обл", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Еврейская автономная обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Еврейская автономная обл", to: "Магаданская обл", percentOfReturns: 1.0});
        arr.push({from: "Еврейская автономная обл", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Еврейская автономная обл", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Еврейская автономная обл", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Еврейская автономная обл", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Еврейская автономная обл", to: "Омская обл", percentOfReturns: 1.266801133});
        arr.push({from: "Еврейская автономная обл", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Еврейская автономная обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Еврейская автономная обл", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Еврейская автономная обл", to: "Приморский край", percentOfReturns: 1.0});
        arr.push({from: "Еврейская автономная обл", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Еврейская автономная обл", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Еврейская автономная обл", to: "Республика Алтай", percentOfReturns: 1.265822785});
        arr.push({from: "Еврейская автономная обл", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Еврейская автономная обл", to: "Республика Бурятия", percentOfReturns: 1.515151515});
        arr.push({from: "Еврейская автономная обл", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Еврейская автономная обл", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Еврейская автономная обл", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Еврейская автономная обл", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Еврейская автономная обл", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Еврейская автономная обл", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Еврейская автономная обл", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Еврейская автономная обл", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Еврейская автономная обл", to: "Республика Саха (Якутия)", percentOfReturns: 1.0});
        arr.push({from: "Еврейская автономная обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Еврейская автономная обл", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Еврейская автономная обл", to: "Республика Тыва", percentOfReturns: 1.388888889});
        arr.push({from: "Еврейская автономная обл", to: "Республика Хакасия", percentOfReturns: 1.538461538});
        arr.push({from: "Еврейская автономная обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Еврейская автономная обл", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Еврейская автономная обл", to: "Сахалинская обл", percentOfReturns: 1.0});
        arr.push({from: "Еврейская автономная обл", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Еврейская автономная обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Еврейская автономная обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Еврейская автономная обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Еврейская автономная обл", to: "Томская обл", percentOfReturns: 1.295879923});
        arr.push({from: "Еврейская автономная обл", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Еврейская автономная обл", to: "Хабаровский край", percentOfReturns: 1.0});
        arr.push({from: "Еврейская автономная обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Еврейская автономная обл", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Еврейская автономная обл", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Еврейская автономная обл", to: "Чукотский автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Еврейская автономная обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Забайкальский край", to: "г. Москва", percentOfReturns: 1.587301587});
        arr.push({from: "Забайкальский край", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Забайкальский край", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Забайкальский край", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Забайкальский край", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Забайкальский край", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Забайкальский край", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Забайкальский край", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Забайкальский край", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Забайкальский край", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Забайкальский край", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Забайкальский край", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Забайкальский край", to: "Новосибирская обл", percentOfReturns: 1.285328603});
        arr.push({from: "Забайкальский край", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Забайкальский край", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Забайкальский край", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Забайкальский край", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Забайкальский край", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Забайкальский край", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Забайкальский край", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Забайкальский край", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Забайкальский край", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Забайкальский край", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Забайкальский край", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Забайкальский край", to: "Алтайский край", percentOfReturns: 1.260287158});
        arr.push({from: "Забайкальский край", to: "Амурская обл", percentOfReturns: 1.0});
        arr.push({from: "Забайкальский край", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Забайкальский край", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Забайкальский край", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Забайкальский край", to: "Еврейская автономная обл", percentOfReturns: 1.0});
        arr.push({from: "Забайкальский край", to: "Забайкальский край", percentOfReturns: 1.0});
        arr.push({from: "Забайкальский край", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 1.0});
        arr.push({from: "Забайкальский край", to: "Иркутская обл", percentOfReturns: 1.321310484});
        arr.push({from: "Забайкальский край", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Забайкальский край", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Забайкальский край", to: "Камчатский край", percentOfReturns: 1.0});
        arr.push({from: "Забайкальский край", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Забайкальский край", to: "Кемеровская обл", percentOfReturns: 1.242138464});
        arr.push({from: "Забайкальский край", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Забайкальский край", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Забайкальский край", to: "Красноярский край", percentOfReturns: 1.308203705});
        arr.push({from: "Забайкальский край", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Забайкальский край", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Забайкальский край", to: "Магаданская обл", percentOfReturns: 1.0});
        arr.push({from: "Забайкальский край", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Забайкальский край", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Забайкальский край", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Забайкальский край", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Забайкальский край", to: "Омская обл", percentOfReturns: 1.266801133});
        arr.push({from: "Забайкальский край", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Забайкальский край", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Забайкальский край", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Забайкальский край", to: "Приморский край", percentOfReturns: 1.0});
        arr.push({from: "Забайкальский край", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Забайкальский край", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Забайкальский край", to: "Республика Алтай", percentOfReturns: 1.265822785});
        arr.push({from: "Забайкальский край", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Забайкальский край", to: "Республика Бурятия", percentOfReturns: 1.515151515});
        arr.push({from: "Забайкальский край", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Забайкальский край", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Забайкальский край", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Забайкальский край", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Забайкальский край", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Забайкальский край", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Забайкальский край", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Забайкальский край", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Забайкальский край", to: "Республика Саха (Якутия)", percentOfReturns: 1.0});
        arr.push({from: "Забайкальский край", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Забайкальский край", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Забайкальский край", to: "Республика Тыва", percentOfReturns: 1.388888889});
        arr.push({from: "Забайкальский край", to: "Республика Хакасия", percentOfReturns: 1.538461538});
        arr.push({from: "Забайкальский край", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Забайкальский край", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Забайкальский край", to: "Сахалинская обл", percentOfReturns: 1.0});
        arr.push({from: "Забайкальский край", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Забайкальский край", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Забайкальский край", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Забайкальский край", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Забайкальский край", to: "Томская обл", percentOfReturns: 1.295879923});
        arr.push({from: "Забайкальский край", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Забайкальский край", to: "Хабаровский край", percentOfReturns: 1.0});
        arr.push({from: "Забайкальский край", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Забайкальский край", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Забайкальский край", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Забайкальский край", to: "Чукотский автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Забайкальский край", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "г. Москва", percentOfReturns: 2.0});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Новосибирская обл", percentOfReturns: 1.285328603});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Алтайский край", percentOfReturns: 1.260287158});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Амурская обл", percentOfReturns: 1.0});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Еврейская автономная обл", percentOfReturns: 1.0});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Забайкальский край", percentOfReturns: 1.0});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 1.0});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Иркутская обл", percentOfReturns: 1.321310484});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Камчатский край", percentOfReturns: 1.0});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Кемеровская обл", percentOfReturns: 1.242138464});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Красноярский край", percentOfReturns: 1.308203705});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Магаданская обл", percentOfReturns: 1.0});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Омская обл", percentOfReturns: 1.266801133});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Приморский край", percentOfReturns: 1.0});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Республика Алтай", percentOfReturns: 1.265822785});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Республика Бурятия", percentOfReturns: 1.515151515});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Республика Саха (Якутия)", percentOfReturns: 1.0});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Республика Тыва", percentOfReturns: 1.388888889});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Республика Хакасия", percentOfReturns: 1.538461538});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Сахалинская обл", percentOfReturns: 1.0});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Томская обл", percentOfReturns: 1.295879923});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Хабаровский край", percentOfReturns: 1.0});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Чукотский автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Иные территории, включая город и космодром Байконур", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Иркутская обл", to: "г. Москва", percentOfReturns: 1.321310484});
        arr.push({from: "Иркутская обл", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Иркутская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Иркутская обл", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Иркутская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Иркутская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Иркутская обл", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Иркутская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Иркутская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Иркутская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Иркутская обл", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Иркутская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Иркутская обл", to: "Новосибирская обл", percentOfReturns: 1.0});
        arr.push({from: "Иркутская обл", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Иркутская обл", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Иркутская обл", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Иркутская обл", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Иркутская обл", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Иркутская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Иркутская обл", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Иркутская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Иркутская обл", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Иркутская обл", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Иркутская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Иркутская обл", to: "Алтайский край", percentOfReturns: 1.0});
        arr.push({from: "Иркутская обл", to: "Амурская обл", percentOfReturns: 1.449275362});
        arr.push({from: "Иркутская обл", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Иркутская обл", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Иркутская обл", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Иркутская обл", to: "Еврейская автономная обл", percentOfReturns: 1.470588235});
        arr.push({from: "Иркутская обл", to: "Забайкальский край", percentOfReturns: 1.587301587});
        arr.push({from: "Иркутская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 2.0});
        arr.push({from: "Иркутская обл", to: "Иркутская обл", percentOfReturns: 1.0});
        arr.push({from: "Иркутская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Иркутская обл", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Иркутская обл", to: "Камчатский край", percentOfReturns: 2.0});
        arr.push({from: "Иркутская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Иркутская обл", to: "Кемеровская обл", percentOfReturns: 1.0});
        arr.push({from: "Иркутская обл", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Иркутская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Иркутская обл", to: "Красноярский край", percentOfReturns: 1.0});
        arr.push({from: "Иркутская обл", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Иркутская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Иркутская обл", to: "Магаданская обл", percentOfReturns: 2.0});
        arr.push({from: "Иркутская обл", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Иркутская обл", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Иркутская обл", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Иркутская обл", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Иркутская обл", to: "Омская обл", percentOfReturns: 1.0});
        arr.push({from: "Иркутская обл", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Иркутская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Иркутская обл", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Иркутская обл", to: "Приморский край", percentOfReturns: 1.470588235});
        arr.push({from: "Иркутская обл", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Иркутская обл", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Иркутская обл", to: "Республика Алтай", percentOfReturns: 1.0});
        arr.push({from: "Иркутская обл", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Иркутская обл", to: "Республика Бурятия", percentOfReturns: 1.0});
        arr.push({from: "Иркутская обл", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Иркутская обл", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Иркутская обл", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Иркутская обл", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Иркутская обл", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Иркутская обл", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Иркутская обл", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Иркутская обл", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Иркутская обл", to: "Республика Саха (Якутия)", percentOfReturns: 2.0});
        arr.push({from: "Иркутская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Иркутская обл", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Иркутская обл", to: "Республика Тыва", percentOfReturns: 1.0});
        arr.push({from: "Иркутская обл", to: "Республика Хакасия", percentOfReturns: 1.0});
        arr.push({from: "Иркутская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Иркутская обл", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Иркутская обл", to: "Сахалинская обл", percentOfReturns: 2.0});
        arr.push({from: "Иркутская обл", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Иркутская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Иркутская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Иркутская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Иркутская обл", to: "Томская обл", percentOfReturns: 1.0});
        arr.push({from: "Иркутская обл", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Иркутская обл", to: "Хабаровский край", percentOfReturns: 1.465160601});
        arr.push({from: "Иркутская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Иркутская обл", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Иркутская обл", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Иркутская обл", to: "Чукотский автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Иркутская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "г. Москва", percentOfReturns: 1.19047619});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Кабардино-Балкарская Республика", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Калининградская обл", to: "г. Москва", percentOfReturns: 2.070292645});
        arr.push({from: "Калининградская обл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Калининградская обл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Калининградская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Калининградская обл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Калининградская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Калининградская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Калининградская обл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Калининградская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Калининградская обл", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Калининградская обл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Калининградская обл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Калининградская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Калининградская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Калининградская обл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Калининградская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Калининградская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Калининградская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Калининградская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Калининградская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Калининградская обл", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Калининградская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Калининградская обл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Калининградская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Калининградская обл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Калининградская обл", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Калининградская обл", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Калининградская обл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Калининградская обл", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Калининградская обл", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Калининградская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Калининградская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Калининградская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Калининградская обл", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Калининградская обл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Калининградская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Калининградская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Калининградская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Калининградская обл", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Калининградская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Калининградская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Калининградская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Камчатский край", to: "г. Москва", percentOfReturns: 2.0});
        arr.push({from: "Камчатский край", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Камчатский край", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Камчатский край", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Камчатский край", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Камчатский край", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Камчатский край", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Камчатский край", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Камчатский край", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Камчатский край", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Камчатский край", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Камчатский край", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Камчатский край", to: "Новосибирская обл", percentOfReturns: 1.285328603});
        arr.push({from: "Камчатский край", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Камчатский край", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Камчатский край", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Камчатский край", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Камчатский край", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Камчатский край", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Камчатский край", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Камчатский край", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Камчатский край", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Камчатский край", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Камчатский край", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Камчатский край", to: "Алтайский край", percentOfReturns: 1.260287158});
        arr.push({from: "Камчатский край", to: "Амурская обл", percentOfReturns: 1.0});
        arr.push({from: "Камчатский край", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Камчатский край", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Камчатский край", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Камчатский край", to: "Еврейская автономная обл", percentOfReturns: 1.0});
        arr.push({from: "Камчатский край", to: "Забайкальский край", percentOfReturns: 1.0});
        arr.push({from: "Камчатский край", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 1.0});
        arr.push({from: "Камчатский край", to: "Иркутская обл", percentOfReturns: 1.321310484});
        arr.push({from: "Камчатский край", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Камчатский край", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Камчатский край", to: "Камчатский край", percentOfReturns: 1.0});
        arr.push({from: "Камчатский край", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Камчатский край", to: "Кемеровская обл", percentOfReturns: 1.242138464});
        arr.push({from: "Камчатский край", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Камчатский край", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Камчатский край", to: "Красноярский край", percentOfReturns: 1.308203705});
        arr.push({from: "Камчатский край", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Камчатский край", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Камчатский край", to: "Магаданская обл", percentOfReturns: 1.0});
        arr.push({from: "Камчатский край", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Камчатский край", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Камчатский край", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Камчатский край", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Камчатский край", to: "Омская обл", percentOfReturns: 1.266801133});
        arr.push({from: "Камчатский край", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Камчатский край", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Камчатский край", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Камчатский край", to: "Приморский край", percentOfReturns: 1.0});
        arr.push({from: "Камчатский край", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Камчатский край", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Камчатский край", to: "Республика Алтай", percentOfReturns: 1.265822785});
        arr.push({from: "Камчатский край", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Камчатский край", to: "Республика Бурятия", percentOfReturns: 1.515151515});
        arr.push({from: "Камчатский край", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Камчатский край", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Камчатский край", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Камчатский край", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Камчатский край", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Камчатский край", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Камчатский край", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Камчатский край", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Камчатский край", to: "Республика Саха (Якутия)", percentOfReturns: 1.0});
        arr.push({from: "Камчатский край", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Камчатский край", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Камчатский край", to: "Республика Тыва", percentOfReturns: 1.388888889});
        arr.push({from: "Камчатский край", to: "Республика Хакасия", percentOfReturns: 1.538461538});
        arr.push({from: "Камчатский край", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Камчатский край", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Камчатский край", to: "Сахалинская обл", percentOfReturns: 1.0});
        arr.push({from: "Камчатский край", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Камчатский край", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Камчатский край", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Камчатский край", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Камчатский край", to: "Томская обл", percentOfReturns: 1.295879923});
        arr.push({from: "Камчатский край", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Камчатский край", to: "Хабаровский край", percentOfReturns: 1.0});
        arr.push({from: "Камчатский край", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Камчатский край", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Камчатский край", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Камчатский край", to: "Чукотский автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Камчатский край", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "г. Москва", percentOfReturns: 1.219512195});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Карачаево-Черкесская Республика", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Кемеровская обл", to: "г. Москва", percentOfReturns: 1.242138464});
        arr.push({from: "Кемеровская обл", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Кемеровская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Кемеровская обл", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Кемеровская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Кемеровская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Кемеровская обл", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Кемеровская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Кемеровская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Кемеровская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Кемеровская обл", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Кемеровская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Кемеровская обл", to: "Новосибирская обл", percentOfReturns: 1.0});
        arr.push({from: "Кемеровская обл", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Кемеровская обл", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Кемеровская обл", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Кемеровская обл", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Кемеровская обл", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Кемеровская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Кемеровская обл", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Кемеровская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Кемеровская обл", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Кемеровская обл", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Кемеровская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Кемеровская обл", to: "Алтайский край", percentOfReturns: 1.0});
        arr.push({from: "Кемеровская обл", to: "Амурская обл", percentOfReturns: 1.449275362});
        arr.push({from: "Кемеровская обл", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Кемеровская обл", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Кемеровская обл", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Кемеровская обл", to: "Еврейская автономная обл", percentOfReturns: 1.470588235});
        arr.push({from: "Кемеровская обл", to: "Забайкальский край", percentOfReturns: 1.587301587});
        arr.push({from: "Кемеровская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 2.0});
        arr.push({from: "Кемеровская обл", to: "Иркутская обл", percentOfReturns: 1.0});
        arr.push({from: "Кемеровская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Кемеровская обл", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Кемеровская обл", to: "Камчатский край", percentOfReturns: 2.0});
        arr.push({from: "Кемеровская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Кемеровская обл", to: "Кемеровская обл", percentOfReturns: 1.0});
        arr.push({from: "Кемеровская обл", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Кемеровская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Кемеровская обл", to: "Красноярский край", percentOfReturns: 1.0});
        arr.push({from: "Кемеровская обл", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Кемеровская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Кемеровская обл", to: "Магаданская обл", percentOfReturns: 2.0});
        arr.push({from: "Кемеровская обл", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Кемеровская обл", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Кемеровская обл", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Кемеровская обл", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Кемеровская обл", to: "Омская обл", percentOfReturns: 1.0});
        arr.push({from: "Кемеровская обл", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Кемеровская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Кемеровская обл", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Кемеровская обл", to: "Приморский край", percentOfReturns: 1.470588235});
        arr.push({from: "Кемеровская обл", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Кемеровская обл", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Кемеровская обл", to: "Республика Алтай", percentOfReturns: 1.0});
        arr.push({from: "Кемеровская обл", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Кемеровская обл", to: "Республика Бурятия", percentOfReturns: 1.0});
        arr.push({from: "Кемеровская обл", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Кемеровская обл", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Кемеровская обл", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Кемеровская обл", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Кемеровская обл", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Кемеровская обл", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Кемеровская обл", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Кемеровская обл", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Кемеровская обл", to: "Республика Саха (Якутия)", percentOfReturns: 2.0});
        arr.push({from: "Кемеровская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Кемеровская обл", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Кемеровская обл", to: "Республика Тыва", percentOfReturns: 1.0});
        arr.push({from: "Кемеровская обл", to: "Республика Хакасия", percentOfReturns: 1.0});
        arr.push({from: "Кемеровская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Кемеровская обл", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Кемеровская обл", to: "Сахалинская обл", percentOfReturns: 2.0});
        arr.push({from: "Кемеровская обл", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Кемеровская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Кемеровская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Кемеровская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Кемеровская обл", to: "Томская обл", percentOfReturns: 1.0});
        arr.push({from: "Кемеровская обл", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Кемеровская обл", to: "Хабаровский край", percentOfReturns: 1.465160601});
        arr.push({from: "Кемеровская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Кемеровская обл", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Кемеровская обл", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Кемеровская обл", to: "Чукотский автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Кемеровская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Кировская обл", to: "г. Москва", percentOfReturns: 1.515757265});
        arr.push({from: "Кировская обл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Кировская обл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Кировская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Кировская обл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Кировская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Кировская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Кировская обл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Кировская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Кировская обл", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Кировская обл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Кировская обл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Кировская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Кировская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Кировская обл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Кировская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Кировская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Кировская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Кировская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Кировская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Кировская обл", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Кировская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Кировская обл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Кировская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Кировская обл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Кировская обл", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Кировская обл", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Кировская обл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Кировская обл", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Кировская обл", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Кировская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Кировская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Кировская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Кировская обл", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Кировская обл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Кировская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Кировская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Кировская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Кировская обл", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Кировская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Кировская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Кировская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Костромская обл", to: "г. Москва", percentOfReturns: 1.869224365});
        arr.push({from: "Костромская обл", to: "Ленинградская обл", percentOfReturns: 0.8836815464});
        arr.push({from: "Костромская обл", to: "Московская обл", percentOfReturns: 1.0});
        arr.push({from: "Костромская обл", to: "Санкт-Петербург", percentOfReturns: 0.92});
        arr.push({from: "Костромская обл", to: "Белгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Костромская обл", to: "Владимирская обл", percentOfReturns: 1.0});
        arr.push({from: "Костромская обл", to: "Волгоградская обл", percentOfReturns: 0.7241927852});
        arr.push({from: "Костромская обл", to: "Воронежская обл", percentOfReturns: 1.0});
        arr.push({from: "Костромская обл", to: "Ивановская обл", percentOfReturns: 1.0});
        arr.push({from: "Костромская обл", to: "Калужская обл", percentOfReturns: 1.0});
        arr.push({from: "Костромская обл", to: "Краснодарский край", percentOfReturns: 0.6308422513});
        arr.push({from: "Костромская обл", to: "Липецкая обл", percentOfReturns: 1.0});
        arr.push({from: "Костромская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Костромская обл", to: "Пермский край", percentOfReturns: 0.7386976871});
        arr.push({from: "Костромская обл", to: "Ростовская обл", percentOfReturns: 0.7460847446});
        arr.push({from: "Костромская обл", to: "Самарская обл", percentOfReturns: 0.682038571});
        arr.push({from: "Костромская обл", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Костромская обл", to: "Ставропольский край", percentOfReturns: 0.780143135});
        arr.push({from: "Костромская обл", to: "Тульская обл", percentOfReturns: 1.0});
        arr.push({from: "Костромская обл", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Костромская обл", to: "Ярославская обл", percentOfReturns: 1.0});
        arr.push({from: "Костромская обл", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Костромская обл", to: "Ульяновская обл", percentOfReturns: 0.6202341737});
        arr.push({from: "Костромская обл", to: "Брянская обл", percentOfReturns: 1.0});
        arr.push({from: "Костромская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Костромская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Костромская обл", to: "Архангельская обл", percentOfReturns: 0.6210495687});
        arr.push({from: "Костромская обл", to: "Астраханская обл", percentOfReturns: 0.7423655211});
        arr.push({from: "Костромская обл", to: "Вологодская обл", percentOfReturns: 0.5978681887});
        arr.push({from: "Костромская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Костромская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Костромская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Костромская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Костромская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 0.84});
        arr.push({from: "Костромская обл", to: "Калининградская обл", percentOfReturns: 0.4830235003});
        arr.push({from: "Костромская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Костромская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 0.82});
        arr.push({from: "Костромская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Костромская обл", to: "Кировская обл", percentOfReturns: 0.6597362407});
        arr.push({from: "Костромская обл", to: "Костромская обл", percentOfReturns: 1.0});
        arr.push({from: "Костромская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Костромская обл", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Костромская обл", to: "Курская обл", percentOfReturns: 1.0});
        arr.push({from: "Костромская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Костромская обл", to: "Мурманская обл", percentOfReturns: 0.6576788681});
        arr.push({from: "Костромская обл", to: "Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Костромская обл", to: "Нижегородская обл", percentOfReturns: 0.6953280758});
        arr.push({from: "Костромская обл", to: "Новгородская обл", percentOfReturns: 0.8212070195});
        arr.push({from: "Костромская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Костромская обл", to: "Оренбургская обл", percentOfReturns: 0.6482695668});
        arr.push({from: "Костромская обл", to: "Орловская обл", percentOfReturns: 1.0});
        arr.push({from: "Костромская обл", to: "Пензенская обл", percentOfReturns: 0.6759425671});
        arr.push({from: "Костромская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Костромская обл", to: "Псковская обл", percentOfReturns: 0.5195501327});
        arr.push({from: "Костромская обл", to: "Республика Адыгея", percentOfReturns: 0.7944580255});
        arr.push({from: "Костромская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Костромская обл", to: "Республика Башкортостан", percentOfReturns: 0.7348977438});
        arr.push({from: "Костромская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Костромская обл", to: "Республика Дагестан", percentOfReturns: 0.77});
        arr.push({from: "Костромская обл", to: "Республика Ингушетия", percentOfReturns: 0.77});
        arr.push({from: "Костромская обл", to: "Республика Калмыкия", percentOfReturns: 0.86});
        arr.push({from: "Костромская обл", to: "Республика Карелия", percentOfReturns: 0.6916802688});
        arr.push({from: "Костромская обл", to: "Республика Коми", percentOfReturns: 0.556420352});
        arr.push({from: "Костромская обл", to: "Республика Крым", percentOfReturns: 0.4669283633});
        arr.push({from: "Костромская обл", to: "Республика Марий Эл", percentOfReturns: 0.5664241478});
        arr.push({from: "Костромская обл", to: "Республика Мордовия", percentOfReturns: 0.6367239818});
        arr.push({from: "Костромская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Костромская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 0.6641109995});
        arr.push({from: "Костромская обл", to: "Республика Татарстан", percentOfReturns: 0.599926894});
        arr.push({from: "Костромская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Костромская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Костромская обл", to: "Рязанская обл", percentOfReturns: 1.0});
        arr.push({from: "Костромская обл", to: "Саратовская обл", percentOfReturns: 0.7249067544});
        arr.push({from: "Костромская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Костромская обл", to: "Севастополь", percentOfReturns: 0.72});
        arr.push({from: "Костромская обл", to: "Смоленская обл", percentOfReturns: 1.0});
        arr.push({from: "Костромская обл", to: "Тамбовская обл", percentOfReturns: 1.0});
        arr.push({from: "Костромская обл", to: "Тверская обл", percentOfReturns: 1.0});
        arr.push({from: "Костромская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Костромская обл", to: "Удмуртская Республика", percentOfReturns: 0.5097286307});
        arr.push({from: "Костромская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Костромская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Костромская обл", to: "Чеченская Республика", percentOfReturns: 0.77});
        arr.push({from: "Костромская обл", to: "Чувашская Республика ", percentOfReturns: 0.5500897862});
        arr.push({from: "Костромская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Костромская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Красноярский край", to: "г. Москва", percentOfReturns: 1.308203705});
        arr.push({from: "Красноярский край", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Красноярский край", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Красноярский край", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Красноярский край", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Красноярский край", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Красноярский край", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Красноярский край", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Красноярский край", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Красноярский край", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Красноярский край", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Красноярский край", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Красноярский край", to: "Новосибирская обл", percentOfReturns: 1.0});
        arr.push({from: "Красноярский край", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Красноярский край", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Красноярский край", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Красноярский край", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Красноярский край", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Красноярский край", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Красноярский край", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Красноярский край", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Красноярский край", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Красноярский край", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Красноярский край", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Красноярский край", to: "Алтайский край", percentOfReturns: 1.0});
        arr.push({from: "Красноярский край", to: "Амурская обл", percentOfReturns: 1.449275362});
        arr.push({from: "Красноярский край", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Красноярский край", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Красноярский край", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Красноярский край", to: "Еврейская автономная обл", percentOfReturns: 1.470588235});
        arr.push({from: "Красноярский край", to: "Забайкальский край", percentOfReturns: 1.587301587});
        arr.push({from: "Красноярский край", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 2.0});
        arr.push({from: "Красноярский край", to: "Иркутская обл", percentOfReturns: 1.0});
        arr.push({from: "Красноярский край", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Красноярский край", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Красноярский край", to: "Камчатский край", percentOfReturns: 2.0});
        arr.push({from: "Красноярский край", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Красноярский край", to: "Кемеровская обл", percentOfReturns: 1.0});
        arr.push({from: "Красноярский край", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Красноярский край", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Красноярский край", to: "Красноярский край", percentOfReturns: 1.0});
        arr.push({from: "Красноярский край", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Красноярский край", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Красноярский край", to: "Магаданская обл", percentOfReturns: 2.0});
        arr.push({from: "Красноярский край", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Красноярский край", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Красноярский край", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Красноярский край", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Красноярский край", to: "Омская обл", percentOfReturns: 1.0});
        arr.push({from: "Красноярский край", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Красноярский край", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Красноярский край", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Красноярский край", to: "Приморский край", percentOfReturns: 1.470588235});
        arr.push({from: "Красноярский край", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Красноярский край", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Красноярский край", to: "Республика Алтай", percentOfReturns: 1.0});
        arr.push({from: "Красноярский край", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Красноярский край", to: "Республика Бурятия", percentOfReturns: 1.0});
        arr.push({from: "Красноярский край", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Красноярский край", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Красноярский край", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Красноярский край", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Красноярский край", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Красноярский край", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Красноярский край", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Красноярский край", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Красноярский край", to: "Республика Саха (Якутия)", percentOfReturns: 2.0});
        arr.push({from: "Красноярский край", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Красноярский край", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Красноярский край", to: "Республика Тыва", percentOfReturns: 1.0});
        arr.push({from: "Красноярский край", to: "Республика Хакасия", percentOfReturns: 1.0});
        arr.push({from: "Красноярский край", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Красноярский край", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Красноярский край", to: "Сахалинская обл", percentOfReturns: 2.0});
        arr.push({from: "Красноярский край", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Красноярский край", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Красноярский край", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Красноярский край", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Красноярский край", to: "Томская обл", percentOfReturns: 1.0});
        arr.push({from: "Красноярский край", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Красноярский край", to: "Хабаровский край", percentOfReturns: 1.465160601});
        arr.push({from: "Красноярский край", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Красноярский край", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Красноярский край", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Красноярский край", to: "Чукотский автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Красноярский край", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Курганская обл", to: "г. Москва", percentOfReturns: 1.219512195});
        arr.push({from: "Курганская обл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Курганская обл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Курганская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Курганская обл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Курганская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Курганская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Курганская обл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Курганская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Курганская обл", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Курганская обл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Курганская обл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Курганская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Курганская обл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Курганская обл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Курганская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Курганская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Курганская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Курганская обл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Курганская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Курганская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Курганская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Курганская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Курганская обл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Курганская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Курганская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Курганская обл", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Курганская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Курганская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Курганская обл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Курганская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Курганская обл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Курганская обл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Курганская обл", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Курганская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Курганская обл", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Курганская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Курганская обл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Курганская обл", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Курганская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Курганская обл", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Курганская обл", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Курганская обл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Курганская обл", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Курганская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Курганская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Курганская обл", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Курганская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Курганская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Курганская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Курганская обл", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Курганская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Курганская обл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Курганская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Курганская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Курганская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Курганская обл", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Курганская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Курганская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Курганская обл", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Курганская обл", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Курганская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Курганская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Курская обл", to: "г. Москва", percentOfReturns: 1.209008367});
        arr.push({from: "Курская обл", to: "Ленинградская обл", percentOfReturns: 0.8836815464});
        arr.push({from: "Курская обл", to: "Московская обл", percentOfReturns: 1.0});
        arr.push({from: "Курская обл", to: "Санкт-Петербург", percentOfReturns: 0.92});
        arr.push({from: "Курская обл", to: "Белгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Курская обл", to: "Владимирская обл", percentOfReturns: 1.0});
        arr.push({from: "Курская обл", to: "Волгоградская обл", percentOfReturns: 0.7241927852});
        arr.push({from: "Курская обл", to: "Воронежская обл", percentOfReturns: 1.0});
        arr.push({from: "Курская обл", to: "Ивановская обл", percentOfReturns: 1.0});
        arr.push({from: "Курская обл", to: "Калужская обл", percentOfReturns: 1.0});
        arr.push({from: "Курская обл", to: "Краснодарский край", percentOfReturns: 0.6308422513});
        arr.push({from: "Курская обл", to: "Липецкая обл", percentOfReturns: 1.0});
        arr.push({from: "Курская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Курская обл", to: "Пермский край", percentOfReturns: 0.7386976871});
        arr.push({from: "Курская обл", to: "Ростовская обл", percentOfReturns: 0.7460847446});
        arr.push({from: "Курская обл", to: "Самарская обл", percentOfReturns: 0.682038571});
        arr.push({from: "Курская обл", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Курская обл", to: "Ставропольский край", percentOfReturns: 0.780143135});
        arr.push({from: "Курская обл", to: "Тульская обл", percentOfReturns: 1.0});
        arr.push({from: "Курская обл", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Курская обл", to: "Ярославская обл", percentOfReturns: 1.0});
        arr.push({from: "Курская обл", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Курская обл", to: "Ульяновская обл", percentOfReturns: 0.6202341737});
        arr.push({from: "Курская обл", to: "Брянская обл", percentOfReturns: 1.0});
        arr.push({from: "Курская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Курская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Курская обл", to: "Архангельская обл", percentOfReturns: 0.6210495687});
        arr.push({from: "Курская обл", to: "Астраханская обл", percentOfReturns: 0.7423655211});
        arr.push({from: "Курская обл", to: "Вологодская обл", percentOfReturns: 0.5978681887});
        arr.push({from: "Курская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Курская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Курская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Курская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Курская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 0.84});
        arr.push({from: "Курская обл", to: "Калининградская обл", percentOfReturns: 0.4830235003});
        arr.push({from: "Курская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Курская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 0.82});
        arr.push({from: "Курская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Курская обл", to: "Кировская обл", percentOfReturns: 0.6597362407});
        arr.push({from: "Курская обл", to: "Костромская обл", percentOfReturns: 1.0});
        arr.push({from: "Курская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Курская обл", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Курская обл", to: "Курская обл", percentOfReturns: 1.0});
        arr.push({from: "Курская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Курская обл", to: "Мурманская обл", percentOfReturns: 0.6576788681});
        arr.push({from: "Курская обл", to: "Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Курская обл", to: "Нижегородская обл", percentOfReturns: 0.6953280758});
        arr.push({from: "Курская обл", to: "Новгородская обл", percentOfReturns: 0.8212070195});
        arr.push({from: "Курская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Курская обл", to: "Оренбургская обл", percentOfReturns: 0.6482695668});
        arr.push({from: "Курская обл", to: "Орловская обл", percentOfReturns: 1.0});
        arr.push({from: "Курская обл", to: "Пензенская обл", percentOfReturns: 0.6759425671});
        arr.push({from: "Курская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Курская обл", to: "Псковская обл", percentOfReturns: 0.5195501327});
        arr.push({from: "Курская обл", to: "Республика Адыгея", percentOfReturns: 0.7944580255});
        arr.push({from: "Курская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Курская обл", to: "Республика Башкортостан", percentOfReturns: 0.7348977438});
        arr.push({from: "Курская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Курская обл", to: "Республика Дагестан", percentOfReturns: 0.77});
        arr.push({from: "Курская обл", to: "Республика Ингушетия", percentOfReturns: 0.77});
        arr.push({from: "Курская обл", to: "Республика Калмыкия", percentOfReturns: 0.86});
        arr.push({from: "Курская обл", to: "Республика Карелия", percentOfReturns: 0.6916802688});
        arr.push({from: "Курская обл", to: "Республика Коми", percentOfReturns: 0.556420352});
        arr.push({from: "Курская обл", to: "Республика Крым", percentOfReturns: 0.4669283633});
        arr.push({from: "Курская обл", to: "Республика Марий Эл", percentOfReturns: 0.5664241478});
        arr.push({from: "Курская обл", to: "Республика Мордовия", percentOfReturns: 0.6367239818});
        arr.push({from: "Курская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Курская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 0.6641109995});
        arr.push({from: "Курская обл", to: "Республика Татарстан", percentOfReturns: 0.599926894});
        arr.push({from: "Курская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Курская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Курская обл", to: "Рязанская обл", percentOfReturns: 1.0});
        arr.push({from: "Курская обл", to: "Саратовская обл", percentOfReturns: 0.7249067544});
        arr.push({from: "Курская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Курская обл", to: "Севастополь", percentOfReturns: 0.72});
        arr.push({from: "Курская обл", to: "Смоленская обл", percentOfReturns: 1.0});
        arr.push({from: "Курская обл", to: "Тамбовская обл", percentOfReturns: 1.0});
        arr.push({from: "Курская обл", to: "Тверская обл", percentOfReturns: 1.0});
        arr.push({from: "Курская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Курская обл", to: "Удмуртская Республика", percentOfReturns: 0.5097286307});
        arr.push({from: "Курская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Курская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Курская обл", to: "Чеченская Республика", percentOfReturns: 0.77});
        arr.push({from: "Курская обл", to: "Чувашская Республика ", percentOfReturns: 0.5500897862});
        arr.push({from: "Курская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Курская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Магаданская обл", to: "г. Москва", percentOfReturns: 2.0});
        arr.push({from: "Магаданская обл", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Магаданская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Магаданская обл", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Магаданская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Магаданская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Магаданская обл", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Магаданская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Магаданская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Магаданская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Магаданская обл", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Магаданская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Магаданская обл", to: "Новосибирская обл", percentOfReturns: 1.285328603});
        arr.push({from: "Магаданская обл", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Магаданская обл", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Магаданская обл", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Магаданская обл", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Магаданская обл", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Магаданская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Магаданская обл", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Магаданская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Магаданская обл", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Магаданская обл", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Магаданская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Магаданская обл", to: "Алтайский край", percentOfReturns: 1.260287158});
        arr.push({from: "Магаданская обл", to: "Амурская обл", percentOfReturns: 1.0});
        arr.push({from: "Магаданская обл", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Магаданская обл", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Магаданская обл", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Магаданская обл", to: "Еврейская автономная обл", percentOfReturns: 1.0});
        arr.push({from: "Магаданская обл", to: "Забайкальский край", percentOfReturns: 1.0});
        arr.push({from: "Магаданская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 1.0});
        arr.push({from: "Магаданская обл", to: "Иркутская обл", percentOfReturns: 1.321310484});
        arr.push({from: "Магаданская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Магаданская обл", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Магаданская обл", to: "Камчатский край", percentOfReturns: 1.0});
        arr.push({from: "Магаданская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Магаданская обл", to: "Кемеровская обл", percentOfReturns: 1.242138464});
        arr.push({from: "Магаданская обл", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Магаданская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Магаданская обл", to: "Красноярский край", percentOfReturns: 1.308203705});
        arr.push({from: "Магаданская обл", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Магаданская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Магаданская обл", to: "Магаданская обл", percentOfReturns: 1.0});
        arr.push({from: "Магаданская обл", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Магаданская обл", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Магаданская обл", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Магаданская обл", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Магаданская обл", to: "Омская обл", percentOfReturns: 1.266801133});
        arr.push({from: "Магаданская обл", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Магаданская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Магаданская обл", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Магаданская обл", to: "Приморский край", percentOfReturns: 1.0});
        arr.push({from: "Магаданская обл", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Магаданская обл", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Магаданская обл", to: "Республика Алтай", percentOfReturns: 1.265822785});
        arr.push({from: "Магаданская обл", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Магаданская обл", to: "Республика Бурятия", percentOfReturns: 1.515151515});
        arr.push({from: "Магаданская обл", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Магаданская обл", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Магаданская обл", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Магаданская обл", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Магаданская обл", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Магаданская обл", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Магаданская обл", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Магаданская обл", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Магаданская обл", to: "Республика Саха (Якутия)", percentOfReturns: 1.0});
        arr.push({from: "Магаданская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Магаданская обл", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Магаданская обл", to: "Республика Тыва", percentOfReturns: 1.388888889});
        arr.push({from: "Магаданская обл", to: "Республика Хакасия", percentOfReturns: 1.538461538});
        arr.push({from: "Магаданская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Магаданская обл", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Магаданская обл", to: "Сахалинская обл", percentOfReturns: 1.0});
        arr.push({from: "Магаданская обл", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Магаданская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Магаданская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Магаданская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Магаданская обл", to: "Томская обл", percentOfReturns: 1.295879923});
        arr.push({from: "Магаданская обл", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Магаданская обл", to: "Хабаровский край", percentOfReturns: 1.0});
        arr.push({from: "Магаданская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Магаданская обл", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Магаданская обл", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Магаданская обл", to: "Чукотский автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Магаданская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Мурманская обл", to: "г. Москва", percentOfReturns: 1.520498907});
        arr.push({from: "Мурманская обл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Мурманская обл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Мурманская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Мурманская обл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Мурманская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Мурманская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Мурманская обл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Мурманская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Мурманская обл", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Мурманская обл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Мурманская обл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Мурманская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Мурманская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Мурманская обл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Мурманская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Мурманская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Мурманская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Мурманская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Мурманская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Мурманская обл", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Мурманская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Мурманская обл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Мурманская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Мурманская обл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Мурманская обл", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Мурманская обл", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Мурманская обл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Мурманская обл", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Мурманская обл", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Мурманская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Мурманская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Мурманская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Мурманская обл", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Мурманская обл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Мурманская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Мурманская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Мурманская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Мурманская обл", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Мурманская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Мурманская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Мурманская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "г. Москва", percentOfReturns: 2.0});
        arr.push({from: "Ненецкий автономный округ", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ненецкий автономный округ", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Ненецкий автономный округ", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ненецкий автономный округ", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Ненецкий автономный округ", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Ненецкий автономный округ", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ненецкий автономный округ", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Ненецкий автономный округ", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Ненецкий автономный округ", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ненецкий автономный округ", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Ненецкий автономный округ", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Ненецкий автономный округ", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Ненецкий автономный округ", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Ненецкий автономный округ", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Ненецкий автономный округ", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Ненецкий автономный округ", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Ненецкий автономный округ", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Ненецкий автономный округ", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Ненецкий автономный округ", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Ненецкий автономный округ", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Ненецкий автономный округ", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Ненецкий автономный округ", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Ненецкий автономный округ", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Ненецкий автономный округ", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Ненецкий автономный округ", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Ненецкий автономный округ", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Ненецкий автономный округ", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Ненецкий автономный округ", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Ненецкий автономный округ", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Ненецкий автономный округ", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Ненецкий автономный округ", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Ненецкий автономный округ", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Ненецкий автономный округ", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Ненецкий автономный округ", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Ненецкий автономный округ", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Ненецкий автономный округ", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ненецкий автономный округ", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Ненецкий автономный округ", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Ненецкий автономный округ", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Ненецкий автономный округ", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Ненецкий автономный округ", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "г. Москва", percentOfReturns: 1.43817003});
        arr.push({from: "Нижегородская обл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Нижегородская обл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Нижегородская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Нижегородская обл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Нижегородская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Нижегородская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Нижегородская обл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Нижегородская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Нижегородская обл", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Нижегородская обл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Нижегородская обл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Нижегородская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Нижегородская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Нижегородская обл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Нижегородская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Нижегородская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Нижегородская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Нижегородская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Нижегородская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Нижегородская обл", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Нижегородская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Нижегородская обл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Нижегородская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Нижегородская обл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Нижегородская обл", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Нижегородская обл", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Нижегородская обл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Нижегородская обл", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Нижегородская обл", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Нижегородская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Нижегородская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Нижегородская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Нижегородская обл", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Нижегородская обл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Нижегородская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Нижегородская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Нижегородская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Нижегородская обл", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Нижегородская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Нижегородская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Нижегородская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "г. Москва", percentOfReturns: 1.217719742});
        arr.push({from: "Новгородская обл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Новгородская обл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Новгородская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Новгородская обл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Новгородская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Новгородская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Новгородская обл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Новгородская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Новгородская обл", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Новгородская обл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Новгородская обл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Новгородская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Новгородская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Новгородская обл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Новгородская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Новгородская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Новгородская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Новгородская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Новгородская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Новгородская обл", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Новгородская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Новгородская обл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Новгородская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Новгородская обл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Новгородская обл", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Новгородская обл", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Новгородская обл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Новгородская обл", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Новгородская обл", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Новгородская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Новгородская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Новгородская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Новгородская обл", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Новгородская обл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Новгородская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Новгородская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Новгородская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Новгородская обл", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Новгородская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Новгородская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Новгородская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Омская обл", to: "г. Москва", percentOfReturns: 1.266801133});
        arr.push({from: "Омская обл", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Омская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Омская обл", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Омская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Омская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Омская обл", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Омская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Омская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Омская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Омская обл", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Омская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Омская обл", to: "Новосибирская обл", percentOfReturns: 1.0});
        arr.push({from: "Омская обл", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Омская обл", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Омская обл", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Омская обл", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Омская обл", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Омская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Омская обл", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Омская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Омская обл", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Омская обл", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Омская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Омская обл", to: "Алтайский край", percentOfReturns: 1.0});
        arr.push({from: "Омская обл", to: "Амурская обл", percentOfReturns: 1.449275362});
        arr.push({from: "Омская обл", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Омская обл", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Омская обл", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Омская обл", to: "Еврейская автономная обл", percentOfReturns: 1.470588235});
        arr.push({from: "Омская обл", to: "Забайкальский край", percentOfReturns: 1.587301587});
        arr.push({from: "Омская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 2.0});
        arr.push({from: "Омская обл", to: "Иркутская обл", percentOfReturns: 1.0});
        arr.push({from: "Омская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Омская обл", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Омская обл", to: "Камчатский край", percentOfReturns: 2.0});
        arr.push({from: "Омская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Омская обл", to: "Кемеровская обл", percentOfReturns: 1.0});
        arr.push({from: "Омская обл", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Омская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Омская обл", to: "Красноярский край", percentOfReturns: 1.0});
        arr.push({from: "Омская обл", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Омская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Омская обл", to: "Магаданская обл", percentOfReturns: 2.0});
        arr.push({from: "Омская обл", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Омская обл", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Омская обл", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Омская обл", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Омская обл", to: "Омская обл", percentOfReturns: 1.0});
        arr.push({from: "Омская обл", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Омская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Омская обл", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Омская обл", to: "Приморский край", percentOfReturns: 1.470588235});
        arr.push({from: "Омская обл", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Омская обл", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Омская обл", to: "Республика Алтай", percentOfReturns: 1.0});
        arr.push({from: "Омская обл", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Омская обл", to: "Республика Бурятия", percentOfReturns: 1.0});
        arr.push({from: "Омская обл", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Омская обл", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Омская обл", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Омская обл", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Омская обл", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Омская обл", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Омская обл", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Омская обл", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Омская обл", to: "Республика Саха (Якутия)", percentOfReturns: 2.0});
        arr.push({from: "Омская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Омская обл", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Омская обл", to: "Республика Тыва", percentOfReturns: 1.0});
        arr.push({from: "Омская обл", to: "Республика Хакасия", percentOfReturns: 1.0});
        arr.push({from: "Омская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Омская обл", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Омская обл", to: "Сахалинская обл", percentOfReturns: 2.0});
        arr.push({from: "Омская обл", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Омская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Омская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Омская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Омская обл", to: "Томская обл", percentOfReturns: 1.0});
        arr.push({from: "Омская обл", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Омская обл", to: "Хабаровский край", percentOfReturns: 1.465160601});
        arr.push({from: "Омская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Омская обл", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Омская обл", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Омская обл", to: "Чукотский автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Омская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Оренбургская обл", to: "г. Москва", percentOfReturns: 1.542568171});
        arr.push({from: "Оренбургская обл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Оренбургская обл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Оренбургская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Оренбургская обл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Оренбургская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Оренбургская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Оренбургская обл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Оренбургская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Оренбургская обл", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Оренбургская обл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Оренбургская обл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Оренбургская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Оренбургская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Оренбургская обл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Оренбургская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Оренбургская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Оренбургская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Оренбургская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Оренбургская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Оренбургская обл", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Оренбургская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Оренбургская обл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Оренбургская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Оренбургская обл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Оренбургская обл", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Оренбургская обл", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Оренбургская обл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Оренбургская обл", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Оренбургская обл", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Оренбургская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Оренбургская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Оренбургская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Оренбургская обл", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Оренбургская обл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Оренбургская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Оренбургская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Оренбургская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Оренбургская обл", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Оренбургская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Оренбургская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Оренбургская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Орловская обл", to: "г. Москва", percentOfReturns: 1.471396762});
        arr.push({from: "Орловская обл", to: "Ленинградская обл", percentOfReturns: 0.8836815464});
        arr.push({from: "Орловская обл", to: "Московская обл", percentOfReturns: 1.0});
        arr.push({from: "Орловская обл", to: "Санкт-Петербург", percentOfReturns: 0.92});
        arr.push({from: "Орловская обл", to: "Белгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Орловская обл", to: "Владимирская обл", percentOfReturns: 1.0});
        arr.push({from: "Орловская обл", to: "Волгоградская обл", percentOfReturns: 0.7241927852});
        arr.push({from: "Орловская обл", to: "Воронежская обл", percentOfReturns: 1.0});
        arr.push({from: "Орловская обл", to: "Ивановская обл", percentOfReturns: 1.0});
        arr.push({from: "Орловская обл", to: "Калужская обл", percentOfReturns: 1.0});
        arr.push({from: "Орловская обл", to: "Краснодарский край", percentOfReturns: 0.6308422513});
        arr.push({from: "Орловская обл", to: "Липецкая обл", percentOfReturns: 1.0});
        arr.push({from: "Орловская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Орловская обл", to: "Пермский край", percentOfReturns: 0.7386976871});
        arr.push({from: "Орловская обл", to: "Ростовская обл", percentOfReturns: 0.7460847446});
        arr.push({from: "Орловская обл", to: "Самарская обл", percentOfReturns: 0.682038571});
        arr.push({from: "Орловская обл", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Орловская обл", to: "Ставропольский край", percentOfReturns: 0.780143135});
        arr.push({from: "Орловская обл", to: "Тульская обл", percentOfReturns: 1.0});
        arr.push({from: "Орловская обл", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Орловская обл", to: "Ярославская обл", percentOfReturns: 1.0});
        arr.push({from: "Орловская обл", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Орловская обл", to: "Ульяновская обл", percentOfReturns: 0.6202341737});
        arr.push({from: "Орловская обл", to: "Брянская обл", percentOfReturns: 1.0});
        arr.push({from: "Орловская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Орловская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Орловская обл", to: "Архангельская обл", percentOfReturns: 0.6210495687});
        arr.push({from: "Орловская обл", to: "Астраханская обл", percentOfReturns: 0.7423655211});
        arr.push({from: "Орловская обл", to: "Вологодская обл", percentOfReturns: 0.5978681887});
        arr.push({from: "Орловская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Орловская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Орловская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Орловская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Орловская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 0.84});
        arr.push({from: "Орловская обл", to: "Калининградская обл", percentOfReturns: 0.4830235003});
        arr.push({from: "Орловская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Орловская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 0.82});
        arr.push({from: "Орловская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Орловская обл", to: "Кировская обл", percentOfReturns: 0.6597362407});
        arr.push({from: "Орловская обл", to: "Костромская обл", percentOfReturns: 1.0});
        arr.push({from: "Орловская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Орловская обл", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Орловская обл", to: "Курская обл", percentOfReturns: 1.0});
        arr.push({from: "Орловская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Орловская обл", to: "Мурманская обл", percentOfReturns: 0.6576788681});
        arr.push({from: "Орловская обл", to: "Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Орловская обл", to: "Нижегородская обл", percentOfReturns: 0.6953280758});
        arr.push({from: "Орловская обл", to: "Новгородская обл", percentOfReturns: 0.8212070195});
        arr.push({from: "Орловская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Орловская обл", to: "Оренбургская обл", percentOfReturns: 0.6482695668});
        arr.push({from: "Орловская обл", to: "Орловская обл", percentOfReturns: 1.0});
        arr.push({from: "Орловская обл", to: "Пензенская обл", percentOfReturns: 0.6759425671});
        arr.push({from: "Орловская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Орловская обл", to: "Псковская обл", percentOfReturns: 0.5195501327});
        arr.push({from: "Орловская обл", to: "Республика Адыгея", percentOfReturns: 0.7944580255});
        arr.push({from: "Орловская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Орловская обл", to: "Республика Башкортостан", percentOfReturns: 0.7348977438});
        arr.push({from: "Орловская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Орловская обл", to: "Республика Дагестан", percentOfReturns: 0.77});
        arr.push({from: "Орловская обл", to: "Республика Ингушетия", percentOfReturns: 0.77});
        arr.push({from: "Орловская обл", to: "Республика Калмыкия", percentOfReturns: 0.86});
        arr.push({from: "Орловская обл", to: "Республика Карелия", percentOfReturns: 0.6916802688});
        arr.push({from: "Орловская обл", to: "Республика Коми", percentOfReturns: 0.556420352});
        arr.push({from: "Орловская обл", to: "Республика Крым", percentOfReturns: 0.4669283633});
        arr.push({from: "Орловская обл", to: "Республика Марий Эл", percentOfReturns: 0.5664241478});
        arr.push({from: "Орловская обл", to: "Республика Мордовия", percentOfReturns: 0.6367239818});
        arr.push({from: "Орловская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Орловская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 0.6641109995});
        arr.push({from: "Орловская обл", to: "Республика Татарстан", percentOfReturns: 0.599926894});
        arr.push({from: "Орловская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Орловская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Орловская обл", to: "Рязанская обл", percentOfReturns: 1.0});
        arr.push({from: "Орловская обл", to: "Саратовская обл", percentOfReturns: 0.7249067544});
        arr.push({from: "Орловская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Орловская обл", to: "Севастополь", percentOfReturns: 0.72});
        arr.push({from: "Орловская обл", to: "Смоленская обл", percentOfReturns: 1.0});
        arr.push({from: "Орловская обл", to: "Тамбовская обл", percentOfReturns: 1.0});
        arr.push({from: "Орловская обл", to: "Тверская обл", percentOfReturns: 1.0});
        arr.push({from: "Орловская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Орловская обл", to: "Удмуртская Республика", percentOfReturns: 0.5097286307});
        arr.push({from: "Орловская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Орловская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Орловская обл", to: "Чеченская Республика", percentOfReturns: 0.77});
        arr.push({from: "Орловская обл", to: "Чувашская Республика ", percentOfReturns: 0.5500897862});
        arr.push({from: "Орловская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Орловская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Пензенская обл", to: "г. Москва", percentOfReturns: 1.479415632});
        arr.push({from: "Пензенская обл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Пензенская обл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Пензенская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Пензенская обл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Пензенская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Пензенская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Пензенская обл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Пензенская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Пензенская обл", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Пензенская обл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Пензенская обл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Пензенская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Пензенская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Пензенская обл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Пензенская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Пензенская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Пензенская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Пензенская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Пензенская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Пензенская обл", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Пензенская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Пензенская обл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Пензенская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Пензенская обл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Пензенская обл", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Пензенская обл", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Пензенская обл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Пензенская обл", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Пензенская обл", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Пензенская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Пензенская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Пензенская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Пензенская обл", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Пензенская обл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Пензенская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Пензенская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Пензенская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Пензенская обл", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Пензенская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Пензенская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Пензенская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Приморский край", to: "г. Москва", percentOfReturns: 1.470588235});
        arr.push({from: "Приморский край", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Приморский край", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Приморский край", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Приморский край", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Приморский край", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Приморский край", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Приморский край", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Приморский край", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Приморский край", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Приморский край", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Приморский край", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Приморский край", to: "Новосибирская обл", percentOfReturns: 1.285328603});
        arr.push({from: "Приморский край", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Приморский край", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Приморский край", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Приморский край", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Приморский край", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Приморский край", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Приморский край", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Приморский край", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Приморский край", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Приморский край", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Приморский край", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Приморский край", to: "Алтайский край", percentOfReturns: 1.260287158});
        arr.push({from: "Приморский край", to: "Амурская обл", percentOfReturns: 1.0});
        arr.push({from: "Приморский край", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Приморский край", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Приморский край", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Приморский край", to: "Еврейская автономная обл", percentOfReturns: 1.0});
        arr.push({from: "Приморский край", to: "Забайкальский край", percentOfReturns: 1.0});
        arr.push({from: "Приморский край", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 1.0});
        arr.push({from: "Приморский край", to: "Иркутская обл", percentOfReturns: 1.321310484});
        arr.push({from: "Приморский край", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Приморский край", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Приморский край", to: "Камчатский край", percentOfReturns: 1.0});
        arr.push({from: "Приморский край", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Приморский край", to: "Кемеровская обл", percentOfReturns: 1.242138464});
        arr.push({from: "Приморский край", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Приморский край", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Приморский край", to: "Красноярский край", percentOfReturns: 1.308203705});
        arr.push({from: "Приморский край", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Приморский край", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Приморский край", to: "Магаданская обл", percentOfReturns: 1.0});
        arr.push({from: "Приморский край", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Приморский край", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Приморский край", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Приморский край", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Приморский край", to: "Омская обл", percentOfReturns: 1.266801133});
        arr.push({from: "Приморский край", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Приморский край", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Приморский край", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Приморский край", to: "Приморский край", percentOfReturns: 1.0});
        arr.push({from: "Приморский край", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Приморский край", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Приморский край", to: "Республика Алтай", percentOfReturns: 1.265822785});
        arr.push({from: "Приморский край", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Приморский край", to: "Республика Бурятия", percentOfReturns: 1.515151515});
        arr.push({from: "Приморский край", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Приморский край", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Приморский край", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Приморский край", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Приморский край", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Приморский край", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Приморский край", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Приморский край", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Приморский край", to: "Республика Саха (Якутия)", percentOfReturns: 1.0});
        arr.push({from: "Приморский край", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Приморский край", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Приморский край", to: "Республика Тыва", percentOfReturns: 1.388888889});
        arr.push({from: "Приморский край", to: "Республика Хакасия", percentOfReturns: 1.538461538});
        arr.push({from: "Приморский край", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Приморский край", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Приморский край", to: "Сахалинская обл", percentOfReturns: 1.0});
        arr.push({from: "Приморский край", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Приморский край", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Приморский край", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Приморский край", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Приморский край", to: "Томская обл", percentOfReturns: 1.295879923});
        arr.push({from: "Приморский край", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Приморский край", to: "Хабаровский край", percentOfReturns: 1.0});
        arr.push({from: "Приморский край", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Приморский край", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Приморский край", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Приморский край", to: "Чукотский автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Приморский край", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Псковская обл", to: "г. Москва", percentOfReturns: 1.924742074});
        arr.push({from: "Псковская обл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Псковская обл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Псковская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Псковская обл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Псковская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Псковская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Псковская обл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Псковская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Псковская обл", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Псковская обл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Псковская обл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Псковская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Псковская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Псковская обл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Псковская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Псковская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Псковская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Псковская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Псковская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Псковская обл", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Псковская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Псковская обл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Псковская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Псковская обл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Псковская обл", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Псковская обл", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Псковская обл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Псковская обл", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Псковская обл", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Псковская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Псковская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Псковская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Псковская обл", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Псковская обл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Псковская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Псковская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Псковская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Псковская обл", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Псковская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Псковская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Псковская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "г. Москва", percentOfReturns: 1.258719741});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Республика Адыгея (Адыгея)", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Алтай", to: "г. Москва", percentOfReturns: 1.265822785});
        arr.push({from: "Республика Алтай", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Республика Алтай", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Алтай", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Республика Алтай", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Республика Алтай", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Алтай", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Республика Алтай", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Республика Алтай", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Республика Алтай", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Алтай", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Республика Алтай", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Республика Алтай", to: "Новосибирская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Алтай", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Республика Алтай", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Республика Алтай", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Республика Алтай", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Республика Алтай", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Республика Алтай", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Алтай", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Республика Алтай", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Республика Алтай", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Республика Алтай", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Республика Алтай", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Республика Алтай", to: "Алтайский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Алтай", to: "Амурская обл", percentOfReturns: 1.449275362});
        arr.push({from: "Республика Алтай", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Республика Алтай", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Республика Алтай", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Республика Алтай", to: "Еврейская автономная обл", percentOfReturns: 1.470588235});
        arr.push({from: "Республика Алтай", to: "Забайкальский край", percentOfReturns: 1.587301587});
        arr.push({from: "Республика Алтай", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 2.0});
        arr.push({from: "Республика Алтай", to: "Иркутская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Алтай", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Республика Алтай", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Республика Алтай", to: "Камчатский край", percentOfReturns: 2.0});
        arr.push({from: "Республика Алтай", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Республика Алтай", to: "Кемеровская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Алтай", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Республика Алтай", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Республика Алтай", to: "Красноярский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Алтай", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Республика Алтай", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Республика Алтай", to: "Магаданская обл", percentOfReturns: 2.0});
        arr.push({from: "Республика Алтай", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Республика Алтай", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Республика Алтай", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Республика Алтай", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Республика Алтай", to: "Омская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Алтай", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Республика Алтай", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Республика Алтай", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Республика Алтай", to: "Приморский край", percentOfReturns: 1.470588235});
        arr.push({from: "Республика Алтай", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Республика Алтай", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Республика Алтай", to: "Республика Алтай", percentOfReturns: 1.0});
        arr.push({from: "Республика Алтай", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Республика Алтай", to: "Республика Бурятия", percentOfReturns: 1.0});
        arr.push({from: "Республика Алтай", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Республика Алтай", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Республика Алтай", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Республика Алтай", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Республика Алтай", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Республика Алтай", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Республика Алтай", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Республика Алтай", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Республика Алтай", to: "Республика Саха (Якутия)", percentOfReturns: 2.0});
        arr.push({from: "Республика Алтай", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Республика Алтай", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Республика Алтай", to: "Республика Тыва", percentOfReturns: 1.0});
        arr.push({from: "Республика Алтай", to: "Республика Хакасия", percentOfReturns: 1.0});
        arr.push({from: "Республика Алтай", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Республика Алтай", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Республика Алтай", to: "Сахалинская обл", percentOfReturns: 2.0});
        arr.push({from: "Республика Алтай", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Республика Алтай", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Республика Алтай", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Республика Алтай", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Алтай", to: "Томская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Алтай", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Республика Алтай", to: "Хабаровский край", percentOfReturns: 1.465160601});
        arr.push({from: "Республика Алтай", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Республика Алтай", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Республика Алтай", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Республика Алтай", to: "Чукотский автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Республика Алтай", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Республика Башкортостан", to: "г. Москва", percentOfReturns: 1.360733528});
        arr.push({from: "Республика Башкортостан", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Башкортостан", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Республика Башкортостан", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Башкортостан", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Республика Башкортостан", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Республика Башкортостан", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Башкортостан", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Республика Башкортостан", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Республика Башкортостан", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Башкортостан", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Республика Башкортостан", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Республика Башкортостан", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Республика Башкортостан", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Республика Башкортостан", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Республика Башкортостан", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Республика Башкортостан", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Республика Башкортостан", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Республика Башкортостан", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Республика Башкортостан", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Республика Башкортостан", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Республика Башкортостан", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Республика Башкортостан", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Республика Башкортостан", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Башкортостан", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Республика Башкортостан", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Республика Башкортостан", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Республика Башкортостан", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Республика Башкортостан", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Республика Башкортостан", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Республика Башкортостан", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Республика Башкортостан", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Республика Башкортостан", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Республика Башкортостан", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Башкортостан", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Республика Башкортостан", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Республика Башкортостан", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Башкортостан", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Республика Башкортостан", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Республика Башкортостан", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Республика Башкортостан", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Республика Башкортостан", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Бурятия", to: "г. Москва", percentOfReturns: 1.515151515});
        arr.push({from: "Республика Бурятия", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Республика Бурятия", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Бурятия", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Республика Бурятия", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Республика Бурятия", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Бурятия", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Республика Бурятия", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Республика Бурятия", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Республика Бурятия", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Бурятия", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Республика Бурятия", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Республика Бурятия", to: "Новосибирская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Бурятия", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Республика Бурятия", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Республика Бурятия", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Республика Бурятия", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Республика Бурятия", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Республика Бурятия", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Бурятия", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Республика Бурятия", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Республика Бурятия", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Республика Бурятия", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Республика Бурятия", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Республика Бурятия", to: "Алтайский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Бурятия", to: "Амурская обл", percentOfReturns: 1.449275362});
        arr.push({from: "Республика Бурятия", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Республика Бурятия", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Республика Бурятия", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Республика Бурятия", to: "Еврейская автономная обл", percentOfReturns: 1.470588235});
        arr.push({from: "Республика Бурятия", to: "Забайкальский край", percentOfReturns: 1.587301587});
        arr.push({from: "Республика Бурятия", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 2.0});
        arr.push({from: "Республика Бурятия", to: "Иркутская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Бурятия", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Республика Бурятия", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Республика Бурятия", to: "Камчатский край", percentOfReturns: 2.0});
        arr.push({from: "Республика Бурятия", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Республика Бурятия", to: "Кемеровская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Бурятия", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Республика Бурятия", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Республика Бурятия", to: "Красноярский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Бурятия", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Республика Бурятия", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Республика Бурятия", to: "Магаданская обл", percentOfReturns: 2.0});
        arr.push({from: "Республика Бурятия", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Республика Бурятия", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Республика Бурятия", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Республика Бурятия", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Республика Бурятия", to: "Омская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Бурятия", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Республика Бурятия", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Республика Бурятия", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Республика Бурятия", to: "Приморский край", percentOfReturns: 1.470588235});
        arr.push({from: "Республика Бурятия", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Республика Бурятия", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Республика Бурятия", to: "Республика Алтай", percentOfReturns: 1.0});
        arr.push({from: "Республика Бурятия", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Республика Бурятия", to: "Республика Бурятия", percentOfReturns: 1.0});
        arr.push({from: "Республика Бурятия", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Республика Бурятия", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Республика Бурятия", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Республика Бурятия", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Республика Бурятия", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Республика Бурятия", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Республика Бурятия", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Республика Бурятия", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Республика Бурятия", to: "Республика Саха (Якутия)", percentOfReturns: 2.0});
        arr.push({from: "Республика Бурятия", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Республика Бурятия", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Республика Бурятия", to: "Республика Тыва", percentOfReturns: 1.0});
        arr.push({from: "Республика Бурятия", to: "Республика Хакасия", percentOfReturns: 1.0});
        arr.push({from: "Республика Бурятия", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Республика Бурятия", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Республика Бурятия", to: "Сахалинская обл", percentOfReturns: 2.0});
        arr.push({from: "Республика Бурятия", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Республика Бурятия", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Республика Бурятия", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Республика Бурятия", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Бурятия", to: "Томская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Бурятия", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Республика Бурятия", to: "Хабаровский край", percentOfReturns: 1.465160601});
        arr.push({from: "Республика Бурятия", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Республика Бурятия", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Республика Бурятия", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Республика Бурятия", to: "Чукотский автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Республика Бурятия", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Республика Дагестан", to: "г. Москва", percentOfReturns: 1.298701299});
        arr.push({from: "Республика Дагестан", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Дагестан", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Республика Дагестан", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Дагестан", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Республика Дагестан", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Республика Дагестан", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Дагестан", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Республика Дагестан", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Республика Дагестан", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Республика Дагестан", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Дагестан", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Республика Дагестан", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Республика Дагестан", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Республика Дагестан", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Республика Дагестан", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Республика Дагестан", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Республика Дагестан", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Республика Дагестан", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Республика Дагестан", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Республика Дагестан", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Республика Дагестан", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Республика Дагестан", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Республика Дагестан", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Республика Дагестан", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Республика Дагестан", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Республика Дагестан", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Республика Дагестан", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Дагестан", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Республика Дагестан", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Республика Дагестан", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Республика Дагестан", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Республика Дагестан", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Республика Дагестан", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Республика Дагестан", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Республика Дагестан", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Республика Дагестан", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Республика Дагестан", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Дагестан", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Республика Дагестан", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Республика Дагестан", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Дагестан", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Республика Дагестан", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Республика Дагестан", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Республика Дагестан", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Республика Дагестан", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Республика Дагестан", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Республика Ингушетия", to: "г. Москва", percentOfReturns: 1.298701299});
        arr.push({from: "Республика Ингушетия", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Ингушетия", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Республика Ингушетия", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Ингушетия", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Республика Ингушетия", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Республика Ингушетия", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Ингушетия", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Республика Ингушетия", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Республика Ингушетия", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Республика Ингушетия", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Ингушетия", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Республика Ингушетия", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Республика Ингушетия", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Республика Ингушетия", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Республика Ингушетия", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Республика Ингушетия", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Республика Ингушетия", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Республика Ингушетия", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Республика Ингушетия", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Республика Ингушетия", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Республика Ингушетия", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Республика Ингушетия", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Республика Ингушетия", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Республика Ингушетия", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Республика Ингушетия", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Республика Ингушетия", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Республика Ингушетия", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Ингушетия", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Республика Ингушетия", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Республика Ингушетия", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Республика Ингушетия", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Республика Ингушетия", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Республика Ингушетия", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Республика Ингушетия", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Республика Ингушетия", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Республика Ингушетия", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Республика Ингушетия", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Ингушетия", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Республика Ингушетия", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Республика Ингушетия", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Ингушетия", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Республика Ингушетия", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Республика Ингушетия", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Республика Ингушетия", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Республика Ингушетия", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Республика Ингушетия", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Республика Калмыкия", to: "г. Москва", percentOfReturns: 1.162790698});
        arr.push({from: "Республика Калмыкия", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Калмыкия", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Республика Калмыкия", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Калмыкия", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Республика Калмыкия", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Республика Калмыкия", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Калмыкия", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Республика Калмыкия", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Республика Калмыкия", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Калмыкия", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Республика Калмыкия", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Республика Калмыкия", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Республика Калмыкия", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Республика Калмыкия", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Республика Калмыкия", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Республика Калмыкия", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Республика Калмыкия", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Республика Калмыкия", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Республика Калмыкия", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Республика Калмыкия", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Республика Калмыкия", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Республика Калмыкия", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Республика Калмыкия", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Калмыкия", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Республика Калмыкия", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Республика Калмыкия", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Республика Калмыкия", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Республика Калмыкия", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Республика Калмыкия", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Республика Калмыкия", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Республика Калмыкия", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Республика Калмыкия", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Республика Калмыкия", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Калмыкия", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Республика Калмыкия", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Республика Калмыкия", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Калмыкия", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Республика Калмыкия", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Республика Калмыкия", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Республика Калмыкия", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Республика Калмыкия", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "г. Москва", percentOfReturns: 1.445754701});
        arr.push({from: "Республика Карелия", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Карелия", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Республика Карелия", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Карелия", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Республика Карелия", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Республика Карелия", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Карелия", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Республика Карелия", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Республика Карелия", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Карелия", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Республика Карелия", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Республика Карелия", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Республика Карелия", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Республика Карелия", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Республика Карелия", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Республика Карелия", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Республика Карелия", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Республика Карелия", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Республика Карелия", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Республика Карелия", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Республика Карелия", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Республика Карелия", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Республика Карелия", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Карелия", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Республика Карелия", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Республика Карелия", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Республика Карелия", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Республика Карелия", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Республика Карелия", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Республика Карелия", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Республика Карелия", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Республика Карелия", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Республика Карелия", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Карелия", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Республика Карелия", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Республика Карелия", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Карелия", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Республика Карелия", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Республика Карелия", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Республика Карелия", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Республика Карелия", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "г. Москва", percentOfReturns: 1.797202414});
        arr.push({from: "Республика Коми", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Коми", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Республика Коми", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Коми", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Республика Коми", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Республика Коми", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Коми", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Республика Коми", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Республика Коми", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Коми", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Республика Коми", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Республика Коми", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Республика Коми", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Республика Коми", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Республика Коми", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Республика Коми", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Республика Коми", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Республика Коми", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Республика Коми", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Республика Коми", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Республика Коми", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Республика Коми", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Республика Коми", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Коми", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Республика Коми", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Республика Коми", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Республика Коми", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Республика Коми", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Республика Коми", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Республика Коми", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Республика Коми", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Республика Коми", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Республика Коми", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Коми", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Республика Коми", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Республика Коми", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Коми", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Республика Коми", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Республика Коми", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Республика Коми", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Республика Коми", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "г. Москва", percentOfReturns: 2.141656148});
        arr.push({from: "Республика Крым", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Крым", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Республика Крым", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Крым", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Республика Крым", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Республика Крым", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Крым", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Республика Крым", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Республика Крым", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Крым", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Республика Крым", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Республика Крым", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Республика Крым", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Республика Крым", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Республика Крым", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Республика Крым", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Республика Крым", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Республика Крым", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Республика Крым", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Республика Крым", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Республика Крым", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Республика Крым", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Республика Крым", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Крым", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Республика Крым", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Республика Крым", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Республика Крым", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Республика Крым", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Республика Крым", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Республика Крым", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Республика Крым", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Республика Крым", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Республика Крым", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Крым", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Республика Крым", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Республика Крым", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Крым", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Республика Крым", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Республика Крым", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Республика Крым", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Республика Крым", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "г. Москва", percentOfReturns: 1.765461455});
        arr.push({from: "Республика Марий Эл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Марий Эл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Республика Марий Эл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Марий Эл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Республика Марий Эл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Республика Марий Эл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Марий Эл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Республика Марий Эл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Республика Марий Эл", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Марий Эл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Республика Марий Эл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Республика Марий Эл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Республика Марий Эл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Республика Марий Эл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Республика Марий Эл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Республика Марий Эл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Республика Марий Эл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Республика Марий Эл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Республика Марий Эл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Республика Марий Эл", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Республика Марий Эл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Республика Марий Эл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Республика Марий Эл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Марий Эл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Республика Марий Эл", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Республика Марий Эл", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Республика Марий Эл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Республика Марий Эл", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Республика Марий Эл", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Республика Марий Эл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Республика Марий Эл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Республика Марий Эл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Республика Марий Эл", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Марий Эл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Республика Марий Эл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Республика Марий Эл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Марий Эл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Республика Марий Эл", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Республика Марий Эл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Республика Марий Эл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Республика Марий Эл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "г. Москва", percentOfReturns: 1.570539242});
        arr.push({from: "Республика Мордовия", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Мордовия", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Республика Мордовия", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Мордовия", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Республика Мордовия", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Республика Мордовия", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Мордовия", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Республика Мордовия", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Республика Мордовия", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Мордовия", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Республика Мордовия", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Республика Мордовия", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Республика Мордовия", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Республика Мордовия", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Республика Мордовия", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Республика Мордовия", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Республика Мордовия", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Республика Мордовия", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Республика Мордовия", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Республика Мордовия", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Республика Мордовия", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Республика Мордовия", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Республика Мордовия", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Мордовия", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Республика Мордовия", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Республика Мордовия", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Республика Мордовия", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Республика Мордовия", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Республика Мордовия", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Республика Мордовия", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Республика Мордовия", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Республика Мордовия", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Республика Мордовия", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Мордовия", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Республика Мордовия", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Республика Мордовия", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Мордовия", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Республика Мордовия", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Республика Мордовия", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Республика Мордовия", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Республика Мордовия", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Саха (Якутия)", to: "г. Москва", percentOfReturns: 2.0});
        arr.push({from: "Республика Саха (Якутия)", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Республика Саха (Якутия)", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Саха (Якутия)", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Республика Саха (Якутия)", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Республика Саха (Якутия)", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Саха (Якутия)", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Республика Саха (Якутия)", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Республика Саха (Якутия)", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Республика Саха (Якутия)", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Саха (Якутия)", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Республика Саха (Якутия)", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Республика Саха (Якутия)", to: "Новосибирская обл", percentOfReturns: 1.285328603});
        arr.push({from: "Республика Саха (Якутия)", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Республика Саха (Якутия)", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Республика Саха (Якутия)", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Республика Саха (Якутия)", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Республика Саха (Якутия)", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Республика Саха (Якутия)", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Саха (Якутия)", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Республика Саха (Якутия)", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Республика Саха (Якутия)", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Республика Саха (Якутия)", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Республика Саха (Якутия)", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Республика Саха (Якутия)", to: "Алтайский край", percentOfReturns: 1.260287158});
        arr.push({from: "Республика Саха (Якутия)", to: "Амурская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Саха (Якутия)", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Республика Саха (Якутия)", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Республика Саха (Якутия)", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Республика Саха (Якутия)", to: "Еврейская автономная обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Саха (Якутия)", to: "Забайкальский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Саха (Якутия)", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 1.0});
        arr.push({from: "Республика Саха (Якутия)", to: "Иркутская обл", percentOfReturns: 1.321310484});
        arr.push({from: "Республика Саха (Якутия)", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Республика Саха (Якутия)", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Республика Саха (Якутия)", to: "Камчатский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Саха (Якутия)", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Республика Саха (Якутия)", to: "Кемеровская обл", percentOfReturns: 1.242138464});
        arr.push({from: "Республика Саха (Якутия)", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Республика Саха (Якутия)", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Республика Саха (Якутия)", to: "Красноярский край", percentOfReturns: 1.308203705});
        arr.push({from: "Республика Саха (Якутия)", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Республика Саха (Якутия)", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Республика Саха (Якутия)", to: "Магаданская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Саха (Якутия)", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Республика Саха (Якутия)", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Республика Саха (Якутия)", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Республика Саха (Якутия)", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Республика Саха (Якутия)", to: "Омская обл", percentOfReturns: 1.266801133});
        arr.push({from: "Республика Саха (Якутия)", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Республика Саха (Якутия)", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Республика Саха (Якутия)", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Республика Саха (Якутия)", to: "Приморский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Саха (Якутия)", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Республика Саха (Якутия)", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Республика Саха (Якутия)", to: "Республика Алтай", percentOfReturns: 1.265822785});
        arr.push({from: "Республика Саха (Якутия)", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Республика Саха (Якутия)", to: "Республика Бурятия", percentOfReturns: 1.515151515});
        arr.push({from: "Республика Саха (Якутия)", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Республика Саха (Якутия)", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Республика Саха (Якутия)", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Республика Саха (Якутия)", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Республика Саха (Якутия)", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Республика Саха (Якутия)", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Республика Саха (Якутия)", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Республика Саха (Якутия)", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Республика Саха (Якутия)", to: "Республика Саха (Якутия)", percentOfReturns: 1.0});
        arr.push({from: "Республика Саха (Якутия)", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Республика Саха (Якутия)", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Республика Саха (Якутия)", to: "Республика Тыва", percentOfReturns: 1.388888889});
        arr.push({from: "Республика Саха (Якутия)", to: "Республика Хакасия", percentOfReturns: 1.538461538});
        arr.push({from: "Республика Саха (Якутия)", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Республика Саха (Якутия)", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Республика Саха (Якутия)", to: "Сахалинская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Саха (Якутия)", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Республика Саха (Якутия)", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Республика Саха (Якутия)", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Республика Саха (Якутия)", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Саха (Якутия)", to: "Томская обл", percentOfReturns: 1.295879923});
        arr.push({from: "Республика Саха (Якутия)", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Республика Саха (Якутия)", to: "Хабаровский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Саха (Якутия)", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Республика Саха (Якутия)", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Республика Саха (Якутия)", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Республика Саха (Якутия)", to: "Чукотский автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Саха (Якутия)", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "г. Москва", percentOfReturns: 1.50577238});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Республика Северная Осетия - Алания", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "г. Москва", percentOfReturns: 1.666869764});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Республика Татарстан (Татарстан)", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Республика Тыва", to: "г. Москва", percentOfReturns: 1.388888889});
        arr.push({from: "Республика Тыва", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Республика Тыва", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Тыва", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Республика Тыва", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Республика Тыва", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Тыва", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Республика Тыва", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Республика Тыва", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Республика Тыва", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Тыва", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Республика Тыва", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Республика Тыва", to: "Новосибирская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Тыва", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Республика Тыва", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Республика Тыва", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Республика Тыва", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Республика Тыва", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Республика Тыва", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Тыва", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Республика Тыва", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Республика Тыва", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Республика Тыва", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Республика Тыва", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Республика Тыва", to: "Алтайский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Тыва", to: "Амурская обл", percentOfReturns: 1.449275362});
        arr.push({from: "Республика Тыва", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Республика Тыва", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Республика Тыва", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Республика Тыва", to: "Еврейская автономная обл", percentOfReturns: 1.470588235});
        arr.push({from: "Республика Тыва", to: "Забайкальский край", percentOfReturns: 1.587301587});
        arr.push({from: "Республика Тыва", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 2.0});
        arr.push({from: "Республика Тыва", to: "Иркутская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Тыва", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Республика Тыва", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Республика Тыва", to: "Камчатский край", percentOfReturns: 2.0});
        arr.push({from: "Республика Тыва", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Республика Тыва", to: "Кемеровская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Тыва", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Республика Тыва", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Республика Тыва", to: "Красноярский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Тыва", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Республика Тыва", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Республика Тыва", to: "Магаданская обл", percentOfReturns: 2.0});
        arr.push({from: "Республика Тыва", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Республика Тыва", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Республика Тыва", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Республика Тыва", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Республика Тыва", to: "Омская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Тыва", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Республика Тыва", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Республика Тыва", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Республика Тыва", to: "Приморский край", percentOfReturns: 1.470588235});
        arr.push({from: "Республика Тыва", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Республика Тыва", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Республика Тыва", to: "Республика Алтай", percentOfReturns: 1.0});
        arr.push({from: "Республика Тыва", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Республика Тыва", to: "Республика Бурятия", percentOfReturns: 1.0});
        arr.push({from: "Республика Тыва", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Республика Тыва", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Республика Тыва", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Республика Тыва", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Республика Тыва", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Республика Тыва", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Республика Тыва", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Республика Тыва", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Республика Тыва", to: "Республика Саха (Якутия)", percentOfReturns: 2.0});
        arr.push({from: "Республика Тыва", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Республика Тыва", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Республика Тыва", to: "Республика Тыва", percentOfReturns: 1.0});
        arr.push({from: "Республика Тыва", to: "Республика Хакасия", percentOfReturns: 1.0});
        arr.push({from: "Республика Тыва", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Республика Тыва", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Республика Тыва", to: "Сахалинская обл", percentOfReturns: 2.0});
        arr.push({from: "Республика Тыва", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Республика Тыва", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Республика Тыва", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Республика Тыва", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Тыва", to: "Томская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Тыва", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Республика Тыва", to: "Хабаровский край", percentOfReturns: 1.465160601});
        arr.push({from: "Республика Тыва", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Республика Тыва", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Республика Тыва", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Республика Тыва", to: "Чукотский автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Республика Тыва", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Республика Хакасия", to: "г. Москва", percentOfReturns: 1.538461538});
        arr.push({from: "Республика Хакасия", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Республика Хакасия", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Хакасия", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Республика Хакасия", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Республика Хакасия", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Хакасия", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Республика Хакасия", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Республика Хакасия", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Республика Хакасия", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Хакасия", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Республика Хакасия", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Республика Хакасия", to: "Новосибирская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Хакасия", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Республика Хакасия", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Республика Хакасия", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Республика Хакасия", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Республика Хакасия", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Республика Хакасия", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Хакасия", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Республика Хакасия", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Республика Хакасия", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Республика Хакасия", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Республика Хакасия", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Республика Хакасия", to: "Алтайский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Хакасия", to: "Амурская обл", percentOfReturns: 1.449275362});
        arr.push({from: "Республика Хакасия", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Республика Хакасия", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Республика Хакасия", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Республика Хакасия", to: "Еврейская автономная обл", percentOfReturns: 1.470588235});
        arr.push({from: "Республика Хакасия", to: "Забайкальский край", percentOfReturns: 1.587301587});
        arr.push({from: "Республика Хакасия", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 2.0});
        arr.push({from: "Республика Хакасия", to: "Иркутская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Хакасия", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Республика Хакасия", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Республика Хакасия", to: "Камчатский край", percentOfReturns: 2.0});
        arr.push({from: "Республика Хакасия", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Республика Хакасия", to: "Кемеровская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Хакасия", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Республика Хакасия", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Республика Хакасия", to: "Красноярский край", percentOfReturns: 1.0});
        arr.push({from: "Республика Хакасия", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Республика Хакасия", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Республика Хакасия", to: "Магаданская обл", percentOfReturns: 2.0});
        arr.push({from: "Республика Хакасия", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Республика Хакасия", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Республика Хакасия", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Республика Хакасия", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Республика Хакасия", to: "Омская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Хакасия", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Республика Хакасия", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Республика Хакасия", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Республика Хакасия", to: "Приморский край", percentOfReturns: 1.470588235});
        arr.push({from: "Республика Хакасия", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Республика Хакасия", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Республика Хакасия", to: "Республика Алтай", percentOfReturns: 1.0});
        arr.push({from: "Республика Хакасия", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Республика Хакасия", to: "Республика Бурятия", percentOfReturns: 1.0});
        arr.push({from: "Республика Хакасия", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Республика Хакасия", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Республика Хакасия", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Республика Хакасия", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Республика Хакасия", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Республика Хакасия", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Республика Хакасия", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Республика Хакасия", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Республика Хакасия", to: "Республика Саха (Якутия)", percentOfReturns: 2.0});
        arr.push({from: "Республика Хакасия", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Республика Хакасия", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Республика Хакасия", to: "Республика Тыва", percentOfReturns: 1.0});
        arr.push({from: "Республика Хакасия", to: "Республика Хакасия", percentOfReturns: 1.0});
        arr.push({from: "Республика Хакасия", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Республика Хакасия", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Республика Хакасия", to: "Сахалинская обл", percentOfReturns: 2.0});
        arr.push({from: "Республика Хакасия", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Республика Хакасия", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Республика Хакасия", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Республика Хакасия", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Республика Хакасия", to: "Томская обл", percentOfReturns: 1.0});
        arr.push({from: "Республика Хакасия", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Республика Хакасия", to: "Хабаровский край", percentOfReturns: 1.465160601});
        arr.push({from: "Республика Хакасия", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Республика Хакасия", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Республика Хакасия", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Республика Хакасия", to: "Чукотский автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Республика Хакасия", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Рязанская обл", to: "г. Москва", percentOfReturns: 2.319386097});
        arr.push({from: "Рязанская обл", to: "Ленинградская обл", percentOfReturns: 0.8836815464});
        arr.push({from: "Рязанская обл", to: "Московская обл", percentOfReturns: 1.0});
        arr.push({from: "Рязанская обл", to: "Санкт-Петербург", percentOfReturns: 0.92});
        arr.push({from: "Рязанская обл", to: "Белгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Рязанская обл", to: "Владимирская обл", percentOfReturns: 1.0});
        arr.push({from: "Рязанская обл", to: "Волгоградская обл", percentOfReturns: 0.7241927852});
        arr.push({from: "Рязанская обл", to: "Воронежская обл", percentOfReturns: 1.0});
        arr.push({from: "Рязанская обл", to: "Ивановская обл", percentOfReturns: 1.0});
        arr.push({from: "Рязанская обл", to: "Калужская обл", percentOfReturns: 1.0});
        arr.push({from: "Рязанская обл", to: "Краснодарский край", percentOfReturns: 0.6308422513});
        arr.push({from: "Рязанская обл", to: "Липецкая обл", percentOfReturns: 1.0});
        arr.push({from: "Рязанская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Рязанская обл", to: "Пермский край", percentOfReturns: 0.7386976871});
        arr.push({from: "Рязанская обл", to: "Ростовская обл", percentOfReturns: 0.7460847446});
        arr.push({from: "Рязанская обл", to: "Самарская обл", percentOfReturns: 0.682038571});
        arr.push({from: "Рязанская обл", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Рязанская обл", to: "Ставропольский край", percentOfReturns: 0.780143135});
        arr.push({from: "Рязанская обл", to: "Тульская обл", percentOfReturns: 1.0});
        arr.push({from: "Рязанская обл", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Рязанская обл", to: "Ярославская обл", percentOfReturns: 1.0});
        arr.push({from: "Рязанская обл", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Рязанская обл", to: "Ульяновская обл", percentOfReturns: 0.6202341737});
        arr.push({from: "Рязанская обл", to: "Брянская обл", percentOfReturns: 1.0});
        arr.push({from: "Рязанская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Рязанская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Рязанская обл", to: "Архангельская обл", percentOfReturns: 0.6210495687});
        arr.push({from: "Рязанская обл", to: "Астраханская обл", percentOfReturns: 0.7423655211});
        arr.push({from: "Рязанская обл", to: "Вологодская обл", percentOfReturns: 0.5978681887});
        arr.push({from: "Рязанская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Рязанская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Рязанская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Рязанская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Рязанская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 0.84});
        arr.push({from: "Рязанская обл", to: "Калининградская обл", percentOfReturns: 0.4830235003});
        arr.push({from: "Рязанская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Рязанская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 0.82});
        arr.push({from: "Рязанская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Рязанская обл", to: "Кировская обл", percentOfReturns: 0.6597362407});
        arr.push({from: "Рязанская обл", to: "Костромская обл", percentOfReturns: 1.0});
        arr.push({from: "Рязанская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Рязанская обл", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Рязанская обл", to: "Курская обл", percentOfReturns: 1.0});
        arr.push({from: "Рязанская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Рязанская обл", to: "Мурманская обл", percentOfReturns: 0.6576788681});
        arr.push({from: "Рязанская обл", to: "Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Рязанская обл", to: "Нижегородская обл", percentOfReturns: 0.6953280758});
        arr.push({from: "Рязанская обл", to: "Новгородская обл", percentOfReturns: 0.8212070195});
        arr.push({from: "Рязанская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Рязанская обл", to: "Оренбургская обл", percentOfReturns: 0.6482695668});
        arr.push({from: "Рязанская обл", to: "Орловская обл", percentOfReturns: 1.0});
        arr.push({from: "Рязанская обл", to: "Пензенская обл", percentOfReturns: 0.6759425671});
        arr.push({from: "Рязанская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Рязанская обл", to: "Псковская обл", percentOfReturns: 0.5195501327});
        arr.push({from: "Рязанская обл", to: "Республика Адыгея", percentOfReturns: 0.7944580255});
        arr.push({from: "Рязанская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Рязанская обл", to: "Республика Башкортостан", percentOfReturns: 0.7348977438});
        arr.push({from: "Рязанская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Рязанская обл", to: "Республика Дагестан", percentOfReturns: 0.77});
        arr.push({from: "Рязанская обл", to: "Республика Ингушетия", percentOfReturns: 0.77});
        arr.push({from: "Рязанская обл", to: "Республика Калмыкия", percentOfReturns: 0.86});
        arr.push({from: "Рязанская обл", to: "Республика Карелия", percentOfReturns: 0.6916802688});
        arr.push({from: "Рязанская обл", to: "Республика Коми", percentOfReturns: 0.556420352});
        arr.push({from: "Рязанская обл", to: "Республика Крым", percentOfReturns: 0.4669283633});
        arr.push({from: "Рязанская обл", to: "Республика Марий Эл", percentOfReturns: 0.5664241478});
        arr.push({from: "Рязанская обл", to: "Республика Мордовия", percentOfReturns: 0.6367239818});
        arr.push({from: "Рязанская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Рязанская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 0.6641109995});
        arr.push({from: "Рязанская обл", to: "Республика Татарстан", percentOfReturns: 0.599926894});
        arr.push({from: "Рязанская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Рязанская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Рязанская обл", to: "Рязанская обл", percentOfReturns: 1.0});
        arr.push({from: "Рязанская обл", to: "Саратовская обл", percentOfReturns: 0.7249067544});
        arr.push({from: "Рязанская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Рязанская обл", to: "Севастополь", percentOfReturns: 0.72});
        arr.push({from: "Рязанская обл", to: "Смоленская обл", percentOfReturns: 1.0});
        arr.push({from: "Рязанская обл", to: "Тамбовская обл", percentOfReturns: 1.0});
        arr.push({from: "Рязанская обл", to: "Тверская обл", percentOfReturns: 1.0});
        arr.push({from: "Рязанская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Рязанская обл", to: "Удмуртская Республика", percentOfReturns: 0.5097286307});
        arr.push({from: "Рязанская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Рязанская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Рязанская обл", to: "Чеченская Республика", percentOfReturns: 0.77});
        arr.push({from: "Рязанская обл", to: "Чувашская Республика ", percentOfReturns: 0.5500897862});
        arr.push({from: "Рязанская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Рязанская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Саратовская обл", to: "г. Москва", percentOfReturns: 1.379487767});
        arr.push({from: "Саратовская обл", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Саратовская обл", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Саратовская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Саратовская обл", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Саратовская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Саратовская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Саратовская обл", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Саратовская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Саратовская обл", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Саратовская обл", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Саратовская обл", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Саратовская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Саратовская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Саратовская обл", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Саратовская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Саратовская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Саратовская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Саратовская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Саратовская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Саратовская обл", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Саратовская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Саратовская обл", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Саратовская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Саратовская обл", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Саратовская обл", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Саратовская обл", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Саратовская обл", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Саратовская обл", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Саратовская обл", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Саратовская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Саратовская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Саратовская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Саратовская обл", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Саратовская обл", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Саратовская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Саратовская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Саратовская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Саратовская обл", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Саратовская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Саратовская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Саратовская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Сахалинская обл", to: "г. Москва", percentOfReturns: 2.0});
        arr.push({from: "Сахалинская обл", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Сахалинская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Сахалинская обл", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Сахалинская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Сахалинская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Сахалинская обл", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Сахалинская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Сахалинская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Сахалинская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Сахалинская обл", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Сахалинская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Сахалинская обл", to: "Новосибирская обл", percentOfReturns: 1.285328603});
        arr.push({from: "Сахалинская обл", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Сахалинская обл", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Сахалинская обл", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Сахалинская обл", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Сахалинская обл", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Сахалинская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Сахалинская обл", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Сахалинская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Сахалинская обл", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Сахалинская обл", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Сахалинская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Сахалинская обл", to: "Алтайский край", percentOfReturns: 1.260287158});
        arr.push({from: "Сахалинская обл", to: "Амурская обл", percentOfReturns: 1.0});
        arr.push({from: "Сахалинская обл", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Сахалинская обл", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Сахалинская обл", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Сахалинская обл", to: "Еврейская автономная обл", percentOfReturns: 1.0});
        arr.push({from: "Сахалинская обл", to: "Забайкальский край", percentOfReturns: 1.0});
        arr.push({from: "Сахалинская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 1.0});
        arr.push({from: "Сахалинская обл", to: "Иркутская обл", percentOfReturns: 1.321310484});
        arr.push({from: "Сахалинская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Сахалинская обл", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Сахалинская обл", to: "Камчатский край", percentOfReturns: 1.0});
        arr.push({from: "Сахалинская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Сахалинская обл", to: "Кемеровская обл", percentOfReturns: 1.242138464});
        arr.push({from: "Сахалинская обл", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Сахалинская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Сахалинская обл", to: "Красноярский край", percentOfReturns: 1.308203705});
        arr.push({from: "Сахалинская обл", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Сахалинская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Сахалинская обл", to: "Магаданская обл", percentOfReturns: 1.0});
        arr.push({from: "Сахалинская обл", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Сахалинская обл", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Сахалинская обл", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Сахалинская обл", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Сахалинская обл", to: "Омская обл", percentOfReturns: 1.266801133});
        arr.push({from: "Сахалинская обл", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Сахалинская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Сахалинская обл", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Сахалинская обл", to: "Приморский край", percentOfReturns: 1.0});
        arr.push({from: "Сахалинская обл", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Сахалинская обл", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Сахалинская обл", to: "Республика Алтай", percentOfReturns: 1.265822785});
        arr.push({from: "Сахалинская обл", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Сахалинская обл", to: "Республика Бурятия", percentOfReturns: 1.515151515});
        arr.push({from: "Сахалинская обл", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Сахалинская обл", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Сахалинская обл", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Сахалинская обл", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Сахалинская обл", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Сахалинская обл", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Сахалинская обл", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Сахалинская обл", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Сахалинская обл", to: "Республика Саха (Якутия)", percentOfReturns: 1.0});
        arr.push({from: "Сахалинская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Сахалинская обл", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Сахалинская обл", to: "Республика Тыва", percentOfReturns: 1.388888889});
        arr.push({from: "Сахалинская обл", to: "Республика Хакасия", percentOfReturns: 1.538461538});
        arr.push({from: "Сахалинская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Сахалинская обл", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Сахалинская обл", to: "Сахалинская обл", percentOfReturns: 1.0});
        arr.push({from: "Сахалинская обл", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Сахалинская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Сахалинская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Сахалинская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Сахалинская обл", to: "Томская обл", percentOfReturns: 1.295879923});
        arr.push({from: "Сахалинская обл", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Сахалинская обл", to: "Хабаровский край", percentOfReturns: 1.0});
        arr.push({from: "Сахалинская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Сахалинская обл", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Сахалинская обл", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Сахалинская обл", to: "Чукотский автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Сахалинская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Севастополь", to: "г. Москва", percentOfReturns: 1.388888889});
        arr.push({from: "Севастополь", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Севастополь", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Севастополь", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Севастополь", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Севастополь", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Севастополь", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Севастополь", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Севастополь", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Севастополь", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Севастополь", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Севастополь", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Севастополь", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Севастополь", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Севастополь", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Севастополь", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Севастополь", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Севастополь", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Севастополь", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Севастополь", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Севастополь", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Севастополь", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Севастополь", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Севастополь", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Севастополь", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Севастополь", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Севастополь", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Севастополь", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Севастополь", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Севастополь", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Севастополь", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Севастополь", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Севастополь", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Севастополь", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Севастополь", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Севастополь", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Севастополь", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Севастополь", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Севастополь", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Севастополь", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Севастополь", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Севастополь", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Смоленская обл", to: "г. Москва", percentOfReturns: 1.016028677});
        arr.push({from: "Смоленская обл", to: "Ленинградская обл", percentOfReturns: 0.8836815464});
        arr.push({from: "Смоленская обл", to: "Московская обл", percentOfReturns: 1.0});
        arr.push({from: "Смоленская обл", to: "Санкт-Петербург", percentOfReturns: 0.92});
        arr.push({from: "Смоленская обл", to: "Белгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Смоленская обл", to: "Владимирская обл", percentOfReturns: 1.0});
        arr.push({from: "Смоленская обл", to: "Волгоградская обл", percentOfReturns: 0.7241927852});
        arr.push({from: "Смоленская обл", to: "Воронежская обл", percentOfReturns: 1.0});
        arr.push({from: "Смоленская обл", to: "Ивановская обл", percentOfReturns: 1.0});
        arr.push({from: "Смоленская обл", to: "Калужская обл", percentOfReturns: 1.0});
        arr.push({from: "Смоленская обл", to: "Краснодарский край", percentOfReturns: 0.6308422513});
        arr.push({from: "Смоленская обл", to: "Липецкая обл", percentOfReturns: 1.0});
        arr.push({from: "Смоленская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Смоленская обл", to: "Пермский край", percentOfReturns: 0.7386976871});
        arr.push({from: "Смоленская обл", to: "Ростовская обл", percentOfReturns: 0.7460847446});
        arr.push({from: "Смоленская обл", to: "Самарская обл", percentOfReturns: 0.682038571});
        arr.push({from: "Смоленская обл", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Смоленская обл", to: "Ставропольский край", percentOfReturns: 0.780143135});
        arr.push({from: "Смоленская обл", to: "Тульская обл", percentOfReturns: 1.0});
        arr.push({from: "Смоленская обл", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Смоленская обл", to: "Ярославская обл", percentOfReturns: 1.0});
        arr.push({from: "Смоленская обл", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Смоленская обл", to: "Ульяновская обл", percentOfReturns: 0.6202341737});
        arr.push({from: "Смоленская обл", to: "Брянская обл", percentOfReturns: 1.0});
        arr.push({from: "Смоленская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Смоленская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Смоленская обл", to: "Архангельская обл", percentOfReturns: 0.6210495687});
        arr.push({from: "Смоленская обл", to: "Астраханская обл", percentOfReturns: 0.7423655211});
        arr.push({from: "Смоленская обл", to: "Вологодская обл", percentOfReturns: 0.5978681887});
        arr.push({from: "Смоленская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Смоленская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Смоленская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Смоленская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Смоленская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 0.84});
        arr.push({from: "Смоленская обл", to: "Калининградская обл", percentOfReturns: 0.4830235003});
        arr.push({from: "Смоленская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Смоленская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 0.82});
        arr.push({from: "Смоленская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Смоленская обл", to: "Кировская обл", percentOfReturns: 0.6597362407});
        arr.push({from: "Смоленская обл", to: "Костромская обл", percentOfReturns: 1.0});
        arr.push({from: "Смоленская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Смоленская обл", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Смоленская обл", to: "Курская обл", percentOfReturns: 1.0});
        arr.push({from: "Смоленская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Смоленская обл", to: "Мурманская обл", percentOfReturns: 0.6576788681});
        arr.push({from: "Смоленская обл", to: "Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Смоленская обл", to: "Нижегородская обл", percentOfReturns: 0.6953280758});
        arr.push({from: "Смоленская обл", to: "Новгородская обл", percentOfReturns: 0.8212070195});
        arr.push({from: "Смоленская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Смоленская обл", to: "Оренбургская обл", percentOfReturns: 0.6482695668});
        arr.push({from: "Смоленская обл", to: "Орловская обл", percentOfReturns: 1.0});
        arr.push({from: "Смоленская обл", to: "Пензенская обл", percentOfReturns: 0.6759425671});
        arr.push({from: "Смоленская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Смоленская обл", to: "Псковская обл", percentOfReturns: 0.5195501327});
        arr.push({from: "Смоленская обл", to: "Республика Адыгея", percentOfReturns: 0.7944580255});
        arr.push({from: "Смоленская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Смоленская обл", to: "Республика Башкортостан", percentOfReturns: 0.7348977438});
        arr.push({from: "Смоленская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Смоленская обл", to: "Республика Дагестан", percentOfReturns: 0.77});
        arr.push({from: "Смоленская обл", to: "Республика Ингушетия", percentOfReturns: 0.77});
        arr.push({from: "Смоленская обл", to: "Республика Калмыкия", percentOfReturns: 0.86});
        arr.push({from: "Смоленская обл", to: "Республика Карелия", percentOfReturns: 0.6916802688});
        arr.push({from: "Смоленская обл", to: "Республика Коми", percentOfReturns: 0.556420352});
        arr.push({from: "Смоленская обл", to: "Республика Крым", percentOfReturns: 0.4669283633});
        arr.push({from: "Смоленская обл", to: "Республика Марий Эл", percentOfReturns: 0.5664241478});
        arr.push({from: "Смоленская обл", to: "Республика Мордовия", percentOfReturns: 0.6367239818});
        arr.push({from: "Смоленская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Смоленская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 0.6641109995});
        arr.push({from: "Смоленская обл", to: "Республика Татарстан", percentOfReturns: 0.599926894});
        arr.push({from: "Смоленская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Смоленская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Смоленская обл", to: "Рязанская обл", percentOfReturns: 1.0});
        arr.push({from: "Смоленская обл", to: "Саратовская обл", percentOfReturns: 0.7249067544});
        arr.push({from: "Смоленская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Смоленская обл", to: "Севастополь", percentOfReturns: 0.72});
        arr.push({from: "Смоленская обл", to: "Смоленская обл", percentOfReturns: 1.0});
        arr.push({from: "Смоленская обл", to: "Тамбовская обл", percentOfReturns: 1.0});
        arr.push({from: "Смоленская обл", to: "Тверская обл", percentOfReturns: 1.0});
        arr.push({from: "Смоленская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Смоленская обл", to: "Удмуртская Республика", percentOfReturns: 0.5097286307});
        arr.push({from: "Смоленская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Смоленская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Смоленская обл", to: "Чеченская Республика", percentOfReturns: 0.77});
        arr.push({from: "Смоленская обл", to: "Чувашская Республика ", percentOfReturns: 0.5500897862});
        arr.push({from: "Смоленская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Смоленская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Тамбовская обл", to: "г. Москва", percentOfReturns: 1.468100843});
        arr.push({from: "Тамбовская обл", to: "Ленинградская обл", percentOfReturns: 0.8836815464});
        arr.push({from: "Тамбовская обл", to: "Московская обл", percentOfReturns: 1.0});
        arr.push({from: "Тамбовская обл", to: "Санкт-Петербург", percentOfReturns: 0.92});
        arr.push({from: "Тамбовская обл", to: "Белгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Тамбовская обл", to: "Владимирская обл", percentOfReturns: 1.0});
        arr.push({from: "Тамбовская обл", to: "Волгоградская обл", percentOfReturns: 0.7241927852});
        arr.push({from: "Тамбовская обл", to: "Воронежская обл", percentOfReturns: 1.0});
        arr.push({from: "Тамбовская обл", to: "Ивановская обл", percentOfReturns: 1.0});
        arr.push({from: "Тамбовская обл", to: "Калужская обл", percentOfReturns: 1.0});
        arr.push({from: "Тамбовская обл", to: "Краснодарский край", percentOfReturns: 0.6308422513});
        arr.push({from: "Тамбовская обл", to: "Липецкая обл", percentOfReturns: 1.0});
        arr.push({from: "Тамбовская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Тамбовская обл", to: "Пермский край", percentOfReturns: 0.7386976871});
        arr.push({from: "Тамбовская обл", to: "Ростовская обл", percentOfReturns: 0.7460847446});
        arr.push({from: "Тамбовская обл", to: "Самарская обл", percentOfReturns: 0.682038571});
        arr.push({from: "Тамбовская обл", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Тамбовская обл", to: "Ставропольский край", percentOfReturns: 0.780143135});
        arr.push({from: "Тамбовская обл", to: "Тульская обл", percentOfReturns: 1.0});
        arr.push({from: "Тамбовская обл", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Тамбовская обл", to: "Ярославская обл", percentOfReturns: 1.0});
        arr.push({from: "Тамбовская обл", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Тамбовская обл", to: "Ульяновская обл", percentOfReturns: 0.6202341737});
        arr.push({from: "Тамбовская обл", to: "Брянская обл", percentOfReturns: 1.0});
        arr.push({from: "Тамбовская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Тамбовская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Тамбовская обл", to: "Архангельская обл", percentOfReturns: 0.6210495687});
        arr.push({from: "Тамбовская обл", to: "Астраханская обл", percentOfReturns: 0.7423655211});
        arr.push({from: "Тамбовская обл", to: "Вологодская обл", percentOfReturns: 0.5978681887});
        arr.push({from: "Тамбовская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Тамбовская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Тамбовская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Тамбовская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Тамбовская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 0.84});
        arr.push({from: "Тамбовская обл", to: "Калининградская обл", percentOfReturns: 0.4830235003});
        arr.push({from: "Тамбовская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Тамбовская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 0.82});
        arr.push({from: "Тамбовская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Тамбовская обл", to: "Кировская обл", percentOfReturns: 0.6597362407});
        arr.push({from: "Тамбовская обл", to: "Костромская обл", percentOfReturns: 1.0});
        arr.push({from: "Тамбовская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Тамбовская обл", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Тамбовская обл", to: "Курская обл", percentOfReturns: 1.0});
        arr.push({from: "Тамбовская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Тамбовская обл", to: "Мурманская обл", percentOfReturns: 0.6576788681});
        arr.push({from: "Тамбовская обл", to: "Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Тамбовская обл", to: "Нижегородская обл", percentOfReturns: 0.6953280758});
        arr.push({from: "Тамбовская обл", to: "Новгородская обл", percentOfReturns: 0.8212070195});
        arr.push({from: "Тамбовская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Тамбовская обл", to: "Оренбургская обл", percentOfReturns: 0.6482695668});
        arr.push({from: "Тамбовская обл", to: "Орловская обл", percentOfReturns: 1.0});
        arr.push({from: "Тамбовская обл", to: "Пензенская обл", percentOfReturns: 0.6759425671});
        arr.push({from: "Тамбовская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Тамбовская обл", to: "Псковская обл", percentOfReturns: 0.5195501327});
        arr.push({from: "Тамбовская обл", to: "Республика Адыгея", percentOfReturns: 0.7944580255});
        arr.push({from: "Тамбовская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Тамбовская обл", to: "Республика Башкортостан", percentOfReturns: 0.7348977438});
        arr.push({from: "Тамбовская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Тамбовская обл", to: "Республика Дагестан", percentOfReturns: 0.77});
        arr.push({from: "Тамбовская обл", to: "Республика Ингушетия", percentOfReturns: 0.77});
        arr.push({from: "Тамбовская обл", to: "Республика Калмыкия", percentOfReturns: 0.86});
        arr.push({from: "Тамбовская обл", to: "Республика Карелия", percentOfReturns: 0.6916802688});
        arr.push({from: "Тамбовская обл", to: "Республика Коми", percentOfReturns: 0.556420352});
        arr.push({from: "Тамбовская обл", to: "Республика Крым", percentOfReturns: 0.4669283633});
        arr.push({from: "Тамбовская обл", to: "Республика Марий Эл", percentOfReturns: 0.5664241478});
        arr.push({from: "Тамбовская обл", to: "Республика Мордовия", percentOfReturns: 0.6367239818});
        arr.push({from: "Тамбовская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Тамбовская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 0.6641109995});
        arr.push({from: "Тамбовская обл", to: "Республика Татарстан", percentOfReturns: 0.599926894});
        arr.push({from: "Тамбовская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Тамбовская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Тамбовская обл", to: "Рязанская обл", percentOfReturns: 1.0});
        arr.push({from: "Тамбовская обл", to: "Саратовская обл", percentOfReturns: 0.7249067544});
        arr.push({from: "Тамбовская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Тамбовская обл", to: "Севастополь", percentOfReturns: 0.72});
        arr.push({from: "Тамбовская обл", to: "Смоленская обл", percentOfReturns: 1.0});
        arr.push({from: "Тамбовская обл", to: "Тамбовская обл", percentOfReturns: 1.0});
        arr.push({from: "Тамбовская обл", to: "Тверская обл", percentOfReturns: 1.0});
        arr.push({from: "Тамбовская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Тамбовская обл", to: "Удмуртская Республика", percentOfReturns: 0.5097286307});
        arr.push({from: "Тамбовская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Тамбовская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Тамбовская обл", to: "Чеченская Республика", percentOfReturns: 0.77});
        arr.push({from: "Тамбовская обл", to: "Чувашская Республика ", percentOfReturns: 0.5500897862});
        arr.push({from: "Тамбовская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Тамбовская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Тверская обл", to: "г. Москва", percentOfReturns: 2.127659574});
        arr.push({from: "Тверская обл", to: "Ленинградская обл", percentOfReturns: 0.8836815464});
        arr.push({from: "Тверская обл", to: "Московская обл", percentOfReturns: 1.0});
        arr.push({from: "Тверская обл", to: "Санкт-Петербург", percentOfReturns: 0.92});
        arr.push({from: "Тверская обл", to: "Белгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Тверская обл", to: "Владимирская обл", percentOfReturns: 1.0});
        arr.push({from: "Тверская обл", to: "Волгоградская обл", percentOfReturns: 0.7241927852});
        arr.push({from: "Тверская обл", to: "Воронежская обл", percentOfReturns: 1.0});
        arr.push({from: "Тверская обл", to: "Ивановская обл", percentOfReturns: 1.0});
        arr.push({from: "Тверская обл", to: "Калужская обл", percentOfReturns: 1.0});
        arr.push({from: "Тверская обл", to: "Краснодарский край", percentOfReturns: 0.6308422513});
        arr.push({from: "Тверская обл", to: "Липецкая обл", percentOfReturns: 1.0});
        arr.push({from: "Тверская обл", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Тверская обл", to: "Пермский край", percentOfReturns: 0.7386976871});
        arr.push({from: "Тверская обл", to: "Ростовская обл", percentOfReturns: 0.7460847446});
        arr.push({from: "Тверская обл", to: "Самарская обл", percentOfReturns: 0.682038571});
        arr.push({from: "Тверская обл", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Тверская обл", to: "Ставропольский край", percentOfReturns: 0.780143135});
        arr.push({from: "Тверская обл", to: "Тульская обл", percentOfReturns: 1.0});
        arr.push({from: "Тверская обл", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Тверская обл", to: "Ярославская обл", percentOfReturns: 1.0});
        arr.push({from: "Тверская обл", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Тверская обл", to: "Ульяновская обл", percentOfReturns: 0.6202341737});
        arr.push({from: "Тверская обл", to: "Брянская обл", percentOfReturns: 1.0});
        arr.push({from: "Тверская обл", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Тверская обл", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Тверская обл", to: "Архангельская обл", percentOfReturns: 0.6210495687});
        arr.push({from: "Тверская обл", to: "Астраханская обл", percentOfReturns: 0.7423655211});
        arr.push({from: "Тверская обл", to: "Вологодская обл", percentOfReturns: 0.5978681887});
        arr.push({from: "Тверская обл", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Тверская обл", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Тверская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Тверская обл", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Тверская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 0.84});
        arr.push({from: "Тверская обл", to: "Калининградская обл", percentOfReturns: 0.4830235003});
        arr.push({from: "Тверская обл", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Тверская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 0.82});
        arr.push({from: "Тверская обл", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Тверская обл", to: "Кировская обл", percentOfReturns: 0.6597362407});
        arr.push({from: "Тверская обл", to: "Костромская обл", percentOfReturns: 1.0});
        arr.push({from: "Тверская обл", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Тверская обл", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Тверская обл", to: "Курская обл", percentOfReturns: 1.0});
        arr.push({from: "Тверская обл", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Тверская обл", to: "Мурманская обл", percentOfReturns: 0.6576788681});
        arr.push({from: "Тверская обл", to: "Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Тверская обл", to: "Нижегородская обл", percentOfReturns: 0.6953280758});
        arr.push({from: "Тверская обл", to: "Новгородская обл", percentOfReturns: 0.8212070195});
        arr.push({from: "Тверская обл", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Тверская обл", to: "Оренбургская обл", percentOfReturns: 0.6482695668});
        arr.push({from: "Тверская обл", to: "Орловская обл", percentOfReturns: 1.0});
        arr.push({from: "Тверская обл", to: "Пензенская обл", percentOfReturns: 0.6759425671});
        arr.push({from: "Тверская обл", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Тверская обл", to: "Псковская обл", percentOfReturns: 0.5195501327});
        arr.push({from: "Тверская обл", to: "Республика Адыгея", percentOfReturns: 0.7944580255});
        arr.push({from: "Тверская обл", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Тверская обл", to: "Республика Башкортостан", percentOfReturns: 0.7348977438});
        arr.push({from: "Тверская обл", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Тверская обл", to: "Республика Дагестан", percentOfReturns: 0.77});
        arr.push({from: "Тверская обл", to: "Республика Ингушетия", percentOfReturns: 0.77});
        arr.push({from: "Тверская обл", to: "Республика Калмыкия", percentOfReturns: 0.86});
        arr.push({from: "Тверская обл", to: "Республика Карелия", percentOfReturns: 0.6916802688});
        arr.push({from: "Тверская обл", to: "Республика Коми", percentOfReturns: 0.556420352});
        arr.push({from: "Тверская обл", to: "Республика Крым", percentOfReturns: 0.4669283633});
        arr.push({from: "Тверская обл", to: "Республика Марий Эл", percentOfReturns: 0.5664241478});
        arr.push({from: "Тверская обл", to: "Республика Мордовия", percentOfReturns: 0.6367239818});
        arr.push({from: "Тверская обл", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Тверская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 0.6641109995});
        arr.push({from: "Тверская обл", to: "Республика Татарстан", percentOfReturns: 0.599926894});
        arr.push({from: "Тверская обл", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Тверская обл", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Тверская обл", to: "Рязанская обл", percentOfReturns: 1.0});
        arr.push({from: "Тверская обл", to: "Саратовская обл", percentOfReturns: 0.7249067544});
        arr.push({from: "Тверская обл", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Тверская обл", to: "Севастополь", percentOfReturns: 0.72});
        arr.push({from: "Тверская обл", to: "Смоленская обл", percentOfReturns: 1.0});
        arr.push({from: "Тверская обл", to: "Тамбовская обл", percentOfReturns: 1.0});
        arr.push({from: "Тверская обл", to: "Тверская обл", percentOfReturns: 1.0});
        arr.push({from: "Тверская обл", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Тверская обл", to: "Удмуртская Республика", percentOfReturns: 0.5097286307});
        arr.push({from: "Тверская обл", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Тверская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Тверская обл", to: "Чеченская Республика", percentOfReturns: 0.77});
        arr.push({from: "Тверская обл", to: "Чувашская Республика ", percentOfReturns: 0.5500897862});
        arr.push({from: "Тверская обл", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Тверская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Томская обл", to: "г. Москва", percentOfReturns: 1.295879923});
        arr.push({from: "Томская обл", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Томская обл", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Томская обл", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Томская обл", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Томская обл", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Томская обл", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Томская обл", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Томская обл", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Томская обл", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Томская обл", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Томская обл", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Томская обл", to: "Новосибирская обл", percentOfReturns: 1.0});
        arr.push({from: "Томская обл", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Томская обл", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Томская обл", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Томская обл", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Томская обл", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Томская обл", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Томская обл", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Томская обл", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Томская обл", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Томская обл", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Томская обл", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Томская обл", to: "Алтайский край", percentOfReturns: 1.0});
        arr.push({from: "Томская обл", to: "Амурская обл", percentOfReturns: 1.449275362});
        arr.push({from: "Томская обл", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Томская обл", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Томская обл", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Томская обл", to: "Еврейская автономная обл", percentOfReturns: 1.470588235});
        arr.push({from: "Томская обл", to: "Забайкальский край", percentOfReturns: 1.587301587});
        arr.push({from: "Томская обл", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 2.0});
        arr.push({from: "Томская обл", to: "Иркутская обл", percentOfReturns: 1.0});
        arr.push({from: "Томская обл", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Томская обл", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Томская обл", to: "Камчатский край", percentOfReturns: 2.0});
        arr.push({from: "Томская обл", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Томская обл", to: "Кемеровская обл", percentOfReturns: 1.0});
        arr.push({from: "Томская обл", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Томская обл", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Томская обл", to: "Красноярский край", percentOfReturns: 1.0});
        arr.push({from: "Томская обл", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Томская обл", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Томская обл", to: "Магаданская обл", percentOfReturns: 2.0});
        arr.push({from: "Томская обл", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Томская обл", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Томская обл", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Томская обл", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Томская обл", to: "Омская обл", percentOfReturns: 1.0});
        arr.push({from: "Томская обл", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Томская обл", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Томская обл", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Томская обл", to: "Приморский край", percentOfReturns: 1.470588235});
        arr.push({from: "Томская обл", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Томская обл", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Томская обл", to: "Республика Алтай", percentOfReturns: 1.0});
        arr.push({from: "Томская обл", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Томская обл", to: "Республика Бурятия", percentOfReturns: 1.0});
        arr.push({from: "Томская обл", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Томская обл", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Томская обл", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Томская обл", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Томская обл", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Томская обл", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Томская обл", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Томская обл", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Томская обл", to: "Республика Саха (Якутия)", percentOfReturns: 2.0});
        arr.push({from: "Томская обл", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Томская обл", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Томская обл", to: "Республика Тыва", percentOfReturns: 1.0});
        arr.push({from: "Томская обл", to: "Республика Хакасия", percentOfReturns: 1.0});
        arr.push({from: "Томская обл", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Томская обл", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Томская обл", to: "Сахалинская обл", percentOfReturns: 2.0});
        arr.push({from: "Томская обл", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Томская обл", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Томская обл", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Томская обл", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Томская обл", to: "Томская обл", percentOfReturns: 1.0});
        arr.push({from: "Томская обл", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Томская обл", to: "Хабаровский край", percentOfReturns: 1.465160601});
        arr.push({from: "Томская обл", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Томская обл", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Томская обл", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Томская обл", to: "Чукотский автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Томская обл", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Удмуртская Республика", to: "г. Москва", percentOfReturns: 1.961828196});
        arr.push({from: "Удмуртская Республика", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Удмуртская Республика", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Удмуртская Республика", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Удмуртская Республика", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Удмуртская Республика", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Удмуртская Республика", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Удмуртская Республика", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Удмуртская Республика", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Удмуртская Республика", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Удмуртская Республика", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Удмуртская Республика", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Удмуртская Республика", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Удмуртская Республика", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Удмуртская Республика", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Удмуртская Республика", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Удмуртская Республика", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Удмуртская Республика", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Удмуртская Республика", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Удмуртская Республика", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Удмуртская Республика", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Удмуртская Республика", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Удмуртская Республика", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Удмуртская Республика", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Удмуртская Республика", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Удмуртская Республика", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Удмуртская Республика", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Удмуртская Республика", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Удмуртская Республика", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Удмуртская Республика", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Удмуртская Республика", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Удмуртская Республика", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Удмуртская Республика", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Удмуртская Республика", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Удмуртская Республика", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Удмуртская Республика", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Удмуртская Республика", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Удмуртская Республика", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Удмуртская Республика", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Удмуртская Республика", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Удмуртская Республика", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Удмуртская Республика", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Хабаровский край", to: "г. Москва", percentOfReturns: 1.465160601});
        arr.push({from: "Хабаровский край", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Хабаровский край", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Хабаровский край", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Хабаровский край", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Хабаровский край", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Хабаровский край", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Хабаровский край", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Хабаровский край", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Хабаровский край", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Хабаровский край", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Хабаровский край", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Хабаровский край", to: "Новосибирская обл", percentOfReturns: 1.285328603});
        arr.push({from: "Хабаровский край", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Хабаровский край", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Хабаровский край", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Хабаровский край", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Хабаровский край", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Хабаровский край", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Хабаровский край", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Хабаровский край", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Хабаровский край", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Хабаровский край", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Хабаровский край", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Хабаровский край", to: "Алтайский край", percentOfReturns: 1.260287158});
        arr.push({from: "Хабаровский край", to: "Амурская обл", percentOfReturns: 1.0});
        arr.push({from: "Хабаровский край", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Хабаровский край", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Хабаровский край", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Хабаровский край", to: "Еврейская автономная обл", percentOfReturns: 1.0});
        arr.push({from: "Хабаровский край", to: "Забайкальский край", percentOfReturns: 1.0});
        arr.push({from: "Хабаровский край", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 1.0});
        arr.push({from: "Хабаровский край", to: "Иркутская обл", percentOfReturns: 1.321310484});
        arr.push({from: "Хабаровский край", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Хабаровский край", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Хабаровский край", to: "Камчатский край", percentOfReturns: 1.0});
        arr.push({from: "Хабаровский край", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Хабаровский край", to: "Кемеровская обл", percentOfReturns: 1.242138464});
        arr.push({from: "Хабаровский край", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Хабаровский край", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Хабаровский край", to: "Красноярский край", percentOfReturns: 1.308203705});
        arr.push({from: "Хабаровский край", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Хабаровский край", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Хабаровский край", to: "Магаданская обл", percentOfReturns: 1.0});
        arr.push({from: "Хабаровский край", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Хабаровский край", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Хабаровский край", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Хабаровский край", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Хабаровский край", to: "Омская обл", percentOfReturns: 1.266801133});
        arr.push({from: "Хабаровский край", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Хабаровский край", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Хабаровский край", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Хабаровский край", to: "Приморский край", percentOfReturns: 1.0});
        arr.push({from: "Хабаровский край", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Хабаровский край", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Хабаровский край", to: "Республика Алтай", percentOfReturns: 1.265822785});
        arr.push({from: "Хабаровский край", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Хабаровский край", to: "Республика Бурятия", percentOfReturns: 1.515151515});
        arr.push({from: "Хабаровский край", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Хабаровский край", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Хабаровский край", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Хабаровский край", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Хабаровский край", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Хабаровский край", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Хабаровский край", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Хабаровский край", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Хабаровский край", to: "Республика Саха (Якутия)", percentOfReturns: 1.0});
        arr.push({from: "Хабаровский край", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Хабаровский край", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Хабаровский край", to: "Республика Тыва", percentOfReturns: 1.388888889});
        arr.push({from: "Хабаровский край", to: "Республика Хакасия", percentOfReturns: 1.538461538});
        arr.push({from: "Хабаровский край", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Хабаровский край", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Хабаровский край", to: "Сахалинская обл", percentOfReturns: 1.0});
        arr.push({from: "Хабаровский край", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Хабаровский край", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Хабаровский край", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Хабаровский край", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Хабаровский край", to: "Томская обл", percentOfReturns: 1.295879923});
        arr.push({from: "Хабаровский край", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Хабаровский край", to: "Хабаровский край", percentOfReturns: 1.0});
        arr.push({from: "Хабаровский край", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Хабаровский край", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Хабаровский край", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Хабаровский край", to: "Чукотский автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Хабаровский край", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "г. Москва", percentOfReturns: 1.418745194});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Ханты-Мансийский автономный округ - Югра", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "г. Москва", percentOfReturns: 1.298701299});
        arr.push({from: "Чеченская Республика", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Чеченская Республика", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Чеченская Республика", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Чеченская Республика", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Чеченская Республика", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Чеченская Республика", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Чеченская Республика", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Чеченская Республика", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Чеченская Республика", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Свердловская обл", percentOfReturns: 0.7651546893});
        arr.push({from: "Чеченская Республика", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Чеченская Республика", to: "Челябинская обл", percentOfReturns: 0.7284978166});
        arr.push({from: "Чеченская Республика", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Чеченская Республика", to: "Тюменская обл", percentOfReturns: 0.7236589399});
        arr.push({from: "Чеченская Республика", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Чеченская Республика", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Чеченская Республика", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Чеченская Республика", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Чеченская Республика", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Чеченская Республика", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Чеченская Республика", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Чеченская Республика", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Чеченская Республика", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Чеченская Республика", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Чеченская Республика", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Чеченская Республика", to: "Курганская обл", percentOfReturns: 0.82});
        arr.push({from: "Чеченская Республика", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Чеченская Республика", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Чеченская Республика", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Чеченская Республика", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Чеченская Республика", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Чеченская Республика", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Чеченская Республика", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Чеченская Республика", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Чеченская Республика", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Чеченская Республика", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Чеченская Республика", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Чеченская Республика", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Чеченская Республика", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Чеченская Республика", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Чеченская Республика", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Чеченская Республика", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Чеченская Республика", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Чеченская Республика", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 0.7048482028});
        arr.push({from: "Чеченская Республика", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Чеченская Республика", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Чеченская Республика", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Чувашская Республика - Чувашия", to: "г. Москва", percentOfReturns: 1.817885053});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Пермский край", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Самарская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Ставропольский край", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Ульяновская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Кировская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Нижегородская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Оренбургская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Пензенская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Республика Башкортостан", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Республика Дагестан", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Республика Ингушетия", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Республика Марий Эл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Республика Мордовия", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Республика Татарстан", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Саратовская обл", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Удмуртская Республика", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Чеченская Республика", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Чувашская Республика ", percentOfReturns: 1.0});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Чувашская Республика - Чувашия", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Чукотский автономный округ", to: "г. Москва", percentOfReturns: 2.0});
        arr.push({from: "Чукотский автономный округ", to: "Ленинградская обл", percentOfReturns: 1.131629379});
        arr.push({from: "Чукотский автономный округ", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Чукотский автономный округ", to: "Санкт-Петербург", percentOfReturns: 1.086956522});
        arr.push({from: "Чукотский автономный округ", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Чукотский автономный округ", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Чукотский автономный округ", to: "Волгоградская обл", percentOfReturns: 1.38084778});
        arr.push({from: "Чукотский автономный округ", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Чукотский автономный округ", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Чукотский автономный округ", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Чукотский автономный округ", to: "Краснодарский край", percentOfReturns: 1.585182346});
        arr.push({from: "Чукотский автономный округ", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Чукотский автономный округ", to: "Новосибирская обл", percentOfReturns: 1.285328603});
        arr.push({from: "Чукотский автономный округ", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Чукотский автономный округ", to: "Ростовская обл", percentOfReturns: 1.340330314});
        arr.push({from: "Чукотский автономный округ", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Чукотский автономный округ", to: "Свердловская обл", percentOfReturns: 1.306925271});
        arr.push({from: "Чукотский автономный округ", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Чукотский автономный округ", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Чукотский автономный округ", to: "Челябинская обл", percentOfReturns: 1.372687711});
        arr.push({from: "Чукотский автономный округ", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Чукотский автономный округ", to: "Тюменская обл", percentOfReturns: 1.381866436});
        arr.push({from: "Чукотский автономный округ", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Чукотский автономный округ", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Чукотский автономный округ", to: "Алтайский край", percentOfReturns: 1.260287158});
        arr.push({from: "Чукотский автономный округ", to: "Амурская обл", percentOfReturns: 1.0});
        arr.push({from: "Чукотский автономный округ", to: "Архангельская обл", percentOfReturns: 1.610177432});
        arr.push({from: "Чукотский автономный округ", to: "Астраханская обл", percentOfReturns: 1.347045319});
        arr.push({from: "Чукотский автономный округ", to: "Вологодская обл", percentOfReturns: 1.67260948});
        arr.push({from: "Чукотский автономный округ", to: "Еврейская автономная обл", percentOfReturns: 1.0});
        arr.push({from: "Чукотский автономный округ", to: "Забайкальский край", percentOfReturns: 1.0});
        arr.push({from: "Чукотский автономный округ", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 1.0});
        arr.push({from: "Чукотский автономный округ", to: "Иркутская обл", percentOfReturns: 1.321310484});
        arr.push({from: "Чукотский автономный округ", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Чукотский автономный округ", to: "Калининградская обл", percentOfReturns: 2.070292645});
        arr.push({from: "Чукотский автономный округ", to: "Камчатский край", percentOfReturns: 1.0});
        arr.push({from: "Чукотский автономный округ", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Чукотский автономный округ", to: "Кемеровская обл", percentOfReturns: 1.242138464});
        arr.push({from: "Чукотский автономный округ", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Чукотский автономный округ", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Чукотский автономный округ", to: "Красноярский край", percentOfReturns: 1.308203705});
        arr.push({from: "Чукотский автономный округ", to: "Курганская обл", percentOfReturns: 1.219512195});
        arr.push({from: "Чукотский автономный округ", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Чукотский автономный округ", to: "Магаданская обл", percentOfReturns: 1.0});
        arr.push({from: "Чукотский автономный округ", to: "Мурманская обл", percentOfReturns: 1.520498907});
        arr.push({from: "Чукотский автономный округ", to: "Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Чукотский автономный округ", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Чукотский автономный округ", to: "Новгородская обл", percentOfReturns: 1.217719742});
        arr.push({from: "Чукотский автономный округ", to: "Омская обл", percentOfReturns: 1.266801133});
        arr.push({from: "Чукотский автономный округ", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Чукотский автономный округ", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Чукотский автономный округ", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Чукотский автономный округ", to: "Приморский край", percentOfReturns: 1.0});
        arr.push({from: "Чукотский автономный округ", to: "Псковская обл", percentOfReturns: 1.924742074});
        arr.push({from: "Чукотский автономный округ", to: "Республика Адыгея", percentOfReturns: 1.258719741});
        arr.push({from: "Чукотский автономный округ", to: "Республика Алтай", percentOfReturns: 1.265822785});
        arr.push({from: "Чукотский автономный округ", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Чукотский автономный округ", to: "Республика Бурятия", percentOfReturns: 1.515151515});
        arr.push({from: "Чукотский автономный округ", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Чукотский автономный округ", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Чукотский автономный округ", to: "Республика Калмыкия", percentOfReturns: 1.162790698});
        arr.push({from: "Чукотский автономный округ", to: "Республика Карелия", percentOfReturns: 1.445754701});
        arr.push({from: "Чукотский автономный округ", to: "Республика Коми", percentOfReturns: 1.797202414});
        arr.push({from: "Чукотский автономный округ", to: "Республика Крым", percentOfReturns: 2.141656148});
        arr.push({from: "Чукотский автономный округ", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Чукотский автономный округ", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Чукотский автономный округ", to: "Республика Саха (Якутия)", percentOfReturns: 1.0});
        arr.push({from: "Чукотский автономный округ", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Чукотский автономный округ", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Чукотский автономный округ", to: "Республика Тыва", percentOfReturns: 1.388888889});
        arr.push({from: "Чукотский автономный округ", to: "Республика Хакасия", percentOfReturns: 1.538461538});
        arr.push({from: "Чукотский автономный округ", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Чукотский автономный округ", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Чукотский автономный округ", to: "Сахалинская обл", percentOfReturns: 1.0});
        arr.push({from: "Чукотский автономный округ", to: "Севастополь", percentOfReturns: 1.388888889});
        arr.push({from: "Чукотский автономный округ", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Чукотский автономный округ", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Чукотский автономный округ", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Чукотский автономный округ", to: "Томская обл", percentOfReturns: 1.295879923});
        arr.push({from: "Чукотский автономный округ", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Чукотский автономный округ", to: "Хабаровский край", percentOfReturns: 1.0});
        arr.push({from: "Чукотский автономный округ", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.418745194});
        arr.push({from: "Чукотский автономный округ", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Чукотский автономный округ", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Чукотский автономный округ", to: "Чукотский автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Чукотский автономный округ", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 2.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "г. Москва", percentOfReturns: 2.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Ленинградская обл", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Московская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Санкт-Петербург", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Белгородская обл", percentOfReturns: 0.9686524436});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Владимирская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Волгоградская обл", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Воронежская обл", percentOfReturns: 1.311313375});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Ивановская обл", percentOfReturns: 1.875025373});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Калужская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Краснодарский край", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Липецкая обл", percentOfReturns: 1.39758999});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Новосибирская обл", percentOfReturns: 0.7780111621});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Пермский край", percentOfReturns: 1.353733763});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Ростовская обл", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Самарская обл", percentOfReturns: 1.466192738});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Свердловская обл", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Ставропольский край", percentOfReturns: 1.281816061});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Тульская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Челябинская обл", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Ярославская обл", percentOfReturns: 2.528895376});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Тюменская обл", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Ульяновская обл", percentOfReturns: 1.612294263});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Брянская обл", percentOfReturns: 1.359187643});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Алтайский край", percentOfReturns: 0.7934699596});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Амурская обл", percentOfReturns: 0.69});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Архангельская обл", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Астраханская обл", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Вологодская обл", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Еврейская автономная обл", percentOfReturns: 0.68});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Забайкальский край", percentOfReturns: 0.63});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Иные территории, включая город и космодром Байконур", percentOfReturns: 0.5});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Иркутская обл", percentOfReturns: 0.7568243892});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Кабардино-Балкарская Республика", percentOfReturns: 1.19047619});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Калининградская обл", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Камчатский край", percentOfReturns: 0.5});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Карачаево-Черкесская Республика", percentOfReturns: 1.219512195});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Кемеровская обл", percentOfReturns: 0.8050632268});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Кировская обл", percentOfReturns: 1.515757265});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Костромская обл", percentOfReturns: 1.869224365});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Красноярский край", percentOfReturns: 0.7644069468});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Курганская обл", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Курская обл", percentOfReturns: 1.209008367});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Магаданская обл", percentOfReturns: 0.5});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Мурманская обл", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Ненецкий автономный округ", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Нижегородская обл", percentOfReturns: 1.43817003});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Новгородская обл", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Омская обл", percentOfReturns: 0.7893898843});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Оренбургская обл", percentOfReturns: 1.542568171});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Орловская обл", percentOfReturns: 1.471396762});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Пензенская обл", percentOfReturns: 1.479415632});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Приморский край", percentOfReturns: 0.68});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Псковская обл", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Республика Адыгея", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Республика Алтай", percentOfReturns: 0.79});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Республика Башкортостан", percentOfReturns: 1.360733528});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Республика Бурятия", percentOfReturns: 0.66});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Республика Дагестан", percentOfReturns: 1.298701299});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Республика Ингушетия", percentOfReturns: 1.298701299});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Республика Калмыкия", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Республика Карелия", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Республика Коми", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Республика Крым", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Республика Марий Эл", percentOfReturns: 1.765461455});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Республика Мордовия", percentOfReturns: 1.570539242});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Республика Саха (Якутия)", percentOfReturns: 0.5});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Республика Северная Осетия - Алания", percentOfReturns: 1.50577238});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Республика Татарстан", percentOfReturns: 1.666869764});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Республика Тыва", percentOfReturns: 0.72});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Республика Хакасия", percentOfReturns: 0.65});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Рязанская обл", percentOfReturns: 2.319386097});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Саратовская обл", percentOfReturns: 1.379487767});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Сахалинская обл", percentOfReturns: 0.5});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Севастополь", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Смоленская обл", percentOfReturns: 1.016028677});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Тамбовская обл", percentOfReturns: 1.468100843});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Тверская обл", percentOfReturns: 2.127659574});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Томская обл", percentOfReturns: 0.7716764354});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Удмуртская Республика", percentOfReturns: 1.961828196});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Хабаровский край", percentOfReturns: 0.6825190351});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Ханты-Мансийский автономный округ - Югра", percentOfReturns: 1.0});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Чеченская Республика", percentOfReturns: 1.298701299});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Чувашская Республика ", percentOfReturns: 1.817885053});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Чукотский автономный округ", percentOfReturns: 0.5});
        arr.push({from: "Ямало-Ненецкий автономный округ", to: "Ямало-Ненецкий автономный округ", percentOfReturns: 1.0});
        this.arr = arr;
    }
}
